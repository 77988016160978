import React, { Component } from 'react';


import axios from '../../apis/thresource';

class RegisterTeacher extends Component {

    constructor(props){
        super(props);

        this.state = {
            firstName: "",
            lastName: "",
            email: "",
            is2FAEnabled: true,
            mobile: ""
        };

        this.onChangeFirstName = this.onChangeFirstName.bind(this);
        this.onChangeLastName = this.onChangeLastName.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChange2FAEnabled = this.onChange2FAEnabled.bind(this);
        this.onChangeMobile = this.onChangeMobile.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onChangeFirstName(_e){
        console.log("onChangeFirstName", _e);
        this.setState({
            firstName: _e.target.value
        });
    }


    onChangeLastName(_e){
        console.log("onChangeLastName", _e);
        this.setState({
            lastName: _e.target.value
        });
    }


    onChangeEmail(_e){
        console.log("onChangeEmail", _e);
        this.setState({
            email: _e.target.value
        });
    }
    
    onChange2FAEnabled() {
        this.setState({
            is2FAEnabled: !this.state.is2FAEnabled
        });
    }

    onChangeMobile(_e) {
        console.log("onChangeMobile", _e);
        this.setState({
            mobile: _e.target.value
        });
    }

    
    onSubmitForm(_e){
        console.log("on register form submit");
        _e.preventDefault();

        const postData = { 
            first_name: this.state.firstName, 
            last_name: this.state.lastName,
            email: this.state.email,
            mobile: this.state.mobile
        };

        axios.post('/api/account/register', JSON.stringify(postData))
        .then(response => {
            //console.log("register data: ", postData);
            console.log("response data: ", response.data);
            if(!response.data.success && response.data.errors) {
                this.props.onSetAlert(response.data.errors);
            }

            if(response.data.success) {
                console.log("register complete updating user");
                this.props.onUpdateUser(postData);

                console.log("register navigating");
                this.props.history.push('/register/verify');
            }
            else {
                console.log("register failed");
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                //console.log("server data:", error.response.data);
                this.props.onSetAlert(error.response.data.errors);
            }
        });
    }

    render() {

        const { email, firstName, lastName, mobile, is2FAEnabled } = this.state;

        return (
            <div className='split-screen'>

                <div className='bgc-y-grade v-align-ctr'>
                    <div className='pall-l'>
                        <h1 className='t-upper t-ctr hw-font'>We're on a mission</h1>
                        <p className='t-ctr'>Join our national campaign for Topic Heroes<sup>®</sup> to be made available FREE to every primary school aged child in the UK. We’re calling for business, teachers, parents and delivery partners to get involved in helping make this a reality.</p>
                    </div>
                </div>

                <div className='v-align-ctr'>
                    <form className="th-form-container" onSubmit={ this.onSubmitForm } >

                        <div className="th-form-inner has-validation-callback">

                            <div>
                                <h2>Register Your Account</h2>
                                <p>Fill out the form and sign-up today!</p>
                            </div>

                            <div className="th-form-inner-row">

                                <div className='th-field-wrapper th-sml-field'> 
                                    <label>First Name</label>
                                    <input type="text" placeholder="First name" onChange={ this.onChangeFirstName } value={ firstName } />
                                </div>

                                <div className='th-field-wrapper'>
                                    <label>Last Name</label>     
                                    <input type="text" placeholder="Last name" onChange={ this.onChangeLastName } value={ lastName } />
                                </div>     
                            </div>

                            <div className='th-field-wrapper'>
                                <label>Email Address</label>   
                                <input type="text" placeholder="Email" onChange={ this.onChangeEmail } value={ email } />
                            </div>

                            <div className="th-form-inline-label">  
                                <input type="checkbox" value="Enabled" checked= { is2FAEnabled } onChange={ this.onChange2FAEnabled } />
                                <label>Enable Two Factor Authentication</label>
                            </div>

                            <div className='th-field-wrapper'>
                                <label>Mobile Number</label>   
                                <input type="text" placeholder="Mobile" onChange={ this.onChangeMobile } value={ mobile } />
                            </div>

                            <div className="th-form-inline-label">  
                                <input type="checkbox" value="Enabled"/>
                                <label>Opt in to recieve offers and updates from Topic Heroes<sup>™</sup></label>
                            </div>

                            <div className="th-form-buttons">
                                <button className="th-btn" type="submit"> Register </button>
                            </div>

                        </div>

                    </form>

                </div>
                
            </div>
        );
    }
}

export default RegisterTeacher;


