
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

class LoginExpired extends Component {

    render() {

        return (
            <div>
                <div className="form-inner-container register-form  panel-bg-white round-corner-12 panel-shadow bg-texture-dots has-validation-callback">
                    <div className="form-header">
                        <h2 className="text-bold space-after-8 text-center">Login Expired</h2>
                        <p className="space-after-8 text-center">
                            Click <Link to={ "/login" }>HERE</Link> to go back to the login page and retry.
                        </p>
                    </div>
                </div>
        
            </div>
        );
    }
}

export default LoginExpired;









