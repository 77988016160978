import React, { Component } from 'react';
import { connect } from 'react-redux';
import { selectTemplate, fetchTemplates } from '../../store/actions';
import {
    Table,
    Button
  } from 'reactstrap';
//import { Link } from 'react-router-dom';

class TemplateList extends Component {
    
    componentDidMount() {
        this.props.fetchTemplates();
    }

    onClickItemEdit(_templateId){
        const editLink = `/templates/edit/${_templateId}`;
        this.props.history.push(editLink);
    }

    render() {

        const renderedItems = this.props.templates.map((template, i) => {
            const controlName = "valid-" + template.id.toString();
            
            return (

                <tr key={i} onClick = { () => this.props.selectTemplate(template) }>
                    <th scope="row">
                        {template.id.toString()}
                    </th>
                    <td>
                        {template.title}
                    </td>
                    <td>
                        {template.created}
                    </td>
                    <td>
                        <label
                            htmlFor={ controlName }
                            title="Change to true" 
                            className="th-switch">
                                <input id="control-valid" type="checkbox" name={ controlName } readOnly={true} checked={ template.visible } />
                                <span className='th-switch-slider round'></span>
                        </label>
                    </td>
                    <td>
                    <button className='th-admin-btn' onClick={ this.onClickItemEdit.bind(this, template.id) }>
                        Edit
                    </button>
                    </td>
                </tr>
            );
        });

        
        return (

            <div className='th-table-container spc-b-l'>

                <table className="th-table">

                    <thead>

                        <tr>
                            <th>ID</th>

                            <th>Title</th>

                            <th>Created</th>

                            <th>Visible</th>

                            <th className='th-table-action-col'>Edit</th>

                        </tr>

                    </thead>

                    <tbody>

                    { renderedItems }

                    </tbody>

                </table>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    
    return {
        templates: state.templates
    };
};

export default connect(
    mapStateToProps, 
    { selectTemplate, fetchTemplates }
)(TemplateList);