import React, { Component } from 'react';
import axios from '../../apis/thresource';
import Loader from '../../components/Loader';

class CompleteRegistration extends Component {
  constructor(props) {
    super(props);

    this.props.onHideHeader();

    this.state = {
      verificationCode: "",
      is2FANotificationAlways: false,
      is2FANotificationNewDevice: false,
      is2FANotificationOneTime: false,
    };

    this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
    this.onChange2FANotificationAlways = this.onChange2FANotificationAlways.bind(this);
    this.onChange2FANotificationNewDevice = this.onChange2FANotificationNewDevice.bind(this);
    this.onChange2FANotificationOneTime = this.onChange2FANotificationOneTime.bind(this);
    this.onClickSendAnotherCode = this.onClickSendAnotherCode.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onChangeVerificationCode(_e) {
    this.setState({
      verificationCode: _e.target.value,
    });
  }

  onChange2FANotificationAlways() {
    this.setState({
      is2FANotificationAlways: true,
      is2FANotificationNewDevice: false,
      is2FANotificationOneTime: false,
    });
  }

  onChange2FANotificationNewDevice() {
    this.setState({
      is2FANotificationNewDevice: true,
      is2FANotificationAlways: false,
      is2FANotificationOneTime: false,
    });
  }

  onChange2FANotificationOneTime() {
    this.setState({
      is2FANotificationOneTime: true,
      is2FANotificationNewDevice: false,
      is2FANotificationAlways: false,
    });
  }

  onClickSendAnotherCode(_e) {
    //console.log("/TODO resent another verification code");
    // _e.preventDefault();

    this.setState({
      verificationCode: "",
    });
    const postData = {
      email: this.props.user.email,
    };

    axios
      .post("/api/account/resendvirifynumber", JSON.stringify(postData))
      .then((response) => {
        //console.log("response data: ", response.data);
        if (!response.data.success && response.data.errors) {
          this.props.onSetAlert(response.data.errors);
        }

        if (response.data.success) {
          const alert = { field_name: "", message: "Code resent" };
          this.props.onSetAlert([alert]);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.props.onSetAlert(error.response.data.errors);
        }
      });
  }

  onSubmitForm(_e) {
    //console.log("on complete registration form submit");
    _e.preventDefault();

    const postData = {
      email: this.props.user.email,
      token: this.state.verificationCode,
      update_2fa: true,
      is_2fa_always: this.state.is2FANotificationAlways,
      is_2fa_device: this.state.is2FANotificationNewDevice,
      is_2fa_onetime: this.state.is2FANotificationOneTime,
    };

    axios
      .post("/api/account/verifynumber", JSON.stringify(postData))
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.props.onSetAlert(response.data.errors);
        }
        if (response.data.success) {
          this.props.history.push(`/register/welcome?name=${this.props.user.first_name}`);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.props.onSetAlert(error.response.data.errors);
        }
      });
  }

  render() {
    const { user } = this.props;
    const { verificationCode, is2FANotificationAlways, is2FANotificationNewDevice, is2FANotificationOneTime } = this.state;

    if (!user) {
      return <Loader />;
    }

    return (
      <div className="content-container full-page d-flex flex-col flex-ai-c flex-jc-c bgc-grade-sunshine pall-l">
        <form className="form-card-container th-br th-fade-in th-shadow-down pall-l" onSubmit={this.onSubmitForm}>
          <div className="th-form-inner-register-verify has-validation-callback">
            <div className="text-center">
              <i className="fa-light fa-shield-check fa-2xl circle-border"></i>
            </div>

            <div>
              <h2 className="hw-font t-ctr t-purple spc-b-r mt-3">Complete Registration</h2>
              <p className="t-ctr">We've sent you a code to {user?.mobile} Complete registration by entering the code.</p>
            </div>

            <div className="th-field-wrapper mt-4">
              <label>Enter Code</label>
              <input type="text" placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;" onChange={this.onChangeVerificationCode} value={verificationCode} />
            </div>
            <div className="th-form-inline-label mt-4">
              <input type="radio" name="2faType" checked={is2FANotificationAlways} onChange={this.onChange2FANotificationAlways} />
              <p className="t-sml">Send a code every time I log in</p>
            </div>
            <div className="th-form-inline-label">
              <input type="radio" name="2faType" checked={is2FANotificationNewDevice} onChange={this.onChange2FANotificationNewDevice} />
              <p className="t-sml">Send a code for a new device</p>
            </div>
            <div className="th-form-inline-label">
              <input type="radio" name="2faType" checked={is2FANotificationOneTime} onChange={this.onChange2FANotificationOneTime} />
              <p className="t-sml">Use code one time</p>
            </div>

            <div className="mt-3">
              <button className="btn btn--primary-fw" type="submit">
                FINISH UP
              </button>
            </div>

            <div className="form-card-footer mt-2-fw">
              <p className="t-large">
                Not received a code?
                <span className="link-label ml-1" onClick={this.onClickSendAnotherCode}>
                  Send another
                </span>
              </p>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CompleteRegistration;


