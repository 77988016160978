import React, { Component } from 'react';
import Loader from '../../components/Loader';

class ChildAccountSuccess extends Component {

    constructor(props){
        super(props);
    this.props.onHideFavorite(true);
        this.onClickGotoChildAccounts = this.onClickGotoChildAccounts.bind(this);
    }

    onClickGotoChildAccounts() {
        this.props.onUpdateChildDetail(null);
        this.props.onNavigateToClientPage('/user/childaccounts');
    }
    
    render() {

        const { childDetail } = this.props;

        if(!childDetail) {
            return (<Loader />);
        }

        return (
            <div className='content-container full-page d-flex flex-col flex-ai-c flex-jc-c bgc-grade-sunshine pall-l'>
                <div className="form-card-container th-br th-fade-in th-shadow-down pall-l">
                    <div className="th-form-inner has-validation-callback">
                        <div>
                            <h2 className="hw-font t-ctr t-purple spc-b-r">Child Registration Complete</h2>
                            <p className="t-ctr t-grey">
                                Child account successfully created with username { childDetail.username }, 
                                click <button className='button btn' onClick={ this.onClickGotoChildAccounts }>HERE</button> to manage child accounts.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ChildAccountSuccess;