
import React, { Component } from 'react';
import TemplateItem from '../../components/TemplateItem';

class Search extends Component {

    constructor(props) {
        super(props);
         this.props.onHideFavorite(true);
    }

    onFavouriteClick (_id, _e) {
        this.props.requestChangeFavouriteTemplate(_id);
    }

    render() {
        const { templateCatalougeFiltered, isLoggedIn, lastSearchTerm, lastSearchOptions, requestTemplateDetails, onNavigateToClientPage } = this.props;
        
        var searchOptionsDescription = "";
        if(lastSearchOptions !== null && lastSearchOptions.length > 0) {
            for(var i = 0; i < lastSearchOptions.length; i++) {
                if(i > 0) {
                    searchOptionsDescription += ", ";
                }
                searchOptionsDescription += lastSearchOptions[i].title;
            }
        }
        return (
            <div className='topic-block topic-block--fh'>
                
                <div className='topic-block-content'>

                    <h1 className='topic-block-content__title'>The results are in...</h1>
                    <p  className='topic-block-content__text'>You searched for <span className='t-bld t-proper'> "{lastSearchTerm} {searchOptionsDescription}"</span>. We hope you found some amazing topic ideas, but if you didn't find what you were looking for, remember you can refine your search with the filters!</p>

                    <div className='topic-block-content__grid'>

                        {
                            templateCatalougeFiltered.map((e, i) => {

                                return ( 
                                
                                    <TemplateItem 
                                        key={i}
                                        template = {e} 
                                        isLoggedIn = { isLoggedIn } 
                                        onNavigateToClientPage = { onNavigateToClientPage }
                                        requestTemplateDetails = { requestTemplateDetails }
                                        requestChangeFavouriteTemplate = { this.onFavouriteClick.bind(this, e.id, e) } />
                                );

                            })
                        }

                    </div>

                </div> 
                
            </div>
        );
    }
}

export default Search;