import React, { Component } from "react";
import axios from "../../apis/thresource";
import { VALID_PASSWORD_REGEX } from "../../constants/regex";

import school_img from "../../assets/img/backgrounds/shutterstock_432862897.jpg";

class RegisterTeacher extends Component {
  constructor(props) {
    super(props);

    this.props.onHideHeader();

    this.state = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      is2FAEnabled: true,
      mobile: "",
      isOptInMarketing: false,
      showVerifyEmail: false,
    };

    this.onChangeFirstName = this.onChangeFirstName.bind(this);
    this.onChangeLastName = this.onChangeLastName.bind(this);
    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onChange2FAEnabled = this.onChange2FAEnabled.bind(this);
    this.onChangeMobile = this.onChangeMobile.bind(this);
    this.onChangeOptInMarketing = this.onChangeOptInMarketing.bind(this);
    this.onChangePassword = this.onChangePassword.bind(this);
    this.navigateToRegister = this.navigateToRegister.bind(this);

    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onChangeFirstName(_e) {
    console.log("onChangeFirstName", _e);
    this.setState({
      firstName: _e.target.value,
    });
  }

  onChangeLastName(_e) {
    console.log("onChangeLastName", _e);
    this.setState({
      lastName: _e.target.value,
    });
  }

  onChangeEmail(_e) {
    console.log("onChangeEmail", _e);
    this.setState({
      email: _e.target.value,
    });
  }

  onChangePassword(_e) {
    this.setState({
      password: _e.target.value,
    });
  }

  onChange2FAEnabled() {
    this.setState({
      is2FAEnabled: !this.state.is2FAEnabled,
    });
  }

  onChangeMobile(_e) {
    console.log("onChangeMobile", _e);
    this.setState({
      mobile: _e.target.value,
    });
  }

  onChangeOptInMarketing() {
    this.setState({
      isOptInMarketing: !this.state.isOptInMarketing,
    });
  }

  navigateToRegister() {
    this.props.history.push("/register");
  }

  onSubmitForm(_e) {
    _e.preventDefault();

    var regex = new RegExp(VALID_PASSWORD_REGEX);
    if (!regex.test(this.state.password)) {
      this.props.onSetAlert([{ message: "password does not satisfy the given combination" }]);
      return;
    }

    const postData = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      mobile: this.state.mobile,
      enable_2fa: this.state.is2FAEnabled,
      opt_in_marketing: this.state.isOptInMarketing,
      teacher: true,
    };

    axios
      .post("/api/account/register", JSON.stringify(postData))
      .then((response) => {
        //console.log("register data: ", postData);
        console.log("response data: ", response.data);
        if (!response.data.success && response.data.errors) {
          this.props.onSetAlert(response.data.errors);
        }

        if (response.data.success) {
          console.log("register complete updating user");
          this.props.onUpdateUser(postData);

          if (postData.enable_2fa) {
            console.log("register navigating");
            this.props.history.push("/register/verify");
          } else {
            this.setState({
              showVerifyEmail: true,
            });
          }
        } else {
          console.log("register failed");
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          //console.log("server data:", error.response.data);
          this.props.onSetAlert(error.response.data.errors);
        }
      });
  }

  render() {
    const { email, firstName, lastName, mobile, is2FAEnabled, isOptInMarketing, password, showVerifyEmail } = this.state;

    return (
      <>
        {!showVerifyEmail ? (
          <div className="register-form-block register-form-block--reverse">
            <div className="register-form-content">
              <div className="back-btn" onClick={this.navigateToRegister}>
                <i className="fa-light fa-circle-chevron-left"></i> Back to registration
              </div>

              <form className="app-form" onSubmit={this.onSubmitForm}>
                <div className="app-form__content">
                  <div>
                    <h2 className="spc-b-r t-hvy spc-b-r mt-5">Register school account</h2>
                    <p className="t-light pbottom-r">
                      Sign-up for a school account and bring the fun of learning through topic-based writing to your classroom. What are you waiting for? Get
                      started today!
                    </p>
                  </div>

                  <div className="app-form__row">
                    <div className="app-field-wrapper">
                      <label>First name</label>
                      <input type="text" placeholder="First name" onChange={this.onChangeFirstName} value={firstName} />
                    </div>
                    <div className="app-field-wrapper">
                      <label>Last name</label>
                      <input type="text" placeholder="Last name" onChange={this.onChangeLastName} value={lastName} />
                    </div>
                  </div>
                  <div className="app-field-wrapper">
                    <label>Email</label>
                    <input type="text" placeholder="Email" onChange={this.onChangeEmail} value={email} />
                  </div>
                  <div>
                    <div className="app-field-wrapper">
                      <label>Set password</label>
                      <input autoComplete="on" name="password" type="password" placeholder="Password" onChange={this.onChangePassword} value={password} />
                    </div>
                    <div className="app-field-wrapper__desc">
                      <label>Password must be at least 8 characters long contain a number and an uppercase letter and special character.</label>
                    </div>
                  </div>

                  <div className="app-field-wrapper app-field-wrapper--inline">
                    <input type="checkbox" value="Enabled" checked={is2FAEnabled} onChange={this.onChange2FAEnabled} />
                    <label>Enable two factor authentication.</label>
                  </div>
                  <div className="app-field-wrapper">
                    <label>Mobile number</label>
                    <input type="text" placeholder="xxxx xxx xxx" onChange={this.onChangeMobile} value={mobile} />
                  </div>
                  {/* <div className="form-inputs">
                                <input type="text" placeholder="" onChange={ this, }
                            </div> */}
                  <div className="app-field-wrapper app-field-wrapper--inline">
                    <input type="checkbox" value="Enabled" checked={isOptInMarketing} onChange={this.onChangeOptInMarketing} />
                    <label>
                      Opt in to recieve offers and updates from Topic Heroes<sup>™</sup>.
                    </label>
                  </div>

                  <div className="th-form-buttons">
                    <button className="th-btn primary-btn" type="submit">
                      {" "}
                      Register now{" "}
                    </button>
                  </div>
                </div>
              </form>
            </div>

            <div className="register-form-message">
              <p className="register-form-message__quote">
                <span>Only 2 in 5 children</span> have access to technology based literacy learning programmes.
              </p>
              <div className="register-form-message__image">
                <img src={school_img} alt="" />
              </div>
            </div>
          </div>
        ) : (
          <div className="content-container full-page d-flex flex-col flex-ai-c flex-jc-c bgc-grade-sunshine pall-l">
            <div className="form-card-container th-br th-fade-in th-shadow-down pall-l">
              <div className="th-form-inner">
                <h2 className="hw-font t-purple t-ctr">Link sent</h2>
                <p className="t-grey t-ctr">An email has been sent to {email}. please check your email for verification.</p>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

export default RegisterTeacher;
