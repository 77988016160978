import React, { Component } from "react";

class SubmitAccountDetails extends Component {
  constructor(props) {
    super(props);

    this.onClickGoToDetails = this.onClickGoToDetails.bind(this);
    this.props.onHideFavorite(true);
  }

  onClickGoToDetails() {
    this.props.onNavigateToClientPage("/user/details");
  }

  render() {
    return (
      <div className="login-block">
        <div className="login-block-card">
          <div className="login-card-content">
            <div>
              <div className="login-card-content__icon">
                <i className="fa-light fa-lock"></i>
              </div>
              <h2 className="login-card-content__title">Account update</h2>
              <p className="login-card-content__text">
                We’ve sent an email to verify these changes. Follow the link to confirm these and your account will be updated.
              </p>
            </div>

            <button className="btn btn--primary-fw" onClick={this.onClickGoToDetails}>
              Continue
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SubmitAccountDetails;
