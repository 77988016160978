import React, { Component } from 'react';
import { ROLE_TYPE_ADMIN, ROLE_TYPE_CHILD } from "../../constants/role_constants";
import { checkAquiredRole } from "../../utils/Utils";

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.props.onHideHeader();

    this.onChangeDisplayWelcomeSetting = this.onChangeDisplayWelcomeSetting.bind(this);
    this.onNavigate = this.onNavigate.bind(this);
  }

  onChangeDisplayWelcomeSetting() {
    this.props.requestUpdateUserWelcomeSetting(!this.props.user.display_welcome);
  }

  onNavigate(path) {
    this.props.history.push(path);
  }

  render() {
    const { user } = this.props;
    const isChild = checkAquiredRole([ROLE_TYPE_CHILD], this.props.auth);

    const isDisplayWelcome = !user.display_welcome;
    return (
      <div className="welcome-block">
        <div className="welcome-content">
          <h1 className="welcome-content__title">Welcome to Topic Heroes, {user !== null ? user.first_name : "Username"}</h1>

          <p className="welcome-content__text">
            Topic Heroes<sup>®</sup> is where children's imagination gets inspired and they become excited about topic-based learning. There's a huge range of
            topics available from sport to climate change to dinosaurs, so what are you waiting for?
            {/* <span className='link-label ml-1' onClick={()=>this.onNavigate("/topics")}>Get started!</span> */}
          </p>

          <div className={`welcome-content__${!isChild ? "grid" : "grid_col_2"}`}>
            <div className="welcome-card">
              <div className="welcome-card-content">
                <div>
                  <div className="welcome-card-content__icon">
                    <i className="fa-light fa-house-blank"></i>
                  </div>
                  <h2 className="welcome-card-content__title">Visit home</h2>
                  <p className="welcome-card-content__text">See your current projects, child accounts and favourite topics all in one place.</p>
                </div>
                <div>
                  <button className="btn btn--primary" onClick={() => this.onNavigate("/")}>
                    Visit home
                  </button>{" "}
                </div>
              </div>
            </div>

            <div className="welcome-card">
              <div className="welcome-card-content">
                <div>
                  <div className="welcome-card-content__icon">
                    <i className="fa-light fa-book-open-cover"></i>
                  </div>
                  <h2 className="welcome-card-content__title">Browse topics</h2>
                  <p className="welcome-card-content__text">Browse through our topic templates and see what you will write about today!</p>
                </div>
                <div>
                  <button className="btn btn--primary" onClick={() => this.onNavigate("/topics")}>
                    Browse topics
                  </button>{" "}
                </div>
              </div>
            </div>

            {!isChild && (
              <div className="welcome-card">
                <div className="welcome-card-content">
                  <div>
                    <div className="welcome-card-content__icon">
                      <i className="fa-light fa-book-open-cover fa-fw"></i>
                    </div>
                    <h2 className="welcome-card-content__title">Child accounts</h2>
                    <p className="welcome-card-content__text">Create and manage child accounts - get them started on a learning adventure.</p>
                  </div>
                  <div>
                    <button className="btn btn--primary" onClick={() => this.onNavigate("/user/childaccounts")}>
                      Child accounts
                    </button>{" "}
                  </div>
                </div>
              </div>
            )}
          </div>

          <div className="welcome-toggle">
            <div className="app-field-wrapper--inline">
              <input type={"checkbox"} onChange={this.onChangeDisplayWelcomeSetting} checked={isDisplayWelcome} />
              <label> Do not display this page again.</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Welcome;