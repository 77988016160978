import React, { Component } from 'react';


class EditFilterOption extends Component {

    constructor(props){
        super(props);

        this.state = {
            changes: null
        }

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onSaveChanges = this.onSaveChanges.bind(this);
         this.props.onHideFavorite(true);


    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.changes !== props.searchFilterOption) {
          return {
            changes: props.searchFilterOption,
          }
        }
        return null
    }

    onChangeTitle(_e){

        var { changes } = this.state;
        changes.title = _e.target.value;
        this.onSaveChanges(changes);
    }


    onSaveChanges(_searchFilterOption) {
        this.setState({
            changes: _searchFilterOption
        });
    }


    onSubmitForm(_e) {
        _e.preventDefault();

        const { changes } = this.state;
        const { searchFilter } = this.props;
        //

        const searchFilterOption = {
            id: changes.id,
            search_filter_id: searchFilter.id,
            title: changes.title, 
        };

        this.props.requestUpdateFilterOption(searchFilterOption);
    }

    render() {

        const { changes } = this.state;
        if(changes === null) {
            return <div>Loading...</div>
        }

        

        const titleText = changes.id < 1 ? "Add Filter Option" : "Edit Filter Option";

        return (
            <div className='content-container bgc-grade-snowfall'>
                <div className='content-mw pall-l'>
                    <div className='th-panel th-br bgc-white pall-l'>
                        <h2 className="hw-font t-purple spc-b-s">{ titleText }</h2>

                        <form className="th-form-container has-validation-callback" onSubmit={ this.onSubmitForm }>
                            <div className="th-form-inner">
                                <div className="th-field-wrapper">
                                    <input type="text" placeholder="Title" onChange={ this.onChangeTitle } value={ changes.title } />
                                </div>

                                <div className="form-buttons">
                                    <button className="th-admin-btn" type="submit"> Save </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                
                
            </div>
        );
    }
}

export default EditFilterOption;