

import React, { Component } from "react";

class Loader extends Component {

    render() {

        return (
            <div className="loader-container d-flex flex-col flex-ai-c flex-jc-c">
                <i className="fa-light fa-cog fa-spin" aria-hidden="true"></i>
            </div>
        );
      }
}
 
export default Loader;


