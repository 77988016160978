import React, { useEffect } from "react";
import { User, School, ShoppingBag, Edit2 } from "lucide-react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Spinner from "react-bootstrap/Spinner";

const schema = yup.object({
  email: yup
    .string()
    .email("Please enter a valid email")
    .required("Email is required"),
  mobile: yup.string().required("Mobile number is required"),
});

const AccountTab = ({ user, requestUpdateUserDetails, history, auth }) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  console.log("Auth111", auth);

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission
    requestUpdateUserDetails(data).then(() => {
      console.log("success");
    });
  };

  useEffect(() => {
    if (user) {
      setValue("first_name", user.first_name);
      setValue("last_name", user.last_name);
      setValue("email", user.email);
      setValue("mobile", user.mobile);
      setValue("marketing_email", user.marketing_email);
      setValue("marketing_sms", user.marketing_sms);
    }
  }, [user, setValue]);

  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 md:grid-cols-2 gap-8"
      >
        <div>
          <h2 className="text-xl font-semibold mb-4">Your contact details</h2>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="firstName"
                className="block text-[12px] font-medium text-purple-200 mb-1"
              >
                First Name
              </label>
              <input
                type="text"
                id="firstName"
                {...register("first_name")}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="lastName"
                className="block text-[12px] font-medium text-purple-200 mb-1"
              >
                Last Name
              </label>
              <input
                type="text"
                id="lastName"
                {...register("last_name")}
                className="w-full px-3 py-2 border border-gray-300 rounded-md"
              />
            </div>
            <div>
              <label
                htmlFor="email"
                className="block text-[12px] font-medium text-purple-200 mb-1"
              >
                Email Address
              </label>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  {...register("email")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md pr-10"
                />
                <Edit2 className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2" />
              </div>
              {errors.email && (
                <p className="text-xs text-red-500 mt-1">
                  {errors.email.message}
                </p>
              )}
              <p className="text-[10px] text-gray-900 mt-1">
                We will send an email to your saved address to verify this
                change
              </p>
            </div>
            <div>
              <label
                htmlFor="phone"
                className="block text-[12px] font-medium text-purple-200 mb-1"
              >
                Phone
              </label>
              <div className="relative">
                <input
                  type="tel"
                  id="mobile"
                  {...register("mobile")}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md pr-10"
                />
                <Edit2 className="w-5 h-5 text-gray-400 absolute right-3 top-1/2 transform -translate-y-1/2" />
              </div>
              {errors.mobile && (
                <p className="text-xs text-red-500 mt-1">
                  {errors.mobile.message}
                </p>
              )}
              <p className="text-[10px] text-gray-900 mt-1">
                We will send a code to your saved number to verify this change
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="mb-8">
            <h2 className="text-xl font-semibold mb-4">Account Password</h2>
            <button
              className="px-4 py-2 bg-gray-300 text-gray-900 rounded-md"
              onClick={() => {
                history.push(
                  `/change-password?token=${auth?.token}&email=${user.email}`
                );
              }}
            >
              Change Password
            </button>
          </div>

          <div>
            <h2 className="text-2xl font-semibold mb-4">
              Marketing Preferences
            </h2>
            <p className="text-[14px] text-gray-900 mb-4">
              We would love to keep in touch with you and share news, offers and
              updates about our products and charity. Your data is important to
              us and we never use it without your permission. Visit our privacy
              policy for more information about how we use data.
            </p>
            <div className="space-y-2">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  {...register("marketing_email")}
                  className="form-checkbox h-5 w-5 text-purple-600"
                />
                <span className="ml-2 text-[14px] text-gray-900">
                  No thank you, do not contact me about Topic Heroes via email
                </span>
              </label>
              <label className="flex items-center">
                <input
                  type="checkbox"
                  {...register("marketing_sms")}
                  className="form-checkbox h-5 w-5 text-purple-600"
                />
                <span className="ml-2 text-[14px] text-gray-900">
                  No thank you, do not contact me about Topic Heroes via SMS
                </span>
              </label>
            </div>
          </div>
        </div>

        <div className="col-span-full">
          <button
            type="submit"
            className=" h-16 px-6 py-2 bg-purple-200 text-white text-[16px] rounded-full hover:bg-purple-700 transition-colors"
          >
            Save Changes
          </button>
        </div>
      </form>
    </div>
  );
};

export default AccountTab;
