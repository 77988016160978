
import React, { Component } from 'react';

import TemplateItem from '../../components/TemplateItem';
import { ROLE_TYPE_CHILD, ROLE_TYPE_TEACHER, ROLE_TYPE_USER } from "../../constants/role_constants";
import { checkAquiredRole } from "../../utils/Utils";

class TopicCatalouge extends Component {
  constructor(props) {
    super(props);
    this.props.onShowHeader();
    this.onSearchTermChange = this.onSearchTermChange.bind(this);
    this.onSubmitSearchFormSubmit = this.onSubmitSearchFormSubmit.bind(this);
    this.props.onHideFavorite(true);
  }

  componentDidMount() {
    if (this.props.templateCatalouge === null) {
      this.props.requestTemplateCatalouge();
    }
  }

  onFavouriteClick(_id, _e) {
    this.props.requestChangeFavouriteTemplate(_id);
  }

  onSearchTermChange(_e) {
    //console.log("on search change: ", _e);
    this.props.onUpdateSearchTerm(_e.target.value);
  }

  onSubmitSearchFormSubmit() {
    this.props.onSubmitSearch();
  }

  render() {
    const {
      templateCatalouge,
      isLoggedIn,
      requestChangeFavouriteTemplate,
      requestTemplateDetails,
      onNavigateToClientPage,
      auth,
    } = this.props;
   
    const isChild = checkAquiredRole([ROLE_TYPE_CHILD], auth);
    const isParentOrTeacher = checkAquiredRole([ROLE_TYPE_USER, ROLE_TYPE_TEACHER], auth);

    return (
      <div className="topic-block topic-block--grey-xl">
        <div className="topic-block-content">
          <h1 className="text-4xl font-bold mb-4">Topics at your fingertips!</h1>

          <div className="topic-block-content__grid">
            {templateCatalouge.map((e, i) => {
              return (
                <TemplateItem
                  key={i}
                  template={e}
                  isLoggedIn={isLoggedIn}
                  onNavigateToClientPage={onNavigateToClientPage}
                  requestTemplateDetails={requestTemplateDetails}
                  requestChangeFavouriteTemplate={
                    requestChangeFavouriteTemplate
                  }
                />
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default TopicCatalouge;
