import React, { Component } from 'react';
import {
    Button
} from 'reactstrap';


class FilterItem extends Component {


    render() {
        
        const { filter, index } = this.props;
        

        return (
            <tr>
                <th scope="row">
                    {filter.id.toString()}
                </th>
                <td>
                    {filter.title}
                </td>
                <td>
                    {filter.options.length.toString()}
                </td>
                <td>
                    <button className='th-admin-btn' onClick={ () => this.props.onItemEditClick(filter) } >
                        Edit
                    </button>
                </td>
                <td>
                    <button className='th-admin-btn' onClick={ () => this.props.onItemOptionsClick(filter) } >
                        Options
                    </button>
                </td>
            </tr>
        );
    }
}

export default FilterItem;

/**
 * 
 * // <td>
                //     <Link to= { editLink } >Edit</Link>
                // </td>
 */