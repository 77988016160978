

import React, { Component } from 'react';
//import PrintessUi from './Printess-Ui/Printess';
//import UiSelection from './Ui-Selection/UiSelection';
import '../assets/css/bs-layouts.css';
import Loader from '../components/Loader';
import PrintessIframe from './Printess-Iframe/Printess-Iframe';
class PrintessPage extends Component {

    constructor(props) {
        super(props);
    }

    render() {

        const { saveToken, activeBookId, user, savedBooks } = this.props;
        if(activeBookId < 1) {
            return <Loader />
        }

        

        return (
            // <PrintessUi 
            //     user = { user }
            //     templateName={ templateDetail.title }
            //     activeBookId = { activeBookId }
            //     {...this.props} /> 
            <PrintessIframe
                user = { user }
                saveToken = { saveToken }
                activeBookId = { activeBookId }
                savedBooks = { savedBooks }
                {...this.props}
            />
        );
    }
}

export default PrintessPage;
