
import React, { Component } from 'react';
import SubjectList from '../../../components/subjects/SubjectList';
import { defaultSubject } from '../../../constants/data';

class Subjects extends Component {

    constructor(props) {
        super(props);

        this.onClickAddSubject = this.onClickAddSubject.bind(this);
         this.props.onHideFavorite(true);

    }

    onClickAddSubject() {

        this.props.onUpdateSubject(defaultSubject);
        this.props.history.push("/categories/add");
    }

    
    

    render() {

        return (
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>
                    <div className='th-panel bgc-white pall-l th-br'>

                        <div className="spc-b-l">
                            <h2 className="hw-font spc-b-s t-purple">Manage Categories</h2>
                            <p></p>
                        </div>

                        <SubjectList 
                            auth = { this.props.auth }
                            school = { this.props.school }
                            onUpdateSubject = { this.props.onUpdateSubject }
                            history= { this.props.history } 
                        />

                        <div>
                            <button className='th-admin-btn' onClick={ this.onClickAddSubject }>Add Category</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Subjects;