import React, { Component } from "react";
import axios from "../apis/thresource";
class ProjectItem extends Component {
  constructor(props) {
    super(props);
    this.onClickProjectDetails = this.onClickProjectDetails.bind(this);
    this.onChildSelectCheckbox = this.onChildSelectCheckbox.bind(this);
    this.onRestoreProject = this.onRestoreProject.bind(this);

    this.state = {
      completed: this.props?.project?.completed,
      isUpdating: false,
      showRestore: this.props?.project?.completed,
    };
  }

  onProjectClick(_projectId) {
    this.props.onSelectSavedBook(_projectId);
  }

  onClickProjectDetails(id) {
    this.props.onNavigateToClientPage(`/topics/${id}/details`);
  }

  onChildSelectCheckbox(e) {
    this.setState({
      isUpdating: true,
    });
    const value = e.target.checked;
    const postData = {
      toggle: value,
    };

    axios
      .put(`/api/savedbooks/student/${this.props.project.id}/completion`, postData, this.props.getRequestHeaders())
      .then(() => {
        this.props.requestSavedBooks();
        this.setState({
          completed: value,
          isUpdating: false,
        });
      })
      .catch((error) => {
        this.setState({
          isUpdating: false,
        });
        if (error.response && error.response.data) {
          this.props.onSetAlert(error.response.data.errors);
        }
      });
  }

  onRestoreProject(e) {
    const value = e.target.checked;
    if (value) {
      this.setState({
        isUpdating: true,
      });

      axios
        .put(`/api/savedbooks/${this.props.project.id}/restore`, {}, this.props.getRequestHeaders())
        .then(() => {
          this.props.requestSavedBooks();
          this.setState({
            completed: false,
            showRestore: false,
            isUpdating: false,
          });
        })
        .catch((error) => {
          this.setState({
            isUpdating: false,
          });
          if (error.response && error.response.data) {
            this.props.onSetAlert(error.response.data.errors);
          }
        });
    }
  }

  render() {
    const { project } = this.props;
    const { completed, showRestore, isUpdating } = this.state;

    return (
      <div className="project-card">
        <div className="project-card-content">
          {completed && <div className="topic-completed-tag">Completed</div>}
          <div className="project-card-content__image">
            <img onClick={this.onProjectClick.bind(this, project.id)} src={project.thumbnail_url} alt="" />
          </div>
          <div className="project-card-desc">
            <h5 className="project-card-desc__title">{project.title}</h5>
            <p className="project-card-desc__text"> Written by {project.author_display_name} </p>
            <button className="btn btn--sml btn--success" onClick={this.onProjectClick.bind(this, project.id)} disabled={this.props.isChild && completed}>
              Edit project
            </button>
          </div>
        </div>

        {this.props.isChild && (
          <>
            <div className="topic-status-section">
              <div className="font-weight-bold">{completed ? "Wait, I’m not done" : "I’m done!"}</div>
              <input type="checkbox" checked={completed} className="custom-checkbox" onChange={this.onChildSelectCheckbox} disabled={isUpdating} />
            </div>
            <div className="topic-status-section">
              <span className="mt-2 topic-status-section__text">
                {completed ? "Uncheck the box to get your project back" : "Check the box to mark your project complete"}
              </span>
            </div>
          </>
        )}

        {this.props.isParentOrTeacher && showRestore && (
          <>
            <div className="topic-status-section">
              <div className="font-weight-bold">Return project</div>
              <input type="checkbox" className="custom-checkbox" onChange={this.onRestoreProject} disabled={isUpdating} />
            </div>
            <div className="topic-status-section">
              <span className="mt-2 topic-status-section__text">Check the box to restore the project</span>
            </div>
          </>
        )}

        <div className="project-card-footer">
          <button className="project-info-btn" onClick={() => this.onClickProjectDetails(project.template_id)}>
            View Topic Info <i class="fa-light fa-circle-info"></i>
          </button>
        </div>
      </div>
    );
  }
}
 
export default ProjectItem;

