import React from "react";



const FooterSection = ({ title, links }) => (
  <div className="flex flex-col space-y-2">
    <h3 className="font-semibold mb-2 text-2xl">{title}</h3>
    {links.map((link) => (
      <a
        key={link.href}
        href={link.href}
        className=" text-gray-800 hover:text-gray-900 font-medium text-lg"
      >
        {link.text}
      </a>
    ))}
  </div>
);

const SocialIcon = ({ href, Icon }) => (
  <a href={href} className="text-purple-600 hover:text-purple-800 text-2xl">
    <Icon size={24} />
  </a>
);

export default function Footer() {
  const currentYear = new Date().getFullYear();

  const sections = [
    {
      title: "Useful Links",
      links: [
        { text: "Contact us", href: "/contact" },
        { text: "Delivery Information", href: "/delivery" },
        { text: "FAQ's", href: "/faqs" },
        { text: "Cookie Policy", href: "/cookie-policy" },
        { text: "Privacy Policy", href: "/privacy-policy" },
        { text: "Terms & Conditions", href: "/terms" },
      ],
    },
    {
      title: "About us",
      links: [
        { text: "Our mission", href: "/mission" },
        { text: "Free for Schools", href: "/schools" },
        { text: "Contributors", href: "/contributors" },
        { text: "Donations", href: "/donations" },
        { text: "Sponsorship", href: "/sponsorship" },
      ],
    },
    {
      title: "Your Account",
      links: [
        { text: "Account login", href: "/login" },
        { text: "Forgotten Password", href: "/forgot-password" },
        { text: "Account Registration", href: "/register" },
        { text: "Eligibility", href: "/eligibility" },
      ],
    },
  ];

//   const socialLinks = [
//     { Icon: instagramIcon, href: "https://facebook.com" },
//     { Icon: instagramIcon, href: "https://linkedin.com" },
//     { Icon: instagramIcon, href: "https://instagram.com" },
//   ];

  return (
    <footer className="bg-gray-100 border-t border-gray-200 pt-12 pb-8">
      <div className="container mx-auto px-4">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {sections.map((section) => (
            <FooterSection
              key={section.title}
              title={section.title}
              links={section.links}
            />
          ))}
          <div className="flex flex-col space-y-2">
            <h3 className="font-semibold text-lg mb-2">Follow us</h3>
            {/* <div className="flex space-x-4">
              {socialLinks.map((link) => (
                <SocialIcon key={link.Icon.name} {...link} />
              ))}
            </div> */}
          </div>
        </div>
        <div className="mt-12 pt-8 border-t border-gray-300 flex justify-between items-center text-sm text-gray-800">
          <p>&copy; {currentYear} Topic name All rights reserved</p>
          <p>Registered Charity XXX</p>
        </div>
      </div>
    </footer>
  );
}
