import React from "react";
//import UiSelection from '../Ui-Selection/UiSelection';

import "bootstrap/dist/css/bootstrap.min.css";
import "./Printess-Iframe.css";
import { PrintessProps } from "../Printess-Ui/PrintessProps";
import { checkAquiredRole } from "../../utils/Utils";
import { ROLE_TYPE_CHILD } from "../../constants/role_constants";
import { PRINTESS_EDITOR_URL } from "../../constants/data";

interface PrintessState {
  loadedStamp: Date;
}

class PrintessIframe extends React.Component<PrintessProps, PrintessState> {
  state = {
    loadedStamp: new Date(),
  };

  isChildUser = checkAquiredRole([ROLE_TYPE_CHILD], this.props.auth);

  componentDidMount(): void {
    const token = process.env.REACT_APP_PRINTESS_TOKEN === "ToBeReplaced" ? process.env.REACT_APP_TEST_TOKEN : process.env.REACT_APP_PRINTESS_TOKEN;

    const iframe = document.getElementsByTagName("iframe")[0];
    if (iframe) {
      iframe.onload = () => {
        /* **************************** */
        /* listen to printess callbacks */
        /* **************************** */
        window.addEventListener("message", this.printessCommunication);

        /* *************************** */
        /*    load Printess editor     */
        /* *************************** */

        iframe.contentWindow?.postMessage(
          {
            cmd: "attach",
            properties: {
              /* Paste your Printess shop-token here */
              token: token, //

              /* Name of the template to load. You can also pass a save-token from "basket" or "back" callback here to load previously saved work. */
              templateName: this.props.saveToken,
              /* A unique ID to identify this session, can later be used to connect to a UserID once the user has logged in or createdan account */
              basketId: this.props.activeBookId.toString(),

              /* Optional if available: the ID of the current shop-user to keep all uploaded resources together and display for reccuring users */
              shopUserId: this.props.user.id,

              showAddToBasketButton: !this.isChildUser, // Hide "Add to basket" button

              translations: {
                custom: {
                  name: "Your Name", // you can access this translation with ${gl("custom.name")}
                },
                ui: {
                  buttonBasket: "Add to Basket",
                  okButton: "Perfect!",
                  buttonDone: "Done",
                  buttonBack: "Close",
                  buttonClose: "Close Button",
                  closeEditorTextOne: "Would you like to save your changes?",
                  closeEditorTextTwo: "You are exiting the editor!",
                  applyChanges: "Apply Changes",
                },
              },
            },
          },
          "*"
        );
      };

      /* *************************** */
      /*   Forward Visual Viewport   */
      /* *************************** */
      if (window.visualViewport) {
        window.visualViewport.addEventListener("scroll", () => {
          // unfortunately an iframe on iOS is not able to receive the correct visual-viewport, so we forward it.
          iframe.contentWindow?.postMessage({ cmd: "viewportScroll", height: window.visualViewport.height, offsetTop: window.visualViewport.offsetTop }, "*");
        });
      }
    }
  }

  componentWillUnmount(): void {
    const iframe = document.getElementsByTagName("iframe")[0];
    if (iframe) {
      window.removeEventListener("message", this.printessCommunication);
      iframe.onload = null;
    }
  }

  printessCommunication = (event: any): void => {
    console.log(`printess message received cmd: ${event.data.cmd}`, JSON.stringify(event.data));
    switch (event.data.cmd) {
      case "loaded":
        this.setState({
          loadedStamp: new Date(),
        });
        break;

      case "back":
        this.props.requestChangeSavedBookToken(event.data.token, "");
        this.props.onToggleEditorMode();
        break;

      case "basket":
        this.props.requestAddToBasket(event.data.token);
        break;

      case "save":
        this.props.requestChangeSavedBookToken(event.data.token, "");
        break;

      case "formFieldChanged":
        const msg = document.getElementById("message");
        if (msg) {
          msg.style.display = "flex";
          msg.textContent = "Form Field: [" + event.data.name + "] changed to '" + event.data.value + "'";
          setTimeout(() => {
            const msgUpdated = document.getElementById("message");
            if (msgUpdated) msgUpdated.style.display = "none";
          }, 2000);
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <>
        {
          <>
            <iframe title="printess editor" id="printess" src={PRINTESS_EDITOR_URL}></iframe>
            <div id="message"></div>
          </>
        }
      </>
    );
  }
}

export default PrintessIframe;
