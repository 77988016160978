import React, { Component } from 'react';
import axios from '../../apis/thresource';
import Loader from '../../components/Loader';


class Verify2FA extends Component {

    constructor(props){
        super(props);

         this.props.onHideHeader();

        this.state = {
            verificationCode: "",
        };

        this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
        this.onClickSendAnotherCode = this.onClickSendAnotherCode.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    onChangeVerificationCode(_e){
        this.setState({
            verificationCode: _e.target.value
        });
    }


    onClickSendAnotherCode(_e) {
        this.setState({
            verificationCode:""
        })
        const postData = {
            email: this.props.user.email
        };

        axios.post('/api/account/resendvirifynumber', JSON.stringify(postData))
        .then(response => {

            if(!response.data.success && response.data.errors) {
                this.props.onSetAlert(response.data.errors);
            }

            if(response.data.success) {
                const alert = { field_name: "", message: "Code resent" };
                this.props.onSetAlert([alert]);
            }
            
        })
        .catch(error => {
            if (error.response && error.response.data) {
                this.props.onSetAlert(error.response.data.errors);
            }
        });


    }
    
    onSubmitForm(_e){
        _e.preventDefault();

        const postData = {
            email: this.props.user.email,
            token: this.state.verificationCode,
            update_2fa:false
        };


        axios.post('/api/account/verifynumber', JSON.stringify(postData))
        .then(response => {
            if(!response.data.success && response.data.errors) {
                this.props.onSetAlert(response.data.errors);
            }
            if(response.data.success) {
               this.props.history.push(`/reset-password?token=${this.props.user.resetToken}&email=${this.props.user.email}`);
            }
        })
        .catch(error => {
            if (error.response && error.response.data) {
                //console.log("server data:", error.response.data);
                this.props.onSetAlert(error.response.data.errors);
            }
        });
    }

    render() {

        const { user } = this.props;
        const { verificationCode } = this.state;

        if(!user) {
            return (<Loader />);
        }

        return (
            <div className='content-container full-page d-flex flex-col flex-ai-c flex-jc-c bgc-grade-sunshine pall-l'>
                <form
                    className="form-card-container th-br th-fade-in th-shadow-down pall-l" 
                    onSubmit={ this.onSubmitForm } >
                    <div className="th-form-inner-register-verify has-validation-callback">

                        <div className="text-center">
                            <i className="fa-light fa-shield-check fa-2xl circle-border"></i>
                        </div>
                        
                        <div>
                            <h2 className="hw-font t-ctr t-purple spc-b-r mt-3">Authentication</h2>
                            <p className="t-ctr">
                                We've sent you a code to {user?.mobile} Please enter the code to reset password.
                            </p>
                        </div>
                        
                        <div className="th-field-wrapper mt-4">
                            <label>Enter Code</label>
                             <input type="text" placeholder='&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;' onChange={ this.onChangeVerificationCode } value={ verificationCode } />
                        </div>
                       
                        <div className="inline-button-container mt-3">
                            <button className="col-12 th-btn yellow-btn" type="submit"> Next </button>
                        </div>

                        <div className='form-card-footer mt-2'>
                            <span >Not received a code? 
                            <span className='link-label ml-1' onClick={this.onClickSendAnotherCode}>Send another</span>
                            </span>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

export default Verify2FA;


