import React, { Component } from 'react';
import { formatDate } from '../../utils/Utils';


class OrderHistoryEntry extends Component {

    

    render() {
        
        const { order } = this.props;
        

        return (
            <tr>
                <th scope="row">
                    {order.id}
                </th>
                <td>
                    {order.complete.toString()}
                </td>
                <td>
                    {formatDate(order.created)}
                </td>
                <td>
                    <button className='btn btn--sml btn--success' onClick={ () => this.props.onItemViewClick(order) } >
                        View
                    </button>
                </td>
            </tr>
        );
    }
}

export default OrderHistoryEntry;