
import React, { Component } from 'react';
import TemplateList from '../../../components/template-list/TemplateList';

class Templates extends Component {

    constructor(props) {
        super(props);

        this.onSyncButtonClick = this.onSyncButtonClick.bind(this);
         this.props.onHideFavorite(true);

    }

    onSyncButtonClick() {
        this.props.history.push("/templates/sync");
    }

    render() {

        return (
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>
                        
                    <div className='th-panel bgc-white pall-l th-br'>

                            <div className="spc-b-l">
                                <h2 className="hw-font spc-b-s t-purple">Manage Templates</h2>
                                
                                <p>All current templates are listed below. You can edit these templates to change details and assets associated with them. 
                                    To search and fetch new templates, hit the 'Sync Templates' button.</p>
                            </div>

                        <TemplateList history={ this.props.history } />

                        <div>

                            <button className='th-admin-btn' onClick={ this.onSyncButtonClick }>Sync Templates</button>

                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default Templates;