import React, { Component } from "react";
import { CircleArrowRight, Heart } from "lucide-react";

//import { NavLink as RRNavLink, Link } from 'react-router-dom';

class TemplateItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      favourite: false,
      shareOpen: false,
    };

    this.onFavouriteTopic = this.onFavouriteTopic.bind(this);
    this.onClickGoToDetails = this.onClickGoToDetails.bind(this);
    this.onShareClick = this.onShareClick.bind(this);
  }

  componentDidMount() {
    this.setState({
      favourite: this.props.template.favourite,
    });
  }

  static getDerivedStateFromProps(props, current_state) {
    if (
      props.template &&
      props.template.favourite &&
      current_state.favourite !== props.template.favourite
    ) {
      return {
        favourite: props.template.favourite,
      };
    }
    return null;
  }

  onFavouriteTopic() {
    const { template } = this.props;
    this.props.requestChangeFavouriteTemplate(template.id);
  }

  onClickGoToDetails() {
    const { template } = this.props;
    this.props.requestTemplateDetails(template.id);
    this.props.onNavigateToClientPage(`/topics/${template.id}/details`);
  }

  onShareClick() {
    //pop modal to show share optoions

    this.setState({
      shareOpen: !this.state.shareOpen,
    });
  }

  onShareOptionClick(_shareType) {
    var topicURL = `${process.env.REACT_APP_LIVE_API}/topics/${this.props.template.id}/details`; //`${window.location.href}/${this.props.template.id}`;

    switch (_shareType) {
      case "twitter":
        window.open(
          "https://twitter.com/intent/tweet?text=" + encodeURI(topicURL),
          "_blank" // <- This is what makes it open in a new window.
        );
        break;

      case "facebook":
        window.open(
          "https://www.facebook.com/sharer/sharer.php?u=" + encodeURI(topicURL),
          "_blank" // <- This is what makes it open in a new window.
        );
        break;

      case "email":
        window.open(
          "mailto:test@example.com?subject=TopicHeroes&body=" +
            encodeURI(topicURL)
        );
        break;

      default:
        /* Copy the text inside the text field */
        navigator.clipboard.writeText(topicURL);
        alert("Link copied to clipboard");
        break;
    }
  }

  render() {
    const { template, isLoggedIn } = this.props;
    const { shareOpen } = this.state;

    const heartClass = template.favourite === true ? "fa-solid" : "fa-light";
    const shareClass = shareOpen === true ? "fa-regular" : "fa-light";
    const shareBoxClass =
      shareOpen === true ? "share-expanded open" : "share-expanded";

    return (
      <div className="topic-card">
        <div className="topic-card-content">
          <div
            className="topic-card-content__image"
            onClick={this.onClickGoToDetails}
          >
            <img
              src={template.thumbnail_url}
              alt=""
              className="w-full h-auto"
            />
            {isLoggedIn && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                this.onFavouriteTopic()
              }}
              className="absolute top-2 right-2 p-2 rounded-full bg-white shadow-md"
            >
              <Heart
                className={`w-7 h-7 ${
                  template.favourite
                    ? "text-red-200"
                    : "text-red-900"
                }`}
                fill={template.favourite ? "red" : "none"}
              />
              </button>
            )}
          </div>

          {shareOpen === false ? (
            <div className="topic-card-desc">
              <h2
                className="text-black-100 text-[22px] font-semibold"
                onClick={this.onClickGoToDetails}
              >
                {template.title}
              </h2>
              <p className="topic-card-desc__text">{template.subtitle}</p>
            </div>
          ) : null}

          <div className={`topic-card-desc ${shareBoxClass}`}>
            <h2 className="topic-card-desc__title">{template.title}</h2>
            <p className="topic-card-desc__text">
              Share this topic with your friends.
            </p>
            <div className="topic-card-share">
              <button
                className="th-social-share-btn"
                onClick={() => this.onShareOptionClick("twitter")}
              >
                <i className="fa-light fa-brands fa-twitter"></i>
              </button>
              <button
                className="th-social-share-btn"
                onClick={() => this.onShareOptionClick("facebook")}
              >
                <i className="fa-brands fa-facebook-f "></i>
              </button>
              <button
                className="th-social-share-btn"
                onClick={() => this.onShareOptionClick("email")}
              >
                <i className="fa fa-envelope"></i>
              </button>
              <button
                className="th-social-share-btn"
                onClick={() => this.onShareOptionClick("clipboard")}
              >
                <i className="fas fa-clipboard"></i>
              </button>
            </div>
          </div>
        </div>

        <div className="flex flex-row justify-between items-start w-full p-6 border-t border-gray-300">
          <p className="text-gray-900 text-[12px]">
            Age Group:{" "}
            {template.age_range ? template.age_range : <span>N/A</span>}
          </p>

          <div className="th-topic-card-icons flex flex-row items-center gap-2">
            <p className="text-gray-900 text-[12px]">Read more</p>
            {/* {
                            (isLoggedIn === true) ? (
                                <button onClick={ this.onFavouriteTopic } ><i className={ `topic-card-footer__icons topic-card-footer__icons--fav ${heartClass} fa-heart fa-fw`} ></i></button>
                            ) : null
                        }

                        <button onClick={ this.onShareClick }><i className={ `topic-card-footer__icons ${shareClass} fa-arrow-up-from-bracket fa-fw` }></i></button> */}

            {/* <button onClick={ this.onClickGoToDetails }><i className="topic-card-footer__icons fa-light fa-magnifying-glass-plus fa-fw"></i></button> */}
            <CircleArrowRight
              onClick={this.onClickGoToDetails}
              className="text-gray-800 cursor-pointer"
            />
          </div>
        </div>
      </div>
    );
  }
}

// <button class="social_share" data-type="facebook">Facebook</button>
// <button class="social_share" data-type="twitter">Twitter</button>

export default TemplateItem;
