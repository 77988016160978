import React, { Component } from 'react';
import Loader from '../../components/Loader';
import queryString from 'query-string';

class RegisterWelcome extends Component {

    constructor(props){
        super(props);
        var firstName = queryString.parse(this.props.location.search).name;
        this.state = { firstName }
        this.onClickGotoLogin = this.onClickGotoLogin.bind(this);
    }

    onClickGotoLogin() {
        this.props.onNavigateToClientPage('/login');
    }
    
    render() {

        if(!this.state.firstName) {
            return (<Loader />);
        }

        return (
            <div className='content-container full-page d-flex flex-col flex-ai-c flex-jc-c bgc-grade-sunshine pall-l'>
                <div className="form-card-container th-br th-fade-in th-shadow-down pall-l">
                    <div className="th-form-inner has-validation-callback">
                        <div>
                            <h2 className="hw-font t-ctr t-purple spc-b-r">Registration Complete</h2>
                            <p className="t-ctr t-grey">Welcome {this.state.firstName } Click 
                             <span className='link-label ml-1' onClick={ this.onClickGotoLogin }>HERE</span> to go to the login page.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RegisterWelcome;