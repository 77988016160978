import React, { Component } from "react";
import axios from "../../apis/thresource";
import queryString from "query-string";
import { VALID_PASSWORD_REGEX } from "../../constants/regex";
import toast from "react-hot-toast";

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.props.onHideHeader();
    
    var token =
      queryString.parse(this.props.location.search).token ??
      props?.auth?.token?.token;
    this.state = {
      newPassword: "",
      confirmPassword: "",
      resetToken: token?.replace(/ /g, "+"),
      email: queryString.parse(this.props.location.search).email,
      passwordMismatched: false,
    };

    this.onChangePassword = this.onChangePassword.bind(this);
    this.onChangeConfirmPassword = this.onChangeConfirmPassword.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onChangePassword(_e) {
    this.setState({
      newPassword: _e.target.value,
    });
  }

  onChangeConfirmPassword(_e) {
    this.setState({
      confirmPassword: _e.target.value,
    });
  }

  onSubmitForm(_e) {
    _e.preventDefault();

    var regex = new RegExp(VALID_PASSWORD_REGEX);
    if (!regex.test(this.state.newPassword)) {
      toast.error("password does not satisfy the given combination");
      return;
    }

    if (this.state.newPassword != this.state.confirmPassword) {
      toast.error("The password and confirm password does not match.");
      return;
    }

    const postData = {
      email: this.state.email,
      token: this.state.resetToken?.trim(),
      password: this.state.newPassword,
      confirmPassword: this.state.confirmPassword,
    };
    const toastId = toast.loading("Please wait...");
    axios
      .post(`/api/auth/reset-password`, JSON.stringify(postData))
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          console.log(response.data.errors);
          toast.error(response.data.errors[0]?.message);
        }
        if (response.data.success) {
          toast.success("password changed successfully!");
          this.props.history.push("/login");
        }
        toast.dismiss(toastId);
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          toast.error(error.response.data.errors[0]?.message);
        }
        toast.dismiss(toastId);
      });
  }

  render() {
    const { newPassword, confirmPassword } = this.state;

    return (
      <div className="reset-block">
        <form className="reset-block-card" onSubmit={this.onSubmitForm}>
          <div>
            <div className="reset-card-content__icon">
              <i className="fa-light fa-paper-plane"></i>
            </div>
            <h2 className="reset-card-content__title">Change password</h2>
            <p className="reset-card-content__text">
              Create a new password by entering it below.
            </p>
          </div>

          <div className="reset-card-content__form">
            <div>
              <label className="block text-lg font-medium text-primary-400">
                New Password
              </label>
              <input
                autoComplete="on"
                name="password"
                type="password"
                placeholder="Password"
                onChange={this.onChangePassword}
                value={newPassword}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
              />
            </div>
            <div className="desc-field-wrapper">
              <label>
                Password must be at least 8 characters long, contain a number
                and an uppercase letter and special character
              </label>
            </div>
          </div>

          <div>
            <label className="block text-lg font-medium text-primary-400">
              Confirm Password
            </label>
            <input
              autoComplete="on"
              name="password"
              type="password"
              placeholder="Password"
              onChange={this.onChangeConfirmPassword}
              value={confirmPassword}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primar"
            />
          </div>

          <button
            type="submit"
            className="flex justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-full h-20 mt-8 hover:bg-primary-300"
          >
            Finish
          </button>
        </form>
      </div>
    );
  }
}

export default ResetPassword;
