import React, { Component } from 'react';

import {
    Table,
    Button,
    ButtonGroup
} from 'reactstrap';


class FilterOptions extends Component {

    constructor(props){
        super(props);

        this.onDeleteOptionClick = this.onDeleteOptionClick.bind(this);
        this.onAddOptionClick = this.onAddOptionClick.bind(this);
         this.props.onHideFavorite(true);

    }

    onAddOptionClick() {

        
        const { searchFilter } = this.props;

        this.props.onUpdateSearchFilterOption({
            id: 0,
            search_filter_id: searchFilter.id,
            title: ""
        });

        const optionsLink = `/filters/edit/${searchFilter.id}/options/add`;
        this.props.history.push(optionsLink);
    }

    onDeleteOptionClick(_option) {
        console.log("delete option click: ", _option);

        const { searchFilter } = this.props;
        
        this.props.requestDeleteFilterOption(_option);
        this.props.onUpdateSearchFilterOption(null);

        this.props.history.push(`/filters/edit/${searchFilter.id}/options`);
    }

    render() {

        const { searchFilter } = this.props;
        if(searchFilter === null) {
            return <div>Loading...</div>
        }

        return (
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>

                    <div className='th-panel th-br bgc-white pall-l'>
                
                        <h2 className="hw-font t-purple spc-b-s">Options for filter: { searchFilter.title }</h2>

                        <p>All current templates are listed below. You can edit these templates to change details and assets associated with them. To search and fetch new templates, hit the 'Sync Templates' button.</p>

                        <div className='th-table-container spc-b-l'>

                            <table className="th-table">

                                <thead>

                                    <tr>

                                        <th>Title</th>

                                        <th>Delete</th>

                                    </tr>

                                </thead>

                                <tbody>

                                    { 
                                        searchFilter.options.map((filterOption, i) => {
                                            return (
                                                <tr key={filterOption.id} 
                                                    >
                                                    <td>{ filterOption.title }</td>
                                                    <td className='th-table-action-col'>
                                                    <button className='th-admin-btn fw-btn' onClick={ this.onDeleteOptionClick.bind(this, filterOption) } >Delete</button>
                                                    </td>
                                                </tr>
                                            );
                                        }) 
                                    }

                                </tbody>

                            </table>

                        </div>

                        <button className='th-admin-btn' onClick={ this.onAddOptionClick }>Add Option</button>

                    </div>

                </div>

            </div>
        );
    }
}

export default FilterOptions;