import React from "react";

const OrderHistoryItem = ({item}) => {
  const { id, created, completed } = item?.props?.order;
  return (
    <div className="!bg-white-100 p-4 rounded-2xl shadow-md mb-4 flex flex-col justify-between h-full">
      <div>
        <h1 className="text-2xl font-semibold mb-4">Order {id}</h1>
      </div>
      <div className="flex justify-between items-center">
        <p className="text-[12px] text-gray-900">
          Created: {new Date(created).toISOString().split("T")[0]}
        </p>
        <button
          onClick={() => item?.props?.onItemViewClick(item?.props?.order)}
          className="px-4 py-2 bg-gray-200 text-gray-900 text-[14px] rounded-full !hover:bg-purple-100 transition-colors"
        >
          View Order
        </button>
      </div>
    </div>
  );
};

export default OrderHistoryItem;
