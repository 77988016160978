import React, { useState } from "react";
import ChildAccountItem from "../../User/ChildAccountItem";
import { RefreshCcw } from "lucide-react";
import AddChildAccountForm from "./AddChildAccountForm";

const ChildAccountsTab = ({
  user,
  onNavigateToClientPage,
  requestOneTimeCode,
  onHideHeader,
  clearOnetimeCode,
  onShowHeader,
  isTeacher,
  isParent,
  onUpdateChildDetail,
  requestAddChildrenBulk,
}) => {
  const [showAddForm, setShowAddForm] = useState(false);

  const onClickGoToAccounts = (isCreate) => {
    onNavigateToClientPage(`/user/childaccounts?create=${isCreate}`);
  };

  const onClickGenerateOnetimeCode = () => {
    requestOneTimeCode();
    onHideHeader();
  };

  const onClickEditChildAccount = (_child) => {
    const editLink = `/User/ChildAccounts/edit/${_child.id}`;
    onUpdateChildDetail(_child);
    onNavigateToClientPage(editLink);
  };

  const onClickSubmitQuickbuildChildren = (qbChildren) => {
    console.log("Wanted to save: " + JSON.stringify(qbChildren));
    if (qbChildren.length > 0) {
      requestAddChildrenBulk(qbChildren);
    }
  };

  const handleAddAccount = (formData) => {
    // Implement the logic to add a new account
    console.log("Adding new account:", formData);
    onClickSubmitQuickbuildChildren([formData]);
    setShowAddForm(false);
  };

  // Check if user is logged in
  if (!user) {
    return (
      <div className="section-block bg-transparent">
        <h2 className="text-2xl font-bold mb-4">Child Accounts</h2>
        <p className="text-gray-900">
          Please log in to view and manage child accounts.
        </p>
      </div>
    );
  }

  return (
    <div className="section-block bg-transparent">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-2xl font-bold">
          Class List - {user.children.length} Members
        </h2>
        {user?.children && user?.children?.length > 0 && (
          <span className="text-gray-900">
            Class login code:{" "}
            <span className="text-primary-400 font-semibold">090896</span>
          </span>
        )}
      </div>
      {user.children && user.children.length > 0 ? (
        <div className="flex justify-between items-center mb-4">
          <p>This is where you can access your class information</p>
          <button
            className="text-purple-600 hover:text-purple-800 transition-colors"
            onClick={onClickGenerateOnetimeCode}
          >
            <p className="flex items-center space-x-2 text-[14px]">
              <RefreshCcw className="w-8 h-8 text-primary-400 mr-2" />{" "}
              Regenerate Code
            </p>
          </button>
        </div>
      ) : (
        <p className="mb-4">
          You've not created a child account yet. To get started, click 'Create
          Account'.
        </p>
      )}

      <div className="gap-8 mb-8">
        {user?.children &&
          user?.children?.map((child, index) => (
            <ChildAccountItem
              key={index}
              child={child}
              onNavigateToClientPage={onNavigateToClientPage}
              allowEdit={true}
              onClickEditChildAccount={onClickEditChildAccount}
            />
          ))}
      </div>

      <button
        className="btn btn-primary bg-primary-400 hover:bg-primary-500 mb-4 border-primary-400 hover:border-purple-300"
        onClick={() => setShowAddForm(!showAddForm)}
      >
        {showAddForm ? "Cancel" : "Add new user"}
      </button>

      <div
        className={`transition-all duration-300 ease-in-out overflow-hidden ${
          showAddForm ? "max-h-[1000px] opacity-100" : "max-h-0 opacity-0"
        }`}
      >
        <AddChildAccountForm
          onSubmit={handleAddAccount}
          onCancel={() => setShowAddForm(false)}
          onClickSubmitQuickbuildChildren={onClickSubmitQuickbuildChildren}
        />
      </div>

      {user?.children && user?.children?.length > 0 && (
        <div className="bg-white p-6 rounded-lg shadow-md mt-8">
          <h3 className="text-xl font-bold mb-2">Generate Passcode</h3>
          <p className="mb-4">
            {isTeacher
              ? "To allow your class to access Topic Heroes, you will need to generate a code for all children to use with their username when logging in."
              : isParent
              ? "To allow your child to access Topic Heroes, you will need to generate a code for them to use with their username when logging in."
              : "You need to generate a code for children to use when logging in."}
          </p>
          <div className="flex space-x-4">
            <button
              className="btn btn-success"
              onClick={onClickGenerateOnetimeCode}
            >
              Generate Code
            </button>
            <button
              className="btn btn-primary"
              onClick={() => onClickGoToAccounts(false)}
            >
              Manage Accounts
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChildAccountsTab;
