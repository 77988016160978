import React, { useState } from "react";
import { User, School, ShoppingBag } from "lucide-react";
import AccountTab from "./AccountTabs/AccountTab";
import SchoolTab from "./AccountTabs/SchoolTab";
import MyOrdersTab from "./AccountTabs/MyOrdersTab";

const AccountDetails = ({
  user,
  auth,
  requestUpdateUserDetails,
  getRequestHeaders,
  requestOrderHistoryDetails,
  onNavigateToClientPage,
  requestAddSchool,
  history,
}) => {
  const [activeTab, setActiveTab] = useState("account");

  return (
    <div className="max-w-[70%] mx-auto p-6 bg-gray-100 rounded-lg shadow-md my-12">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-4xl font-bold">My Account</h1>
        <div className="flex space-x-4">
          <button
            onClick={() => setActiveTab("account")}
            className={`flex items-center px-4 py-2 rounded-full border ${
              activeTab === "account"
                ? "bg-white border-purple-200 text-purple-200"
                : "bg-gray-300 text-gray-900"
            }`}
          >
            <User
              className={`w-8 h-8 mr-2 ${
                activeTab === "account" ? "text-purple-200" : "text-gray-900"
              }`}
            />
            My Account
          </button>
          <button
            onClick={() => setActiveTab("school")}
            className={`flex items-center px-4 py-2 rounded-full border ${
              activeTab === "school"
                ? "bg-white border-purple-200 text-purple-200"
                : "bg-gray-300 text-gray-900"
            }`}
          >
            <School
              className={`w-8 h-8 mr-2 ${
                activeTab === "school"
                  ? "text-purple-200 border-purple-200"
                  : "text-gray-900"
              }`}
            />
            My School
          </button>
          <button
            onClick={() => setActiveTab("orders")}
            className={`flex items-center px-4 py-2 rounded-full border ${
              activeTab === "orders"
                ? "bg-white border-gray-300 text-purple-200"
                : "bg-gray-300 text-gray-900"
            }`}
          >
            <ShoppingBag
              className={`w-8 h-8 mr-2 ${
                activeTab === "orders" ? "text-purple-200" : "text-gray-900"
              }`}
            />
            My Orders
          </button>
        </div>
      </div>

      <hr className="border-t border-gray-300 mb-8" />

      {activeTab === "account" && (
        <AccountTab
          user={user}
          auth={auth}
          history={history}
          requestUpdateUserDetails={requestUpdateUserDetails}
        />
      )}
      {activeTab === "school" && (
        <SchoolTab user={user} requestAddSchool={requestAddSchool} />
      )}
      {activeTab === "orders" && (
        <MyOrdersTab
          getRequestHeaders={getRequestHeaders}
          requestOrderHistoryDetails={requestOrderHistoryDetails}
          onNavigateToClientPage={onNavigateToClientPage}
        />
      )}
    </div>
  );
};

export default AccountDetails;
