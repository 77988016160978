import axios from "axios";

export const BASEURL = () => {
  //var baseURL = process.env.NODE_ENV === 'development' ? process.env.REACT_APP_TEST_API : process.env.REACT_APP_LIVE_API;
  const host = window.location.host;
  var baseURL = process.env.REACT_APP_LIVE_API;
  //var baseURL = (host === "localhost:44328") || (host === "localhost:3000") ? process.env.REACT_APP_TEST_API : process.env.REACT_APP_LIVE_API
  return baseURL;
};

export default axios.create({
  baseURL: BASEURL(),
  headers: {
    "Content-Type": "application/json",
  },
});
//timeout: 1000 * 30,
// validateStatus: (status) => {
//     return status >= 200 && status < 500
//   },

// headers: {
//     'Content-Type': 'application/vnd.api+json',
//     Accept: 'application/vnd.api+json',
//   },
