import React, { Component } from "react";
import Avatar from "react-avatar";

class ChildAccountItem extends Component {
  onClickSwitchChildAccount(_child) {
    const switchLink = `/login-child?u=${_child.username}`;
    this.props.onNavigateToClientPage(switchLink);
  }

  render() {
    const { child, allowEdit } = this.props;

    return (
      <div className="child-account-row mb-4">
        <div className="child-account-content">
          <div >
            {/* <i className="fa-light fa-user"></i> */}
            <Avatar
              name={`${child.first_name} ${child.last_name}`}
              round={true}
              size="44"
              color="purple"
            />
          </div>

          <div className="child-account-detail">
            <h3 className="child-account-detail__title">{`${child.first_name} ${child.last_name}`}</h3>
            <p className="child-account-detail__text">
              Username{" "}
              <span className="font-weight-bold">{`${child.username}`}</span>
            </p>
          </div>
        </div>

        <div className="child-account-actions">
          {/* {allowEdit ? (
                <button
                  className="btn btn--sml btn--secondary"
                  onClick={() => this.props.onClickEditChildAccount(child)}
                >
                  Edit account
                </button>
              ) : null} */}
          {/* <button
            className="btn btn--sml btn--success"
            onClick={() => this.onClickSwitchChildAccount(child)}
          >
            Login as {`${child.first_name}`}{" "}
          </button> */}
          <div className="flex justify-end space-x-4 mt-8">
            <button
              className="text-gray-800 text-[14px] hover:text-gray-900 transition-colors"
              onClick={() => onClickDeleteChildAccount(child)}
            >
              Delete
            </button>
            <button
              className="text-gray-800 text-[14px] hover:text-gray-900 transition-colors"
              onClick={() => this.props.onClickEditChildAccount(child)}
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ChildAccountItem;
