import React, { Component } from 'react';
import { Input } from 'reactstrap';

import SearchFilterOptionList from '../../../components/search-bar/SearchFilterOptionList';
import { RESOURCE_TYPE_GLOSSARY, RESOURCE_TYPE_WORKBOOK } from '../../../constants/string_constants';
import { rebuildSearchFilterCatalouge, toggleSearchFilterOptionChange, toggleSearchFilterOptionChangeByTitle } from '../../../utils/Utils';

class EditTemplate extends Component {

    constructor(props){
        super(props);

        this.state = {
            changes: null,
            tagsText: "",
            filterChanges: null,
            coverImage: null,
            currentResourceFile: null
        };

        this.onChangeTitle = this.onChangeTitle.bind(this);
        this.onChangeSubject = this.onChangeSubject.bind(this);
        this.onChangeSponsor = this.onChangeSponsor.bind(this);
        this.onChangeSubtitle = this.onChangeSubtitle.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeCode = this.onChangeCode.bind(this);
        this.onChangeTags = this.onChangeTags.bind(this);
        this.onChangeOutOfStock = this.onChangeOutOfStock.bind(this);
        this.onChangeVisible = this.onChangeVisible.bind(this);
        this.onChangeFetaured = this.onChangeFetaured.bind(this);

        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onRequestTemplateDetails = this.onRequestTemplateDetails.bind(this);


        this.onSubmitCoverImageForm = this.onSubmitCoverImageForm.bind(this);
        this.onSubmitResourceUploadForm = this.onSubmitResourceUploadForm.bind(this);
        //this.onClickSetCoverImage = this.onClickSetCoverImage.bind(this);
        //this.onClickSetThumbnailImage = this.onClickSetThumbnailImage.bind(this);
        //this.onClickDeleteTemplateImage = this.onClickDeleteTemplateImage.bind(this);

        //this.rebuildSearchFilterCatalouge = this.rebuildSearchFilterCatalouge.bind(this);
        this.onSelectedOptionChange = this.onSelectedOptionChange.bind(this);
        this.onSelectedOptionDropdownChange = this.onSelectedOptionDropdownChange.bind(this);
        this.onSaveOptions = this.onSaveOptions.bind(this);
         this.props.onHideFavorite(true);
    }

    componentDidMount(){
        this.props.requestAllSubjects();
        this.props.requestAllSponsors();
        setTimeout(this.onRequestTemplateDetails, 100);
    }

    static getDerivedStateFromProps(props, current_state) {

        var templateChanged = false;
        var filtersChanged = false;

        if (current_state.changes !== props.templateDetail) {
            templateChanged = true;
        }

        if (current_state.filterChanges !== props.searchFilterCatalouge) {
            filtersChanged = true;
        }

        if(templateChanged === true
        || filtersChanged === true) {

            var tagsText = "";
        
            if(props.templateDetail !== null) {
                if(props.templateDetail.tags !== undefined && props.templateDetail.tags !== null) {
                    for(var i = 0; i < props.templateDetail.tags.length; i++) {
                        if(i > 0) {
                            tagsText += ","
                        }
                        tagsText += props.templateDetail.tags[i].tag.toString();
                    }
                }
            }
            

            return {
                changes: props.templateDetail,
                tagsText: tagsText,
                filterChanges: rebuildSearchFilterCatalouge(props.templateDetail, props.searchFilterCatalouge)
            };
        }

        return null
    }

    // shouldComponentUpdate(nextProps, nextState) {

    //     var templateChanged = false;
    //     if (nextProps.templateDetail !== this.props.templateDetail) {
    //         templateChanged = true;
    //     } 
    //     else if (nextProps.templateDetail.id !== this.props.templateDetail.id) {
    //         templateChanged = true;
    //     } 
    //     else if (nextProps.templateDetail.options !== this.props.templateDetail.options) {
    //         templateChanged = true;
    //     } 
    //     else if (nextProps.templateDetail.options.length !== this.props.templateDetail.options.length) {
    //         templateChanged = true;
    //     } 

    //     if(templateChanged) {
    //         this.rebuildSearchFilterCatalouge(nextProps.templateDetail);
    //     }


    //     return templateChanged;
    // }

    onRequestTemplateDetails() {
        const templateId = parseInt(this.props.match.params.id, 10);
        this.props.requestTemplateDetails(templateId);
        this.setState({
            filterChanges: rebuildSearchFilterCatalouge(this.props.templateDetail, this.props.searchFilterCatalouge)
        });
    }

    

    onChangeTitle(_e){
        var templateDetails = this.state.changes;
        templateDetails.title =  _e.target.value;
        this.saveChanges(templateDetails);
    }

    onChangeSubtitle(_e){
        var templateDetails = this.state.changes;
        templateDetails.subtitle =  _e.target.value;
        this.saveChanges(templateDetails);
    }

    onChangeDescription(_e) {
        var templateDetails = this.state.changes;
        templateDetails.description =  _e.target.value;
        this.saveChanges(templateDetails);
    }

    onChangeCode(_e) {
        var templateDetails = this.state.changes;
        templateDetails.code =  _e.target.value;
        this.saveChanges(templateDetails);
    }

    onChangeTags(_e) {
        this.setState({
            tagsText: _e.target.value
        });
    }

    onChangeOutOfStock() {
        var templateDetails = this.state.changes;
        templateDetails.out_of_stock = !templateDetails.out_of_stock;
        this.saveChanges(templateDetails);
    }

    onChangeVisible() {
        var templateDetails = this.state.changes;
        templateDetails.visible =  !templateDetails.visible;
        this.saveChanges(templateDetails);
    }

    onChangeFetaured() {
        var templateDetails = this.state.changes;
        templateDetails.featured =  !templateDetails.featured;
        this.saveChanges(templateDetails);
    }

    onChangeSubject(_e) {
        var templateDetails = this.state.changes;
        templateDetails.book_subject_id =  _e.target.value;
        this.saveChanges(templateDetails);
    }

    onChangeSponsor(_e) {
        var templateDetails = this.state.changes;
        templateDetails.sponsor_id =  _e.target.value;
        this.saveChanges(templateDetails);
    }

    saveChanges(_templateDetail) {
        this.setState({
           changes: _templateDetail 
        });
    }

    

    onSubmitForm(_e) {
        _e.preventDefault();

        var template = this.state.changes;
        
        if(this.state.tagsText) {
            var tags = this.state.tagsText.split(",");
            console.log("tags: ", tags);
            template.tags = tags;
        }
        console.log("Saveing template: ", template);
        this.props.requestUpdateTemplateDetails(template, template.visible, template.featured);
    }



    onChangeCoverImage = (e) => {
        this.setState({
          coverImage: e.target.files[0]
        })
    };

    onChangeResourceFile = (e) => {
        this.setState({
          currentResourceFile: e.target.files[0]
        })
    };

    onSubmitCoverImageForm(_e) {
        _e.preventDefault();
        if(this.state.coverImage === null) {
            return;
        }
        let form_data = new FormData();
        form_data.append('image', this.state.coverImage, this.state.coverImage.name);
        const changes = this.state.changes;
        this.props.requestUpdateTemplateImage(changes.id, form_data);
    }

    onSubmitResourceUploadForm(_e) {
        _e.preventDefault();

        if(this.state.currentResourceFile === null) {
            return;
        }
        let form_data = new FormData();
        form_data.append('file', this.state.currentResourceFile, this.state.currentResourceFile.name);
        const changes = this.state.changes;
        this.props.requestUploadTemplateResource(changes.id, form_data);
    }

    onClickSetCoverImage(_imageId) {
        const template = this.state.changes;
        this.props.requestSetCoverImage(template.id, _imageId);
    }

    

    onClickSetThumbnailImage(_imageId) {
        const template = this.state.changes;
        this.props.requestSetThumbnailImage(template.id, _imageId);
    }

    onClickDeleteTemplateImage(_imageId) {
        var z = confirm("Confirm you want to delete this image? This can not be undone!");
        if (z !== true) {
            return;
        }
        const template = this.state.changes;
        this.props.requestDeleteTemplateImage(template.id, _imageId);
    }

    onClickSetResourceType(_resourceId, _resourceType) {
        this.props.requestSetResourceType(_resourceId, _resourceType);
    }

    onClickDeleteTemplateResource(_resourceId) {
        var z = confirm("Confirm you want to delete this Resource? This can not be undone!");
        if (z !== true) {
            return;
        }
        const template = this.state.changes;
        this.props.requestDeleteTemplateResource(template.id, _resourceId);
    }

    onHandleDownloadResource(_resource) {
        window.open(_resource.resource_url, '_blank');
    }

    onSelectedOptionChange(_optionId) {

        console.log("onSelectedOptionChange: ", _optionId);
  
        var { filterChanges } = this.state;
        toggleSearchFilterOptionChange(filterChanges, _optionId);
        this.setState({
            filterChanges: filterChanges
        });
        //this.props.onUpdateSearchFilterCatalouge(filterChanges);
      }
  
      onSelectedOptionDropdownChange(_sfid, _e) {
  
        const selectedValue = _e.target.value;
        console.log("onSelectedOptionDropdownChange ", selectedValue, _sfid);
  
        var { filterChanges } = this.state;
        toggleSearchFilterOptionChangeByTitle(searchFilterCatalouge, selectedValue, _sfid);
        this.setState({
            filterChanges: filterChanges
        });
    }

    onSaveOptions() {
        var templateId = this.props.templateDetail.id;
        var options = this.state.filterChanges;
        this.props.requestAddRemoveTemplateSearchFilterOption(templateId, options);
    }

    render() {
        const { templateDetail, searchFilterCatalouge } = this.props;
        const { filterChanges, tagsText, changes } = this.state;
        if(templateDetail === null) {
            return <div>Loading.</div>
        }

        if(changes === null) {
            return <div>Loading...</div>
        }
        const isVisible = changes.visible;
        const isFeatured = changes.featured;

        
           
        

        return (
            
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>

                    <div className='th-panel th-br bgc-white pall-l spc-b-l'>

                        <h2 className="hw-font spc-b-s t-purple">Template Details</h2>

                        <p className="spc-b-l">Update the details for the <b>{ changes.title }</b> template.</p>
                        
                        <form className="th-form-container"  onSubmit= { this.onSubmitForm }>

                            <div className="th-form-inner">

                                <div className='th-field-wrapper'>

                                    <label htmlFor="title">Title</label>

                                    <input name="title" placeholder="title" type="text" onChange={ this.onChangeTitle } value={ changes.title } maxLength="80"/>

                                </div>

                                <div className='th-field-wrapper'>

                                    <label htmlFor="category">Category</label>

                                    <select 
                                        name="category" 
                                        placeholder="category" 
                                        onChange={ this.onChangeSubject } 
                                        value={ changes.book_subject_id } 
                                    >
                                        <option value="0"></option>
                                        {
                                            (this.props.subjects !== null) ? (
                                                this.props.subjects.map((e, i) => {
                                                    return (
                                                        <option key={i} value={e.id}>{e.title}</option>
                                                    )
                                                })
                                            ) : null
                                        }
                                    </select>

                                </div>

                                <div className='th-field-wrapper'>

                                    <label htmlFor="subtitle">Subtitle</label>

                                    <input name="subtitle" placeholder="subtitle" type="text" onChange={ this.onChangeSubtitle } value={ changes.subtitle } maxLength="256"/>

                                </div>

                                <div className="th-field-wrapper">

                                    <label htmlFor="desc">Template Content</label>

                                    <textarea rows='10' name="desc" placeholder="Start typing..." onChange={ this.onChangeDescription } value={ changes.description } />

                                </div>

                                <div className="th-field-wrapper">

                                    <label htmlFor="code">Code</label>
                                    
                                    <input autoComplete="on" name="code" placeholder="Code" type="text" onChange={ this.onChangeCode } value={ changes.code } maxLength="128"/>

                                </div>

                                <div className="th-field-wrapper">

                                    <label htmlFor="tags">Tags</label>
                                    
                                    <input 
                                        autoComplete="on" 
                                        name="tags" 
                                        placeholder="Tags comma seperated list" 
                                        type="text" 
                                        onChange={ this.onChangeTags } 
                                        value={ tagsText } 
                                        maxLength="256" />

                                </div>

                                <div className='th-field-wrapper'>

                                    <label htmlFor="sponsor">Sponsor</label>

                                    <select 
                                        name="sponsor" 
                                        placeholder="sponsor" 
                                        onChange={ this.onChangeSponsor } 
                                        value={ changes.sponsor_id } 
                                    >
                                        <option value="0"></option>
                                        {
                                            (this.props.sponsors !== null) ? (
                                                this.props.sponsors.map((e, i) => {
                                                    return (
                                                        <option key={i} value={e.id}>{e.title}</option>
                                                    )
                                                })
                                            ) : null
                                        }
                                    </select>

                                </div>

                                <div className='th-form-inner-row'>

                                    <div className="th-form-inline-label" > 
                                    
                                        <input onChange={ this.onChangeOutOfStock } type="checkbox" checked={ changes.out_of_stock } />
                                            
                                        <label check>Out of Stock</label>
                                    
                                    </div>

                                    <div className="th-form-inline-label" > 
                                    
                                        <input onChange={ this.onChangeVisible } type="checkbox" checked={ isVisible } />
                                    
                                        <label check>Visible</label>
                                    
                                    </div>

                                    <div className="th-form-inline-label" check> 
                                    
                                        <input onChange={ this.onChangeFetaured } type="checkbox" checked={ isFeatured } />
                                    
                                        <label check>Featured</label>
                                    
                                    </div>

                                </div>

                                <div className="d-flex flex-row flex-ai-l">
                                    <button className='th-admin-btn'>Save changes</button>
                                </div>
                            
                            </div>

                        </form>

                    </div>

                    <div className='th-panel th-br bgc-white pall-l spc-b-l'>
                        <h2 className="hw-font spc-b-s t-purple">Template Search Options</h2>

                        <p className="spc-b-l">Update search critiria template.</p>
                        
                        <div className='th-form-container'>
                            <div className='th-form-inner'>
                            {
                                (filterChanges !== null && filterChanges !== undefined) ? (
                                    <SearchFilterOptionList 
                                        assignMode = { true }
                                        searchFilterCatalouge = { filterChanges }
                                        onSelectedOptionChange = { this.onSelectedOptionChange }
                                        onSelectedOptionDropdownChange = { this.onSelectedOptionDropdownChange } 
                                        />
                                ) : null
                            }

                                <div className="d-flex flex-row flex-ai-l">
                                    <button className='th-admin-btn' onClick={ this.onSaveOptions }>Save Options</button>
                                </div>
                            </div>
                        </div>
                        
                        

                    </div>

                    <div className='th-panel th-br bgc-white pall-l spc-b-l'>

                        <h2 className="hw-font spc-b-s t-purple">Template Image Upload</h2>

                        <p className="">Upload image assets for the template</p>

                        <form onSubmit={ this.onSubmitCoverImageForm }>

                            <div className='spc-b-l'>

                                <label htmlFor="tags">Select Files</label>

                                <Input type="file" id="cover_img" accept="image/png, image/jpeg" onChange={ this.onChangeCoverImage } required />

                            </div>

                            <div className="d-flex flex-row flex-ai-l">

                                <button className='th-admin-btn'>Upload Image</button>

                            </div>

                        </form>

                    </div>

                    <div className='th-panel th-br bgc-white pall-l spc-b-l'>

                        <h2 className="hw-font spc-b-s t-purple">Template Image Assets</h2>

                        <p>Manage the image assets for the template</p>

                        <div className="d-grid col-3-2-1 th-gap-l">

                            {
                                (templateDetail.images !== null && templateDetail.images !== undefined) ? (
                                    templateDetail.images.map((e, i) => {

                                        const isCover = (e.image_url === templateDetail.cover_image_url);
                                        const isThumb = (e.image_url === templateDetail.thumbnail_url);

                                        return <div className='th-admin-image-tile' key={i}>

                                            <div className='th-admin-image-container th-br spc-b-r'>

                                                <img className='th-img-contain' src ={ e.image_url } alt={ e.alt_text } />

                                            </div>

                                            <div className="d-flex flex-col flex-ai-l th-gap-xs">

                                                {
                                                    (isCover === true) ? (
                                                        <span>Current Banner</span>
                                                    ) : (
                                                        <button className='th-admin-link' onClick={ this.onClickSetCoverImage.bind(this, e.id) }>
                                                            <i className="fa-light fa-image-landscape fa-fw"></i>Set as Banner
                                                        </button>
                                                    )
                                                }
                                                

                                                {
                                                    (isThumb === true) ? (
                                                        <span>Current Thumbnail</span>
                                                    ) : (
                                                        <button className='th-admin-link' onClick={ this.onClickSetThumbnailImage.bind(this, e.id) }>
                                                            <i className="fa-light fa-image fa-fw"></i>Set as Thumbnail
                                                        </button>
                                                    )
                                                }
                                                
                                                <button className='th-admin-link' onClick={ this.onClickDeleteTemplateImage.bind(this, e.id) }>
                                                <i className="fa-light fa-trash-can fa-fw"></i>Delete
                                                </button>

                                            </div>
                                            
                                        </div>
                                    })
                                    
                                ) : null
                            }

                        </div>

                    </div>

                    <div className='th-panel th-br bgc-white pall-l spc-b-l'>

                        <h2 className="hw-font spc-b-s t-purple">Template Resource Upload</h2>

                        <p className="">Upload resource assets for the template</p>

                        <form onSubmit={ this.onSubmitResourceUploadForm }>

                            <div className='spc-b-l'>

                                <label htmlFor="tags">Select Files</label>

                                <Input type="file" id="resource_file" accept="application/pdf" onChange={ this.onChangeResourceFile } required />

                            </div>

                            <div className="d-flex flex-row flex-ai-l">

                                <button className='th-admin-btn'>Upload Resource</button>

                            </div>

                        </form>

                    </div>

                    <div className='th-panel th-br bgc-white pall-l spc-b-l'>

                        <h2 className="hw-font spc-b-s t-purple">Template Resource Assets</h2>

                        <p>Manage the resources assets for the template</p>

                        <div className="d-grid col-3-2-1 th-gap-l">

                            {
                                (templateDetail.resources !== null && templateDetail.resources !== undefined) ? (
                                    templateDetail.resources.map((e, i) => {

                                        return <div className='th-admin-image-tile' key={i}>

                                            <div><button className='th-download-btn d-flex flex-ai-c' onClick={ this.onHandleDownloadResource.bind(this, e) }>{ e.title }</button></div>
                                            <div className='spc-b-l'></div>

                                            <div className="d-flex flex-col flex-ai-l th-gap-xs">
                                                
                                                {
                                                    (e.resource_type === RESOURCE_TYPE_WORKBOOK) ? (
                                                        <span>Workbook</span>
                                                    ) : (
                                                        <button 
                                                            className='th-admin-link' 
                                                            onClick={ this.onClickSetResourceType.bind(this, e.id, RESOURCE_TYPE_WORKBOOK) }>
                                                            <i className="fa-light fa-image-landscape fa-fw"></i>Set as Workbook
                                                        </button>
                                                    )
                                                }

                                                {
                                                    (e.resource_type === RESOURCE_TYPE_GLOSSARY) ? (
                                                        <span>Glossary</span>
                                                    ) : (
                                                        <button 
                                                            className='th-admin-link' 
                                                            onClick={ this.onClickSetResourceType.bind(this, e.id, RESOURCE_TYPE_GLOSSARY) }>
                                                            <i className="fa-light fa-image-landscape fa-fw"></i>Set as Glossary
                                                        </button>
                                                    )
                                                }

                                                <button 
                                                    className='th-admin-link' 
                                                    onClick={ this.onClickDeleteTemplateResource.bind(this, e.id) }>
                                                    <i className="fa-light fa-trash-can fa-fw"></i>Delete
                                                </button>

                                            </div>
                                            
                                        </div>
                                    })
                                    
                                ) : null
                            }

                        </div>

                    </div>



                </div>

            </div>
        );
    }
}

export default EditTemplate;




