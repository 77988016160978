import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
    Table,
  } from 'reactstrap';
import { selectSearchFilter, fetchSearchFilters } from '../../store/actions';

import FilterItem from './FilterItem';

class FilterList extends Component {
    
    constructor(props) {
        super(props);

        this.onItemEditClick = this.onItemEditClick.bind(this);
        this.onItemOptionsClick = this.onItemOptionsClick.bind(this);
    }

    componentDidMount() {
        this.props.fetchSearchFilters();
    }


    onItemEditClick(_filter) {
        //console.log("item filter click: ", _filter);
        this.props.selectSearchFilter(_filter);
        this.props.onUpdateSearchFilter(_filter);

        const editLink = `/filters/edit/${_filter.id}`;
        this.props.history.push(editLink);
    }

    onItemOptionsClick(_filter) {
        //this.props.onUpdateSearchFilterOption(_filter);

        this.props.onUpdateSearchFilter(_filter);
        const optionsLink = `/filters/edit/${_filter.id}/options`;
        this.props.history.push(optionsLink);
    }

    render() {

        const renderedItems = this.props.filters.map((filter, i) => {

            return (

                <FilterItem key={i} index= { i } filter = { filter } onItemEditClick = { this.onItemEditClick } onItemOptionsClick = { this.onItemOptionsClick } />

            );
        });

        
        return (

            <div className='th-table-container spc-b-l'>

                <table className="th-table">

                    <thead>

                        <tr>

                            <th>ID</th>

                            <th>Title</th>

                            <th>Options</th>

                            <th className='th-table-action-col'>Edit</th>

                            <th className='th-table-action-col'>Options</th>

                        </tr>

                    </thead>

                    <tbody>

                        { renderedItems }

                    </tbody>

                </table>

            </div>

        );
    }
}

const mapStateToProps = (state) => {
    
    return {

        filters: state.filters
        
    };
};

export default connect(
    mapStateToProps, 
    { selectSearchFilter, fetchSearchFilters }
)(FilterList);