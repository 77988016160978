
import React, { Component } from 'react';

class SyncTemplates extends Component {

    constructor(props){
        super(props);

        this.state = {
            title: ""
        };
        this.onFormSubmit = this.onFormSubmit.bind(this);
         this.props.onHideFavorite(true);
    }

    componentDidMount() {
        
    }

    onChangeTitle(_e){
        this.setState({
            title: _e.target.value
        });
    }

    onFormSubmit(_e) {
        _e.preventDefault();
        this.props.requestSyncTemplates();
    }

    render() {

        const { title } = this.state;

        return (
            <div className='content-container bgc-grade-snowfall'>
                <div className='content-mw pall-l'>
                    <form onSubmit= { this.onFormSubmit } className="th-panel bgc-white pall-l th-br" >
                        <div className="spc-b-l">
                            <h2 className="hw-font spc-b-s t-purple">Sync Templates</h2>
                            <p>
                                The 'Sync' button below will sync all templates in Printess with the Topic Heroes portal.
                                Any new templates will de added and any existing templates will be updated.
                            </p>
                        </div>
                        <div className="form-buttons">
                            <button className="th-admin-btn" type="submit"> Sync </button>
                        </div>
                    </form>
                </div>
                
            </div>
        );
    }
}

export default SyncTemplates;




