import astronaught from "../../../assets/img/graphics/astronaught.svg";
import moon from "../../../assets/img/graphics/moon.svg";
import space_bg from "../../../assets/img/backgrounds/space-bg-1920x1080.jpg";
import deliveryIcon from "../../../assets/img/icons/delivery-icon.svg";
import sponsorshipIcon from "../../../assets/img/icons/sponsor-icon.svg";
import publicityIcon from "../../../assets/img/icons/publicity-icon.svg";
import book from "../../../assets/img/graphics/book.png";
import joy from "../../../assets/img/graphics/joy.png";
import inspire from "../../../assets/img/graphics/inspire.png";
import thGroupImage from "../../../assets/img/graphics/topic-heroes-group.png";
import pattern from "../../../assets/img/backgrounds/th-pattern.svg";
import { NavLink as RRNavLink, Link } from "react-router-dom";

const LoggedOutView = ({ isBtnHover, onHoverEffect }) => {
  return (
    <div>
      <div className="hero-banner-block hero-banner-block--fh">
        <img className="hero-banner-block__image" src={space_bg} />

        <img className="hero-banner-block__spaceman" src={astronaught} />

        <div
          className={`hero-banner-block__moon ${
            isBtnHover ? "hero-banner-block__moon--focus" : ""
          }`}
        >
          <img src={moon} />
        </div>

        <div
          className="hero-banner-content"
          onMouseEnter={onHoverEffect}
          onMouseLeave={onHoverEffect}
        >
          <h1 className="hero-banner-content__title">We're on a mission</h1>
          <p className="hero-banner-content__text">
            Join our national campaign to make Topic Heroes<sup>®</sup>{" "}
            available FREE to every Primary School aged child in the UK. We're
            calling for business, teachers, parents and delivery partners to get
            involved in helping make this a reality.
          </p>
          <div className="hero-banner-content__buttons">
            <Link className="btn btn--primary-reverse" to="/register">
              Register
            </Link>
            <p>OR</p>
            <Link className="btn btn--primary-reverse" to="/Login">
              Sign in
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoggedOutView;
