import React from "react";
import TemplateItem from "../../../components/TemplateItem";

const FavoritesTab = ({
  templateCatalouge,
  isLoggedIn,
  onNavigateToClientPage,
  requestTemplateDetails,
  requestChangeFavouriteTemplate,
}) => {
  const favorites = templateCatalouge.filter((template) => template.favourite);

  return (
    <div className="section-block bg-transparent">
      <h2 className="text-2xl font-bold mb-4">My favourites</h2>
      <p className="mb-4">These are your favourite topics, all in one place!</p>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {favorites.map((template, index) => (
          <TemplateItem
            key={index}
            template={template}
            isLoggedIn={isLoggedIn}
            onNavigateToClientPage={onNavigateToClientPage}
            requestTemplateDetails={requestTemplateDetails}
            requestChangeFavouriteTemplate={requestChangeFavouriteTemplate}
          />
        ))}
      </div>
    </div>
  );
};

export default FavoritesTab;
