import React from "react";
import OrderHistoryList from "../../../components/order-history/OrderHistoryList";

const MyOrdersTab = ({ getRequestHeaders, requestOrderHistoryDetails, onNavigateToClientPage }) => {
  return (
    <div>
      <h1 className="text-2xl font-semibold mb-4">My Orders</h1>
    
      <OrderHistoryList
        admin={false}
        getRequestHeaders={getRequestHeaders}
        requestOrderHistoryDetails={requestOrderHistoryDetails}
        onNavigateToClientPage={onNavigateToClientPage}
      />
    </div>
  );
};

export default MyOrdersTab;
