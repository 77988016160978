
import React, { Component } from 'react';
import OrderHistoryList from '../../../components/order-history/OrderHistoryList';

class Orders extends Component {

    constructor(props) {
        super(props);
         this.props.onHideFavorite(true);

    }

    render() {

        return (
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>

                    <div className='th-panel bgc-white pall-l th-br'>

                        <div className="spc-b-s">

                            <h2 className="hw-font spc-b-s t-purple">Order Management</h2>

                            <p>You can view order details by cliking on the order below. </p>

                        </div>

                        <div className="spc-b-l">

                            <OrderHistoryList 
                                admin = { true }
                                getRequestHeaders = { this.props.getRequestHeaders }
                                requestOrderHistoryDetails = { this.props.requestOrderHistoryDetails }
                                onNavigateToClientPage = { this.props.onNavigateToClientPage }
                                />
                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default Orders;