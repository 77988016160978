//import logo from './logo.svg';
//import './assets/css/App.css';

import React, { Component, createRef } from "react";
import { Router, Route, Switch, Redirect, withRouter } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import { Alert } from "reactstrap";

import { defaultAddress } from "./constants/data";

import axios from "./apis/thresource";

import Layout from "./layout/Layout";
import Home from "./pages/Home/Home";
import Search from "./pages/Home/Search";
// import Dev from './pages/Dev';
// import FAQ from './pages/FAQ';
// import About from './pages/About';
// import Help from './pages/Help';

import PrincessPage from "./pages/PrintessPage";

import Login from "./pages/Login";
import LoginChild from "./pages/LoginChild";
import LoginExpired from "./pages/LoginExpired";
import LoginRedirect from "./pages/LoginRedirect";
import MemberArea from "./pages/MemberArea";

import Templates from "./pages/Admin/ManageTemplates/Templates";
import EditTemplate from "./pages/Admin/ManageTemplates/EditTemplate";
import SyncTemplates from "./pages/Admin/ManageTemplates/SyncTemplates";

import Filters from "./pages/Admin/ManageFilters/Filters";
import FilterOptions from "./pages/Admin/ManageFilters/FilterOptions";
import EditFilter from "./pages/Admin/ManageFilters/EditFilter";
import EditFilterOption from "./pages/Admin/ManageFilters/EditFilterOption";

import Register from "./pages/Registration/Register";
import RegisterTeacherAccount from "./pages/Registration/RegisterTeacherAccount";
import RegisterParentAccount from "./pages/Registration/RegisterParentAccount";
import RegisterAccount from "./pages/Registration/RegisterAccount";
import CompleteRegistration from "./pages/Registration/CompleteRegistration";
import RegisterWelcome from "./pages/Registration/RegisterWelcome";

import AccountDetails from "./pages/User/AccountDetails";
import AddressBookEdit from "./pages/User/AddressBookEdit";
import ChildAccounts from "./pages/User/ChildAccounts";
import ChildAccountEdit from "./pages/User/ChildAccountEdit";
import SubmitAccountDetails from "./pages/User/SubmitAccountDetails";
import UpdatedAccountDetails from "./pages/User/UpdatedAccountDetails";

import Basket from "./pages/Basket/Basket";
import Topics from "./pages/Home/TopicCatalouge";
import TopicDetail from "./pages/Home/TopicDetail";
import OrderHistory from "./pages/Orders/OrderHistory";
import OrderHistoryDetails from "./pages/Orders/OrderHistoryDetails";
import AdminIndex from "./pages/Admin/AdminIndex";
import Welcome from "./pages/Dashboard/Welcome";
import PrintessPage from "./pages/PrintessPage";
import Sponsors from "./pages/Admin/ManageSponsors/Sponsors";
import EditSponsor from "./pages/Admin/ManageSponsors/EditSponsor";
import Schools from "./pages/Admin/ManageSchools/Schools";
import EditSchool from "./pages/Admin/ManageSchools/EditSchool";
import ForgotPassword from "./pages/ForgotPassword/ForgotPassword";
import Verify2FA from "./pages/ForgotPassword/Verify2FA";
import ResetPassword from "./pages/ForgotPassword/ResetPassword";
import ChangePassword from "./pages/ForgotPassword/ChangePassword";

import { ROLE_TYPE_ADMIN, ROLE_TYPE_CHILD } from "./constants/role_constants";

import {
  cleanURL,
  checkAquiredRole,
  scrollTo,
  getSiteCookie,
  setSiteCookie,
  getRequestHeadersFromAuth,
} from "./utils/Utils";
import Orders from "./pages/Admin/ManageOrders/Orders";
import ChildAccountSuccess from "./pages/User/ChildAccountSuccess";
import Privacy from "./pages/Privacy";
import Subjects from "./pages/Admin/ManageSubjects/Subjects";
import EditSubject from "./pages/Admin/ManageSubjects/EditSubject";
import CompleteLogin from "./pages/CompleteLogin";
import Checkout from "./pages/Checkout/Checkout";

const routes = [
  {
    path: "/search",
    component: Search,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/search/term/:term",
    component: Search,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },

  {
    path: "/topics",
    component: Topics,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/topics/:id/details",
    component: TopicDetail,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },

  {
    path: "/member-area",
    component: MemberArea,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/login",
    component: Login,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/login-expired",
    component: LoginExpired,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/login-redirect",
    component: LoginRedirect,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/login-child",
    component: LoginChild,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/register",
    component: Register,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/register/account",
    component: RegisterAccount,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/register/verify",
    component: CompleteRegistration,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/register/welcome",
    component: RegisterWelcome,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/register/teacher",
    component: RegisterTeacherAccount,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/register/parent",
    component: RegisterParentAccount,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/login/verify",
    component: CompleteLogin,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },

  {
    path: "/forgot-password",
    component: ForgotPassword,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/verify-2fa",
    component: Verify2FA,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/reset-password",
    component: ResetPassword,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },
  {
    path: "/change-password",
    component: ChangePassword,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: true,
  },

  {
    path: "/dashboard",
    component: Welcome,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },

  {
    path: "/user/details",
    component: AccountDetails,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/user/childaccounts",
    component: ChildAccounts,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/user/childaccounts/add",
    component: ChildAccountEdit,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/user/childaccounts/edit/:id",
    component: ChildAccountEdit,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/user/childaccounts/registersuccess",
    component: ChildAccountSuccess,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/user/submit-details",
    component: SubmitAccountDetails,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/user/updated-details",
    component: UpdatedAccountDetails,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },

  {
    path: "/user/addressbook/add",
    component: AddressBookEdit,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/user/addressbook/edit/:id",
    component: AddressBookEdit,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/basket",
    component: Basket,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/order-history",
    component: OrderHistory,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/order-history/:id/details",
    component: OrderHistoryDetails,
    requiresAuth: true,
    rolesRequired: [],
    isFullPage: false,
  },

  {
    path: "/editor",
    component: PrincessPage,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/editor/:id",
    component: PrincessPage,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },

  {
    path: "/admin/index",
    component: AdminIndex,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/admin/orders",
    component: Orders,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/templates",
    component: Templates,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/templates/edit/:id",
    component: EditTemplate,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/templates/sync",
    component: SyncTemplates,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },

  {
    path: "/sponsors",
    component: Sponsors,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/sponsors/add",
    component: EditSponsor,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/sponsors/edit/:id",
    component: EditSponsor,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },

  {
    path: "/schools",
    component: Schools,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/schools/add",
    component: EditSchool,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/schools/edit/:id",
    component: EditSchool,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },

  {
    path: "/categories",
    component: Subjects,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/categories/add",
    component: EditSubject,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/categories/edit/:id",
    component: EditSubject,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },

  {
    path: "/filters",
    component: Filters,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/filters/add",
    component: EditFilter,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/filters/edit/:id",
    component: EditFilter,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/filters/edit/:id/options",
    component: FilterOptions,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },
  {
    path: "/filters/edit/:id/options/add",
    component: EditFilterOption,
    requiresAuth: true,
    rolesRequired: [ROLE_TYPE_ADMIN],
    isFullPage: false,
  },

  {
    path: "/privacy",
    component: Privacy,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/",
    component: Home,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },
  {
    path: "/checkout",
    component: Checkout,
    requiresAuth: false,
    rolesRequired: [],
    isFullPage: false,
  },

  //<Route exact path='/register/account' component={RegisterAccount} />
];

//function App() {
class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      alertVisible: false,
      alerts: [],
      user: null,
      auth: null,
      onetimeCode: null,
      activeBookId: 0,
      allTemplates: [],
      subjects: [],
      templateCatalouge: [],
      templateCatalougeFiltered: [],
      templateDetail: null,
      searchFilterCatalouge: [],
      savedBooks: [],
      editorMode: false,
      address: defaultAddress,
      searchTerm: "",
      lastSearchTerm: "",
      lastSearchOptions: [],
      searchFilter: null,
      searchFilterOption: null,
      sponsors: [],
      sponsor: null,
      //schools: [],
      school: null,
      subject: null,
      childDetail: null,
      saveToken: "",
      orderHistoryDetail: null,
      isDisplayHeader: true,
      isDisplayCookieWarning: true,
      authToken: "",
      showFavoriteIcon: false,
      auth: null,
      voucherApplied: false,
    };

    this.onClickAcceptCookies = this.onClickAcceptCookies.bind(this);
    this.getRequestHeaders = this.getRequestHeaders.bind(this);
    this.onDismiss = this.onDismiss.bind(this);
    this.onSetAlert = this.onSetAlert.bind(this);
    this.onClearAlert = this.onClearAlert.bind(this);
    this.onUpdateSponsor = this.onUpdateSponsor.bind(this);
    this.onUpdateSchool = this.onUpdateSchool.bind(this);
    this.onUpdateSubject = this.onUpdateSubject.bind(this);
    this.onUpdateSearchFilter = this.onUpdateSearchFilter.bind(this);
    this.onUpdateSearchFilterOption =
      this.onUpdateSearchFilterOption.bind(this);
    this.onUpdateSearchFilterCatalouge =
      this.onUpdateSearchFilterCatalouge.bind(this);
    this.onUpdateAddress = this.onUpdateAddress.bind(this);
    this.onUpdateUser = this.onUpdateUser.bind(this);
    this.onUpdateAuthToken = this.onUpdateAuthToken.bind(this);
    this.onUpdateAuth = this.onUpdateAuth.bind(this);
    this.onUpdateTemplateDetails = this.onUpdateTemplateDetails.bind(this);
    this.onUpdateOrderHistoryDetails =
      this.onUpdateOrderHistoryDetails.bind(this);
    this.onUpdateSearchTerm = this.onUpdateSearchTerm.bind(this);
    this.onUpdateActiveSavedBook = this.onUpdateActiveSavedBook.bind(this);
    this.onUpdateChildDetail = this.onUpdateChildDetail.bind(this);
    this.onSubmitSearch = this.onSubmitSearch.bind(this);
    this.clearOnetimeCode = this.clearOnetimeCode.bind(this);

    this.onLogout = this.onLogout.bind(this);
    this.requestAllTemplates = this.requestAllTemplates.bind(this);
    this.requestAllSubjects = this.requestAllSubjects.bind(this);
    this.requestAllSponsors = this.requestAllSponsors.bind(this);
    this.requestCheckToken = this.requestCheckToken.bind(this);
    this.requestCheckTokenBypass = this.requestCheckTokenBypass.bind(this);
    this.requestLoginBypass = this.requestLoginBypass.bind(this);
    this.requestChildLogin = this.requestChildLogin.bind(this);
    this.requestSavedBooks = this.requestSavedBooks.bind(this);
    this.requestCreateSavedBook = this.requestCreateSavedBook.bind(this);
    this.requestDeleteSavedBook = this.requestDeleteSavedBook.bind(this);
    this.requestChangeSavedBookToken =
      this.requestChangeSavedBookToken.bind(this);
    this.requestDeleteSession = this.requestDeleteSession.bind(this);
    this.requestTemplateCatalouge = this.requestTemplateCatalouge.bind(this);
    this.requestTemplateSearch = this.requestTemplateSearch.bind(this);
    this.requestChangeFavouriteTemplate =
      this.requestChangeFavouriteTemplate.bind(this);
    this.onSelectStartWriting = this.onSelectStartWriting.bind(this);
    this.requestAddRemoveTemplateSearchFilterOption =
      this.requestAddRemoveTemplateSearchFilterOption.bind(this);
    this.requestTemplateDetails = this.requestTemplateDetails.bind(this);
    this.requestOrderHistoryDetails =
      this.requestOrderHistoryDetails.bind(this);
    this.requestRefreshOrderHistoryStatus =
      this.requestRefreshOrderHistoryStatus.bind(this);
    this.requestUpdateTemplateDetails =
      this.requestUpdateTemplateDetails.bind(this);
    this.onToggleEditorMode = this.onToggleEditorMode.bind(this);
    this.onUpdateSaveToken = this.onUpdateSaveToken.bind(this);
    this.requestUpdateUserDetails = this.requestUpdateUserDetails.bind(this);
    this.requestOneTimeCode = this.requestOneTimeCode.bind(this);
    this.requestUpdateUserWelcomeSetting =
      this.requestUpdateUserWelcomeSetting.bind(this);
    this.requestUpdateAddress = this.requestUpdateAddress.bind(this);
    this.requestUpdateSponsor = this.requestUpdateSponsor.bind(this);
    this.requestUpdateSubject = this.requestUpdateSubject.bind(this);
    this.requestUpdateSchool = this.requestUpdateSchool.bind(this);
    this.requestGetUserDetails = this.requestGetUserDetails.bind(this);
    this.requestUpdateFilter = this.requestUpdateFilter.bind(this);
    this.requestUpdateFilterOption = this.requestUpdateFilterOption.bind(this);
    this.requestDeleteFilterOption = this.requestDeleteFilterOption.bind(this);
    this.requestSearchFilterCatalouge =
      this.requestSearchFilterCatalouge.bind(this);
    this.requestSyncTemplates = this.requestSyncTemplates.bind(this);
    this.requestUpdateTemplateImage =
      this.requestUpdateTemplateImage.bind(this);
    this.requestUpdateSponsorImage = this.requestUpdateSponsorImage.bind(this);
    this.requestUploadTemplateResource =
      this.requestUploadTemplateResource.bind(this);
    this.requestSetCoverImage = this.requestSetCoverImage.bind(this);
    this.requestSetThumbnailImage = this.requestSetThumbnailImage.bind(this);
    this.requestDeleteTemplateImage =
      this.requestDeleteTemplateImage.bind(this);
    this.requestSetResourceType = this.requestSetResourceType.bind(this);
    this.requestDeleteTemplateResource =
      this.requestDeleteTemplateResource.bind(this);
    this.requestUpdateChildDetail = this.requestUpdateChildDetail.bind(this);
    this.requestDeleteChild = this.requestDeleteChild.bind(this);
    this.requestAddChildrenBulk = this.requestAddChildrenBulk.bind(this);
    this.requestAddToBasket = this.requestAddToBasket.bind(this);
    this.requestRemoveFromBasket = this.requestRemoveFromBasket.bind(this);
    this.onNavigateToClientPage = this.onNavigateToClientPage.bind(this);
    this.requestCheckout = this.requestCheckout.bind(this);
    this.requestDeleteAddress = this.requestDeleteAddress.bind(this);
    this.onUpdateAuthToken = this.onUpdateAuthToken.bind(this);
    this.onHideHeader = this.onHideHeader.bind(this);
    this.onShowHeader = this.onShowHeader.bind(this);
    this.onNavigateToFavorite = this.onNavigateToFavorite.bind(this);
    this.onHideFavorite = this.onHideFavorite.bind(this);
    this.requestAddSchool = this.requestAddSchool.bind(this);
    this.onApplyVoucher = this.onApplyVoucher.bind(this);
    this.onCheckoutClick = this.onCheckoutClick.bind(this);
    this.favRef = createRef();
  }

  componentDidMount() {
    const { location } = this.props;
    const currentPath = location.pathname;

    //this.requestAllTemplates();
    this.requestSearchFilterCatalouge();
    this.requestTemplateCatalouge();
    this.requestCheckToken();

    var siteCookieValue = getSiteCookie().toString();
    //console.log("siteCookieValue: ", siteCookieValue);
    var isSiteCookieAccepted = siteCookieValue && siteCookieValue.length > 0;

    this.setState({
      isDisplayCookieWarning: !isSiteCookieAccepted,
      showFavoriteIcon: currentPath === "/",
    });
  }

  onDismiss() {
    this.setState({
      alertVisible: false,
    });
  }

  onNavigateToClientPage(_pageUrl, _keepErrors = false) {
    this.props.history.push(_pageUrl);

    //const currentPath = window.location.pathname; //cleanURL(window.location.pathname);

    var displayHeader = true;
    for (var i = 0; i < routes.length; i++) {
      if (_pageUrl === routes[i].path) {
        if (routes[i].isFullPage === true) {
          displayHeader = false;
        }
      }
    }

    const alertList = _keepErrors === true ? this.state.alerts : [];
    const alertVisible = _keepErrors === true ? this.state.alertVisible : false;

    this.setState(
      {
        isDisplayHeader: displayHeader,
        alerts: alertList,
        alertVisible: alertVisible,
      },
      () => {
        scrollTo(0);
      }
    );
  }

  onUpdateActiveSavedBook(_bookId) {
    this.setState(
      {
        activeBookId: _bookId,
      },
      () => {
        this.onUpdateSaveToken();
      }
    );
  }

  onSetAlert(_alertObj) {
    //var alerts = this.state.alerts;
    //alerts.push(_alertObj);
    this.setState({
      alertVisible: true,
      alerts: _alertObj,
    });
  }

  onClearAlert() {
    this.setState({
      alerts: [],
      alertVisible: false,
    });
  }

  onUpdateSearchFilterOption(_searchFilterOption) {
    this.setState({
      searchFilterOption: _searchFilterOption,
    });
  }

  onUpdateSearchFilter(_searchFilter) {
    this.setState({
      searchFilter: _searchFilter,
    });
  }

  onUpdateAddress(_address) {
    this.setState({
      address: _address,
    });
  }

  onUpdateUser(_user) {
    this.setState({
      user: _user,
    });
  }

  onUpdateAuth(_auth) {
    this.setState({
      auth: _auth,
    });
  }

  onUpdateChildDetail(_child) {
    this.setState({
      childDetail: _child,
    });
  }

  onClickAcceptCookies() {
    setSiteCookie();
    this.setState({
      isDisplayCookieWarning: false,
    });
  }

  onUpdateAuth(_auth, _loggedIn) {
    const previousLoggedInState = this.state.isLoggedIn;
    const previousAuth = this.state.auth;
    this.setState(
      {
        isLoggedIn: _loggedIn,
        auth: _auth,
      },
      () => {
        if (_loggedIn === true) {
          if (
            previousLoggedInState !== _loggedIn ||
            (previousAuth !== null && previousAuth.token !== _auth.token)
          ) {
            this.requestGetUserDetails(true);

            this.requestSavedBooks();

            //We have now logged in so update the topics list to show our favourites.
            this.requestTemplateCatalouge();
          }
        }
      }
    );
  }

  onUpdateTemplateDetails(_details) {
    this.setState({
      templateDetail: _details,
    });
  }

  onUpdateOrderHistoryDetails(_details) {
    this.setState({
      orderHistoryDetail: _details,
    });
  }

  onUpdateSchool(_school) {
    this.setState({
      school: _school,
    });
  }

  onUpdateSubject(_subject) {
    this.setState({
      subject: _subject,
    });
  }

  onUpdateSponsor(_sponsor) {
    console.log("app update sponsor: ", _sponsor);
    this.setState({
      sponsor: _sponsor,
    });
  }

  onUpdateSearchTerm(_term) {
    this.setState({
      searchTerm: _term,
    });
  }

  onUpdateSearchFilterCatalouge(_catalouge) {
    this.setState({
      searchFilterCatalouge: _catalouge,
    });
  }

  onUpdateAuthToken(_authToken) {
    this.setState({
      authToken: _authToken,
    });
  }

  onHideHeader() {
    this.setState({
      isDisplayHeader: false,
    });
  }

  onShowHeader() {
    this.setState({
      isDisplayHeader: true,
    });
  }

  onHideFavorite(isHide) {
    this.setState({
      showFavoriteIcon: !isHide,
    });
  }

  onSubmitSearch() {
    const searchTerm = this.state.searchTerm
      .toString()
      .replace(/\s{2,}/g, " ")
      .toLowerCase();

    const cleanSearchTerm = encodeURIComponent(searchTerm.trim()).replace(
      /%20/g,
      "+"
    );

    const sfc = this.state.searchFilterCatalouge;

    var lastSearchOptions = [];
    for (var i = 0; i < sfc.length; i++) {
      for (var j = 0; j < sfc[i].options.length; j++) {
        if (sfc[i].options[j].selected === true) {
          lastSearchOptions.push(sfc[i].options[j]);
        }
      }
    }

    this.requestTemplateSearch();

    this.setState(
      {
        lastSearchTerm: searchTerm,
        lastSearchOptions: lastSearchOptions,
      },
      () => {
        if (cleanSearchTerm.length > 0) {
          this.onNavigateToClientPage(`/search/term/${cleanSearchTerm}`);
        } else {
          this.onNavigateToClientPage(`/search`);
        }
      }
    );
  }

  requestLoginBypass() {
    // axios.post('/api/auth/loginbypass', null, this.getRequestHeaders())
    // .then(response => {
    //     if(response.data.success === true) {

    //     }
    //     else {
    //         console.log("Login pypass not authenticated.");
    //     }
    // })
    // .catch(error => {
    //     console.log("Login bypass error response: ", error);
    //     if (error.response && error.response.data) {
    //         this.props.onSetAlert(error.response.data.errors);
    //     }
    // });

    //After form submit.
    //console.log("requestLoginBypass*");
    this.requestCheckTokenBypass();
  }

  requestCheckToken() {
    //console.log("Login poll check started.");
    axios
      .get("/api/auth/loginpoll", this.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          this.onUpdateAuth(response.data.data, true);
        } else {
          //console.log("Login poll not authenticated.");
        }
      })
      .catch((error) => {
        //console.log("Login poll check server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestCheckTokenBypass() {
    //console.log("Login poll bypass check started.");
    axios
      .get("/api/auth/loginpollbypasspoll", this.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          this.onUpdateAuth(response.data.data, true);
        } else {
          //console.log("Login poll bypass not authenticated.");
        }
      })
      .catch((error) => {
        //console.log("Login poll check server error response: ", error);
        if (error.response && error.response.data) {
          this.props.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestChildLogin(_username, _password) {
    this.onClearAlert();
    const postData = {
      username: _username,
      password: _password,
    };
    axios
      .post("/api/auth/loginchild", JSON.stringify(postData))
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success) {
          this.onUpdateAuth(response.data.data, true);
          //this.onNavigateToClientPage("/");
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestChangeFavouriteTemplate(_templateId) {
    const postData = {
      template_id: _templateId,
    };

    axios
      .post(`/api/templates/favourite`, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          var templates = this.state.templateCatalouge;
          var templateDetail = this.state.templateDetail;
          for (var i = 0; i < templates.length; i++) {
            if (templates[i].id === _templateId) {
              templates[i].favourite = response.data.data;
            }

            //Here also update the template details if that is the one loaded.
            if (templateDetail !== null && templateDetail.id === _templateId) {
              templateDetail.favourite = response.data.data;
            }
          }
          this.setState({
            templateCatalouge: templates,
            templateDetail: templateDetail,
          });
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  onSelectStartWriting(_templateId) {
    this.onUpdateActiveSavedBook(_templateId);
    this.onToggleEditorMode();
  }

  async onNavigateToFavorite() {
    window.scrollTo({
      top: this.favRef.current.offsetTop - 100,
      behavior: "smooth",
    });
  }

  requestTemplateDetails(_templateId) {
    this.setState({
      templateDetail: null,
    });
    axios
      .get(`/api/templates/${_templateId}/details`, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.onUpdateTemplateDetails(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestOrderHistoryDetails(_orderHistoryId) {
    axios
      .get(
        `/api/orderhistory/${_orderHistoryId}/details`,
        this.getRequestHeaders()
      )
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.onUpdateOrderHistoryDetails(response.data.data);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestRefreshOrderHistoryStatus(_orderHistoryId) {
    axios
      .post(
        `/api/orderhistory/${_orderHistoryId}/refreshstatus`,
        null,
        this.getRequestHeaders()
      )
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.requestOrderHistoryDetails(_orderHistoryId);
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestSyncTemplates() {
    const postData = { sync_type: 3 }; //3 = all, 2 = new only

    axios
      .post("/api/templates/sync", postData, this.getRequestHeaders()) //https://dog.ceo/api/breeds/image/random
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.onNavigateToClientPage("/templates");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestGetUserDetails(
    _redirect = false,
    _clientRedirectPage = null,
    _keepErrors = false
  ) {
    axios
      .get("/api/account/details", this.getRequestHeaders()) //_auth.token
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success) {
          this.setState(
            {
              user: response.data.data,
            },
            () => {
              if (_redirect === true) {
                if (_clientRedirectPage !== null) {
                  this.onNavigateToClientPage(_clientRedirectPage, _keepErrors);
                } else if (response.data.data.display_welcome === true) {
                  this.onNavigateToClientPage("/dashboard", _keepErrors);
                } else {
                  this.onNavigateToClientPage("/", _keepErrors);
                }
              }
            }
          );
        } else {
          console.log("register failed");
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUpdateUserDetails(_user) {
    this.onClearAlert();
    const postData = {
      first_name: _user.first_name,
      last_name: _user.last_name,
      mobile: _user.mobile,
      email: _user.email,
      display_welcome: _user.display_welcome,
    };

    axios
      .post(`/api/account/update/details`, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.onUpdateUser(response.data.data);
          alert("Changes saved");
          if (response.data.data.email_mobile_changed)
            this.onNavigateToClientPage("/user/submit-details");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestOneTimeCode() {
    var expiryDate = new Date();
    expiryDate.setHours(expiryDate.getHours() + 2);

    const postData = {
      expiry: expiryDate,
      max_use: 0,
    };

    axios
      .post(`/api/organisercode/create`, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.setState({
            onetimeCode: response.data.data,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  clearOnetimeCode() {
    this.setState({
      onetimeCode: null,
    });
  }

  requestUpdateUserWelcomeSetting(_displayWelcome) {
    const postData = {
      display_welcome: _displayWelcome,
    };

    axios
      .post(
        `/api/account/update/welcomesetting`,
        postData,
        this.getRequestHeaders()
      )
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.requestGetUserDetails();
          alert("Changes saved");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestUpdateTemplateDetails(_details, _templateVisible, _templateFeatured) {
    const postData = {
      book_subject_id: _details.book_subject_id,
      sponsor_id: _details.sponsor_id,
      title: _details.title,
      subtitle: _details.subtitle,
      description: _details.description,
      code: _details.code,
      tags: _details.tags,
      out_of_stock: _details.out_of_stock,
      visible: _templateVisible,
      featured: _templateFeatured,
    };

    axios
      .post(
        `/api/templates/${_details.id}/edit`,
        postData,
        this.getRequestHeaders()
      )
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.onUpdateTemplateDetails(response.data.data);

          //we have changes a template so update our main template catalouge
          this.requestTemplateCatalouge();
          alert("Changes saved");
          this.onNavigateToClientPage("/templates");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestUpdateAddress(_address) {
    var addressId = _address.id;

    const postData = {
      addline_1: _address.addline_1,
      addline_2: _address.addline_2,
      city: _address.city,
      county: _address.county,
      country: _address.country,
      postcode: _address.postcode,
      primary: _address.primary,
    };

    const addressApiEndpoint =
      addressId > 0
        ? `/api/account/addressbook/${addressId}/update`
        : `/api/account/addressbook/add`;

    axios
      .post(addressApiEndpoint, postData, this.getRequestHeaders())
      .then((response) => {
        //console.log("account update address response: ", response.data);
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.onUpdateAddress(_address);
          //We have now added an address so pull down the lastest user details to view it.
          this.requestGetUserDetails();
          this.onNavigateToClientPage("/user/details");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestDeleteAddress(_address) {
    const addressId = _address.id;
    const addressApiEndpoint = `api/account/addressbook/remove/${addressId}`;

    axios
      .delete(addressApiEndpoint, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          //We have now added an address so pull down the lastest user details to view it.
          this.requestGetUserDetails();
          this.onNavigateToClientPage("/user/details");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestSearchFilterCatalouge() {
    axios
      .get("/api/searchfilter/catalouge", this.getRequestHeaders())
      .then((response) => {
        this.onUpdateSearchTerm("");
        this.onUpdateSearchFilterCatalouge(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestUpdateTemplateImage(_templateId, _formdata) {
    const url = `/api/images/template/${_templateId}/upload`;

    axios
      .post(url, _formdata, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestTemplateDetails(_templateId);
          this.onNavigateToClientPage(`/templates/edit/${_templateId}`);
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUpdateSponsorImage(_sponsorId, _formdata) {
    const url = `/api/images/sponsor/${_sponsorId}/upload`;

    axios
      .post(url, _formdata, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.onNavigateToClientPage(`/sponsors`);
          //this.onNavigateToClientPage(`/sponsor/edit/${_sponsorId}`);
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUploadTemplateResource(_templateId, _formdata) {
    const url = `/api/files/resource/${_templateId}/upload`;

    axios
      .post(url, _formdata, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestTemplateDetails(_templateId);
          this.onNavigateToClientPage(`/templates/edit/${_templateId}`);
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestDeleteTemplateImage(_templateId, _templateImageId) {
    const url = `/api/images/template/${_templateId}/remove/${_templateImageId}`;

    axios
      .delete(url, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestTemplateDetails(_templateId);
          this.onNavigateToClientPage(`/templates/edit/${_templateId}`);
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestDeleteTemplateResource(_templateId, _templateResourceId) {
    const url = `/api/files/resource/${_templateId}/remove/${_templateResourceId}`;

    axios
      .delete(url, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestTemplateDetails(_templateId);
          this.onNavigateToClientPage(`/templates/edit/${_templateId}`);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestSetCoverImage(_templateId, _templateImageId) {
    const url = `/api/images/template/${_templateId}/applycover/${_templateImageId}`;

    axios
      .post(url, null, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestTemplateDetails(_templateId);
          this.onNavigateToClientPage(`/templates/edit/${_templateId}`);
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestSetThumbnailImage(_templateId, _templateImageId) {
    const url = `/api/images/template/${_templateId}/applythumbnail/${_templateImageId}`;

    axios
      .post(url, null, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestTemplateDetails(_templateId);
          this.onNavigateToClientPage(`/templates/edit/${_templateId}`);
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestSetResourceType(_resourceId, _resourceType) {
    const url = `/api/files/resource/${_resourceId}/settype`;
    const postData = {
      resource_type: _resourceType,
    };
    axios
      .post(url, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestTemplateDetails(_templateId);
          this.onNavigateToClientPage(`/templates/edit/${_templateId}`);
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUpdateSponsor(_sponsor) {
    const postData = _sponsor;

    const url =
      _sponsor.id.length > 0
        ? `/api/sponsors/${_sponsor.id}/edit`
        : "/api/sponsors/create";

    axios
      .post(url, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.onNavigateToClientPage("/sponsors");
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUpdateSubject(_subject) {
    const postData = _subject;

    const url =
      _subject.id > 0
        ? `/api/subjects/${_subject.id}/edit`
        : "/api/subjects/create";

    axios
      .post(url, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.onNavigateToClientPage("/categories");
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUpdateSchool(_school) {
    const postData = _school;

    const url =
      _school.id > 0
        ? `/api/schools/${_school.id}/edit`
        : "/api/schools/create";

    axios
      .post(url, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.onNavigateToClientPage("/schools");
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUpdateFilter(_filter) {
    const postData = _filter;

    const url =
      _filter.id > 0
        ? `/api/searchfilter/${_filter.id}/edit`
        : "/api/searchfilter/create";

    axios
      .post(url, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestSearchFilterCatalouge();
          this.onNavigateToClientPage("/filters");
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestUpdateFilterOption(_filterOption) {
    const { searchFilter } = this.state;

    const postData = {
      title: _filterOption.title,
    };
    const url =
      _filterOption.id > 0
        ? `/api/searchfilter/${searchFilter.id}/options/${_filterOption.id}/edit`
        : `/api/searchfilter/${searchFilter.id}/options/create`;

    axios
      .post(url, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestSearchFilterCatalouge();
          this.onNavigateToClientPage(
            `/filters/edit/${searchFilter.id}/options`
          );
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  requestDeleteFilterOption(_filterOption) {
    const { searchFilter } = this.state;

    const url = `/api/searchfilter/${searchFilter.id}/options/${_filterOption.id}/remove`;

    axios
      .delete(url, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        if (response.data.success === true) {
          this.requestSearchFilterCatalouge();
          this.onNavigateToClientPage(
            `/filters/edit/${searchFilter.id}/options`
          );
        }
      })
      .catch((error) => {
        console.log("server error response: ", error);
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  onLogout() {
    //request to server and clear cookies

    axios
      .get("/api/auth/logout", this.getRequestHeaders())
      .then((response) => {
        console.log("logout: ", response.data.success);
        this.setState({
          isLoggedIn: false,
          auth: null,
          authToken: null,
        });
        this.onNavigateToClientPage("/");
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestAllTemplates() {
    axios
      .get("/api/templates/list", this.getRequestHeaders())
      .then((response) => {
        this.setState({
          allTemplates: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestAllSubjects() {
    axios
      .get("/api/subjects/list", this.getRequestHeaders())
      .then((response) => {
        this.setState({
          subjects: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestAllSponsors() {
    axios
      .get("/api/sponsors/list", this.getRequestHeaders())
      .then((response) => {
        this.setState({
          sponsors: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestSavedBooks() {
    axios
      .get("/api/savedbooks/list", this.getRequestHeaders())
      .then((response) => {
        this.setState({
          savedBooks: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestCreateSavedBook() {
    if (this.state.templateDetail === null) {
      return;
    }
    const postData = {
      template_id: this.state.templateDetail.id,
    };

    axios
      .post("/api/savedbooks/create", postData, this.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          this.requestSavedBooks();
          this.onUpdateActiveSavedBook(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestDeleteSavedBook(_bookId) {
    axios
      .delete(`/api/savedbooks/remove/${_bookId}`, this.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          this.onUpdateActiveSavedBook(0);
          this.requestSavedBooks();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestChangeSavedBookToken(_saveToken, _thumbnailUrl) {
    const postData = {
      ext_ref: _saveToken,
      thumbnail_url: _thumbnailUrl,
    };
    const bookId = this.state.activeBookId;
    axios
      .put(
        `/api/savedbooks/${bookId}/changetoken`,
        postData,
        this.getRequestHeaders()
      )
      .then((response) => {
        if (response.data.success === true) {
          this.onUpdateActiveSavedBook(0);
          this.requestSavedBooks();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestAddToBasket(_saveToken) {
    this.setState(
      {
        editorMode: false,
      },
      () => {
        const isChild = checkAquiredRole([ROLE_TYPE_CHILD], this.state.auth);

        const bookId = this.state.activeBookId;
        const saveToken = _saveToken;

        const postData = {
          book_id: bookId,
          ext_ref: saveToken,
          quantity: 1,
        };
        axios
          .post(`/api/basket/additem`, postData, this.getRequestHeaders())
          .then((response) => {
            if (response.data.success === true) {
              this.requestSavedBooks();
              this.onUpdateActiveSavedBook(0);

              if (isChild) {
                this.onNavigateToClientPage("/");
              } else {
                this.onNavigateToClientPage("/basket");
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    );
  }

  requestRemoveFromBasket(_itemId) {
    const postData = {
      id: _itemId,
    };
    axios
      .post(`/api/basket/removeitem`, postData, this.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          //Refresh basket
          this.requestSavedBooks(); //as we have removed ann item from the basket its now back in our projects
          this.onNavigateToClientPage("/basket");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestCheckout() {
    axios
      .post(`/api/basket/checkout`, null, this.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          this.onNavigateToClientPage("/order-history");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestDeleteSession() {
    axios
      .delete(`/api/basket/clear`, this.getRequestHeaders())
      .then((response) => {
        console.log("remove session response: ", response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestDeleteChild(_child) {
    var z = confirm(
      "Confirm you want to delete this child account? Warning this can not be undone!"
    );
    if (z !== true) {
      return;
    }

    const childAccountApiEndpoint = `/api/account/remove/child/${_child.username}`;

    axios
      .delete(childAccountApiEndpoint, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.requestGetUserDetails();
          this.onNavigateToClientPage("/user/childaccounts");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestUpdateChildDetail(_child) {
    var childId = _child.id;

    const postData = {
      id: _child.id,
      first_name: _child.first_name,
      last_name: _child.last_name,
      password: _child.password,
      username: _child.username,
      old_passowrd: _child.old_passowrd,
    };

    const isEditRecord = childId && childId.length > 0;

    const childAccountApiEndpoint =
      isEditRecord === true
        ? `/api/account/child/${childId}/update`
        : `/api/account/add/child`;

    axios
      .post(childAccountApiEndpoint, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          this.onUpdateChildDetail(_child);
          this.requestGetUserDetails();

          if (isEditRecord === true) {
            //We have now added an address so pull down the lastest user details to view it.
            this.onNavigateToClientPage("/user/childaccounts");
          } else {
            _child.username = response.data.data; //Server returns updated username.
            this.onUpdateChildDetail(_child);
            this.onNavigateToClientPage("/user/childaccounts/registersuccess");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestAddChildrenBulk(_children) {
    const postData = {
      children: _children,
    };

    axios
      .post(`/api/account/add/children`, postData, this.getRequestHeaders())
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }
        const keepErrors = !response.data.success;
        this.requestGetUserDetails(true, "/user/childaccounts", keepErrors);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestAddSchool(_school) {
    console.log("requestAddSchool");

    const postData = {
      school: _school,
    };

    axios
      .post("/api/schools/create", _school, this.getRequestHeaders())
      .then((response) => {
        console.log(response);
      });
  }

  requestTemplateCatalouge() {
    axios
      .get("/api/templates/catalouge", this.getRequestHeaders())
      .then((response) => {
        this.setState({
          templateCatalouge: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestTemplateSearch() {
    const postData = {
      term: this.state.searchTerm,
      search_filters: this.state.searchFilterCatalouge,
    };

    axios
      .post("/api/templates/search", postData, this.getRequestHeaders())
      .then((response) => {
        this.setState({
          templateCatalougeFiltered: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  requestAddRemoveTemplateSearchFilterOption(_templateId, _options) {
    const postData = {
      template_id: _templateId,
      options: _options,
    };

    axios
      .post(
        `/api/templates/applysearchfilteroptions`,
        postData,
        this.getRequestHeaders()
      )
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.onSetAlert(response.data.errors);
        }

        if (response.data.success === true) {
          //as the template option links have changed request the latest version.
          this.requestTemplateDetails(_templateId);
          alert("Changes Saved");
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          this.onSetAlert(error.response.data.errors);
        }
      });
  }

  getRequestHeaders() {
    //_token
    if (!this.state.isLoggedIn) {
      return null;
    }
    return getRequestHeadersFromAuth(this.state.auth);
  }

  onToggleEditorMode() {
    const nextValue = !this.state.editorMode;
    //console.log("onToggleEditorMode: ", nextValue);
    this.setState({
      editorMode: nextValue,
    });
  }

  onUpdateSaveToken() {
    var saveToken = "";
    if (this.state.activeBookId > 0) {
      for (var i = 0; i < this.state.savedBooks.length; i++) {
        if (this.state.savedBooks[i].id === this.state.activeBookId) {
          saveToken = this.state.savedBooks[i].ext_ref;
        }
      }
    }

    this.setState({
      saveToken: saveToken,
    });
  }

  onApplyVoucher() {
    console.log("onApplyVoucher");
    this.setState({
      voucherApplied: true,
    });
  }

  onCheckoutClick() {
    console.log("onCheckoutClick");
    this.onNavigateToClientPage("/checkout");
  }

  render() {
    const {
      isLoggedIn,
      user,
      address,
      allTemplates,
      subjects,
      templateCatalouge,
      templateCatalougeFiltered,
      alerts,
      alertVisible,
      templateDetail,
      editorMode,
      searchTerm,
      lastSearchTerm,
      lastSearchOptions,
      searchFilterCatalouge,
      searchFilter,
      searchFilterOption,
      sponsor,
      subject,
      school,
      sessions,
      savedBooks,
      sponsors,
      activeBookId,
      childDetail,
      saveToken,
      orderHistoryDetail,
      auth,
      isDisplayHeader,
      onetimeCode,
      isDisplayCookieWarning,
      authToken,
      showFavoriteIcon,
      voucherApplied,
    } = this.state;

    if (editorMode === true && saveToken) {
      return (
        <PrintessPage
          user={user}
          templateDetail={templateDetail}
          saveToken={saveToken}
          activeBookId={activeBookId}
          onToggleEditorMode={this.onToggleEditorMode}
          requestChangeSavedBookToken={this.requestChangeSavedBookToken}
          requestAddToBasket={this.requestAddToBasket}
          auth={auth}
        />
      );
    }

    const currentPath = window.location.pathname; //cleanURL(window.location.pathname);

    return (
      <Layout
        history={this.props.history}
        isLoggedIn={isLoggedIn}
        auth={auth}
        isDisplayHeader={isDisplayHeader}
        onNavigateToClientPage={this.onNavigateToClientPage}
        onLogout={this.onLogout}
        onSetAlert={this.onSetAlert}
        onClearAlert={this.onClearAlert}
        onToggleEditorMode={this.onToggleEditorMode}
        onUpdateSearchTerm={this.onUpdateSearchTerm}
        onUpdateSearchFilterCatalouge={this.onUpdateSearchFilterCatalouge}
        searchTerm={searchTerm}
        searchFilterCatalouge={searchFilterCatalouge}
        requestChangeFavouriteTemplate={this.requestChangeFavouriteTemplate}
        onSelectStartWriting={this.onSelectStartWriting}
        onSubmitSearch={this.onSubmitSearch}
        requestSearchFilterCatalouge={this.requestSearchFilterCatalouge}
        requestLoginBypass={this.requestLoginBypass}
        onHideHeader={this.onHideHeader}
        showFavoriteIcon={showFavoriteIcon}
        onNavigateToFavorite={this.onNavigateToFavorite}
      >
        <Toaster />
        {alertVisible === true ? (
          <Alert
            className="th-alert-container"
            // color="dark"
            // color="info"
            isOpen={this.state.alertVisible}
            toggle={this.onDismiss}
          >
            {/* {
                    alerts.length > 0 ? JSON.stringify(alerts) : "N/A"
                  } */}
            {alerts && alerts !== null ? (
              <div className="th-alert-content">
                {alerts.map((e, i) => {
                  return <div key={i}>{e ? e.message.toString() : "N/A"}</div>;
                })}
              </div>
            ) : null}
          </Alert>
        ) : null}

        <Router history={this.props.history}>
          <Switch>
            {routes.map((e, i) => {
              if (currentPath !== "/" && currentPath !== "") {
                if (e.requiresAuth === true && isLoggedIn == false) {
                  return <Redirect key={i} from={e.path} to={"/login"} />;
                }

                if (e.requiresAuth === true && e.rolesRequired.length > 0) {
                  var roleAquired = checkAquiredRole(e.rolesRequired, auth);
                  if (!roleAquired) {
                    return <Redirect key={i} from={e.path} to={"/login"} />;
                  }
                }
              }

              return (
                <Route
                  key={i}
                  exact
                  path={e.path}
                  render={(props) => (
                    <e.component
                      isLoggedIn={isLoggedIn}
                      user={user}
                      auth={auth}
                      onetimeCode={onetimeCode}
                      address={address}
                      sponsor={sponsor}
                      subject={subject}
                      school={school}
                      allTemplates={allTemplates}
                      subjects={subjects}
                      sponsors={sponsors}
                      templateCatalouge={templateCatalouge}
                      templateCatalougeFiltered={templateCatalougeFiltered}
                      searchTerm={searchTerm}
                      lastSearchTerm={lastSearchTerm}
                      lastSearchOptions={lastSearchOptions}
                      searchFilter={searchFilter}
                      searchFilterOption={searchFilterOption}
                      searchFilterCatalouge={searchFilterCatalouge}
                      sessions={sessions}
                      savedBooks={savedBooks}
                      templateDetail={templateDetail}
                      childDetail={childDetail}
                      saveToken={saveToken}
                      orderHistoryDetail={orderHistoryDetail}
                      authToken={authToken}
                      onNavigateToClientPage={this.onNavigateToClientPage}
                      getRequestHeaders={this.getRequestHeaders}
                      clearOnetimeCode={this.clearOnetimeCode}
                      onSetAlert={this.onSetAlert}
                      onClearAlert={this.onClearAlert}
                      onUpdateUser={this.onUpdateUser}
                      onUpdateAuth={this.onUpdateAuth}
                      onUpdateSearchFilter={this.onUpdateSearchFilter}
                      onUpdateSearchFilterOption={
                        this.onUpdateSearchFilterOption
                      }
                      onUpdateAddress={this.onUpdateAddress}
                      onUpdateSponsor={this.onUpdateSponsor}
                      onUpdateSubject={this.onUpdateSubject}
                      onUpdateSchool={this.onUpdateSchool}
                      onUpdateSearchTerm={this.onUpdateSearchTerm}
                      onUpdateActiveSavedBook={this.onUpdateActiveSavedBook}
                      onUpdateChildDetail={this.onUpdateChildDetail}
                      onUpdateSearchFilterCatalouge={
                        this.onUpdateSearchFilterCatalouge
                      }
                      onLogout={this.onLogout}
                      requestAllSubjects={this.requestAllSubjects}
                      requestAllSponsors={this.requestAllSponsors}
                      requestLoginBypass={this.requestLoginBypass}
                      requestCheckToken={this.requestCheckToken}
                      requestChildLogin={this.requestChildLogin}
                      requestChangeFavouriteTemplate={
                        this.requestChangeFavouriteTemplate
                      }
                      onSelectStartWriting={this.onSelectStartWriting}
                      requestAddRemoveTemplateSearchFilterOption={
                        this.requestAddRemoveTemplateSearchFilterOption
                      }
                      requestTemplateDetails={this.requestTemplateDetails}
                      requestOrderHistoryDetails={
                        this.requestOrderHistoryDetails
                      }
                      requestRefreshOrderHistoryStatus={
                        this.requestRefreshOrderHistoryStatus
                      }
                      requestUpdateTemplateDetails={
                        this.requestUpdateTemplateDetails
                      }
                      requestUpdateUserDetails={this.requestUpdateUserDetails}
                      requestOneTimeCode={this.requestOneTimeCode}
                      requestUpdateUserWelcomeSetting={
                        this.requestUpdateUserWelcomeSetting
                      }
                      requestUpdateAddress={this.requestUpdateAddress}
                      requestUpdateSponsor={this.requestUpdateSponsor}
                      requestUpdateSubject={this.requestUpdateSubject}
                      requestUpdateSchool={this.requestUpdateSchool}
                      requestUpdateFilter={this.requestUpdateFilter}
                      requestUpdateFilterOption={this.requestUpdateFilterOption}
                      requestDeleteFilterOption={this.requestDeleteFilterOption}
                      requestSearchFilterCatalouge={
                        this.requestSearchFilterCatalouge
                      }
                      requestSyncTemplates={this.requestSyncTemplates}
                      requestUpdateTemplateImage={
                        this.requestUpdateTemplateImage
                      }
                      requestUpdateSponsorImage={this.requestUpdateSponsorImage}
                      requestUploadTemplateResource={
                        this.requestUploadTemplateResource
                      }
                      onToggleEditorMode={this.onToggleEditorMode}
                      requestSetCoverImage={this.requestSetCoverImage}
                      requestSetResourceType={this.requestSetResourceType}
                      requestSetThumbnailImage={this.requestSetThumbnailImage}
                      requestDeleteTemplateImage={
                        this.requestDeleteTemplateImage
                      }
                      requestDeleteTemplateResource={
                        this.requestDeleteTemplateResource
                      }
                      requestDeleteSession={this.requestDeleteSession}
                      requestCreateSavedBook={this.requestCreateSavedBook}
                      requestDeleteSavedBook={this.requestDeleteSavedBook}
                      requestUpdateChildDetail={this.requestUpdateChildDetail}
                      requestDeleteChild={this.requestDeleteChild}
                      requestAddChildrenBulk={this.requestAddChildrenBulk}
                      requestAddToBasket={this.requestAddToBasket}
                      requestRemoveFromBasket={this.requestRemoveFromBasket}
                      requestCheckout={this.requestCheckout}
                      requestDeleteAddress={this.requestDeleteAddress}
                      onUpdateAuthToken={this.onUpdateAuthToken}
                      onHideHeader={this.onHideHeader}
                      onShowHeader={this.onShowHeader}
                      onSubmitSearch={this.onSubmitSearch}
                      favRef={this.favRef}
                      showFavoriteIcon={this.showFavoriteIcon}
                      onHideFavorite={this.onHideFavorite}
                      requestSavedBooks={this.requestSavedBooks}
                      requestAddSchool={this.requestAddSchool}
                      voucherApplied={voucherApplied}
                      onApplyVoucher={this.onApplyVoucher}
                      onCheckoutClick={this.onCheckoutClick}
                      {...props}
                    />
                  )}
                />
              );
            })}
          </Switch>
        </Router>
        {/* 
          <Route exact path='/dev' component={Dev} />
          
          <Route exact path='/faq' component={FAQ} />
          <Route exact path='/help' component={Help} />
          <Route exact path='/about' component={About} /> */}

        {/*<Route path='/counter' component={Counter} />*/}
        {/*<Route path='/fetch-data/:startDateIndex?' component={FetchData} />*/}

        {isDisplayCookieWarning === true ? (
          <div className="cookie-banner-block">
            <div className="cookie-banner-content">
              <div className="cookie-banner-content__text">
                This website uses cookies to give you the best possible
                experience. Many of these cookies are essential to the efficient
                operation of this site.
              </div>

              <div className="cookie-banner-buttons">
                <button className="btn btn--sml btn--secondary" to="/register">
                  Read more
                </button>
                <button
                  className="btn btn--sml btn--success"
                  onClick={this.onClickAcceptCookies}
                >
                  Accept Cookies
                </button>
              </div>
            </div>
          </div>
        ) : null}
      </Layout>
    );
  }
}

export default withRouter(App);
