
import React, { Component } from 'react';
import { SUPPORT_EMAIL } from '../constants/string_constants';


class LoginChild extends Component {

    constructor(props){
        super(props);

         this.props.onHideHeader();


        this.state = {
            username: "",
            password: "",
        };

        this.onChangeUsername = this.onChangeUsername.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);
        
        this.onSubmitForm = this.onSubmitForm.bind(this);
    }

    componentDidMount() {
        let paramString = window.location.search.split('?')[1];
        let queryString = new URLSearchParams(paramString);
        for(let pair of queryString.entries()) {
            console.log("Key is:" + pair[0]);
            if(pair[0] === "u") {
                this.setState({
                    username: pair[1]
                });
            }
        }
    }

    onChangeUsername(_e) {
        this.setState({
            username: _e.target.value
        });
    }

    onChangePassword(_e) {
        this.setState({
            password: _e.target.value
        });
    }

    onSubmitForm(_e) {
        _e.preventDefault();


        this.props.requestChildLogin(this.state.username, this.state.password);
    }
    
    render() {

        const { username, password } = this.state;

        return (
            <div className='login-block'>

                <form className="login-block-card" onSubmit={ this.onSubmitForm }>

                    <div className="login-card-content">

                        <div>
                            <div className='login-card-content__icon'><i className="fa-light fa-lock"></i></div>
                            <h2 className="login-card-content__title">Child account login</h2>
                            <p className="login-card-content__text">Simply enter your username and the code your teacher or parent generated for you.</p>   
                        </div>

                        <div className='app-field-wrapper'>
                            <label>Username</label>
                            <input autoComplete="on" name="username" type="text" maxLength="40" placeholder="Username" onChange={ this.onChangeUsername } value={ username } />
                        </div>

                        <div className='app-field-wrapper'>
                            <label>Passcode</label>
                            <input autoComplete="on" name="password" type="password" maxLength="128" placeholder="Your code" onChange={ this.onChangePassword } value={ password } />
                        </div>

                        <button className="btn btn--primary-fw" type="submit"> Sign In </button>

                    </div>

                </form>
                
            </div>
        );
    }
}

export default LoginChild;