import React from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";

const RemoveBasketModal = ({
  open = false,
  onClose = () => {},
  onConfirmDelete = () => {},
}) => {
  return (
    <>
      <Transition appear show={open} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-800/80" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-lg md:max-w-xl lg:max-w-2xl h-auto transform overflow-hidden rounded-2xl bg-white p-6 text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg md:text-xl lg:text-2xl font-medium leading-6 text-gray-900"
                  >
                    Remove Basket Item
                  </Dialog.Title>
                  <div className="mt-4">
                    <p className="text-2xl text-gray-900">
                      Are you sure you want to remove this item? Your item will
                      be saved back to your projects.
                    </p>
                  </div>

                  <div className="mt-6 flex justify-center space-x-4">
                    <button
                      type="button"
                      className="inline-flex h-16 w-48 text-xl justify-center items-center border border-transparent rounded-full px-4 py-2 font-medium text-blue-900 hover:text-gray-800 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={onClose}
                    >
                      Calcel
                    </button>
                    <button
                      type="button"
                      className="inline-flex h-16 w-48 justify-center items-center rounded-full border border-transparent p-8 bg-purple-200 px-4 py-2 text-xl font-medium text-white hover:opacity-80 focus:outline-none focus-visible:ring-2 focus-visible:ring-red-500 focus-visible:ring-offset-2"
                      onClick={onConfirmDelete}
                    >
                      Continue
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default RemoveBasketModal;
