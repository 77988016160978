import React, { Component } from "react";
import Loader from "../../components/Loader";
import { defaultChildDetail } from "../../constants/data";
import ChildAccountItem from "./ChildAccountItem";
import queryString from "query-string";

class ChildAccounts extends Component {
  constructor(props) {
    super(props);

    this.props.onShowHeader();
    const isCreate = queryString.parse(this.props.location.search).create;
    this.state = {
      changes: null,
      quickbuildChildren: isCreate && isCreate == "true" ? [{ first_name: "", last_name: "" }] : [],
      isCreate,
    };

    this.onClickNewChildAccount = this.onClickNewChildAccount.bind(this);
    this.onClickEditChildAccount = this.onClickEditChildAccount.bind(this);
    this.onClickClearOnetimeCode = this.onClickClearOnetimeCode.bind(this);
    this.onClickGenerateOnetimeCode = this.onClickGenerateOnetimeCode.bind(this);
    this.onClickAddQuickBuildChild = this.onClickAddQuickBuildChild.bind(this);

    //this.onChangeFirstName = this.onChangeFirstName.bind(this);
    //this.onChangeLastName = this.onChangeLastName.bind(this);
    this.saveQuickBuildChanges = this.saveQuickBuildChanges.bind(this);

    this.onClickAddQuickbuildChildRow = this.onClickAddQuickbuildChildRow.bind(this);
    this.onClickDeleteQuickbuildChild = this.onClickDeleteQuickbuildChild.bind(this);
    this.onClickSubmitQuickbuildChildren = this.onClickSubmitQuickbuildChildren.bind(this);
    this.props.onHideFavorite(true);
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.changes !== props.user) {
      return {
        changes: props.user,
      };
    }
    return null;
  }

  onClickNewChildAccount() {
    const nwAddLink = "/User/ChildAccounts/add";
    this.props.onUpdateChildDetail(defaultChildDetail);
    this.props.onNavigateToClientPage(nwAddLink);
  }

  onClickEditChildAccount(_child) {
    const editLink = `/User/ChildAccounts/edit/${_child.id}`;
    this.props.onUpdateChildDetail(_child);
    this.props.onNavigateToClientPage(editLink);
  }

  onClickClearOnetimeCode() {
    this.props.clearOnetimeCode();
    this.props.onShowHeader();
  }

  onClickGenerateOnetimeCode() {
    this.props.requestOneTimeCode();
    this.props.onHideHeader();
  }

  onClickAddQuickBuildChild() {
    this.props.requestOneTimeCode();
  }

  onChangeFirstName(_i, _e) {
    var qbChildren = this.state.quickbuildChildren;
    qbChildren[_i].first_name = _e.target.value;
    this.saveQuickBuildChanges(qbChildren);
  }

  onChangeLastName(_i, _e) {
    var qbChildren = this.state.quickbuildChildren;
    qbChildren[_i].last_name = _e.target.value;
    this.saveQuickBuildChanges(qbChildren);
  }

  saveQuickBuildChanges(_qbChildren) {
    this.setState({
      quickbuildChildren: _qbChildren,
    });
  }

  onClickAddQuickbuildChildRow() {
    var qbChildren = this.state.quickbuildChildren;
    qbChildren.push({ first_name: "", last_name: "" });
    this.setState({
      quickbuildChildren: qbChildren,
    });
  }

  onClickDeleteQuickbuildChild(_i) {
    var qbChildren = this.state.quickbuildChildren;
    qbChildren.splice(_i, 1);
    this.saveQuickBuildChanges(qbChildren);
  }

  onClickSubmitQuickbuildChildren() {
    var qbChildren = this.state.quickbuildChildren;
    //TODO request the server post sending the array of child names.
    //alert("Wanted to save: " + JSON.stringify(qbChildren));

    if (qbChildren.length > 0) {
      this.props.requestAddChildrenBulk(qbChildren);
      this.setState({
        quickbuildChildren: [],
      });
    }
  }

  render() {
    const user = this.state.changes;

    if (user === null) {
      return <Loader />;
    }

    const { onetimeCode } = this.props;
    const { quickbuildChildren } = this.state;

    const childCount = user.children.length;
    const qbChildCount = quickbuildChildren.length;

    if (onetimeCode) {
      return (
        <div className="login-block">
          <div className="login-block-card">
            <div className="login-card-content">
              <div>
                <div className="login-card-content__icon">
                  <i className="fa-light fa-lock"></i>
                </div>
                <h2 className="login-card-content__code">{onetimeCode}</h2>
                <p className="login-card-content__text">Share this code with your children to allow them to login with their username</p>
              </div>
              <button className="btn btn--primary" type="button" onClick={this.onClickClearOnetimeCode}>
                {" "}
                Continue{" "}
              </button>
            </div>
          </div>
        </div>
      );
    }

    return (
      <div>
        {/*             
            {childCount > 0 ? (
                <div className="passcode-block">

                    <div className="passcode-block-content">

                        <div className="passcode-block-content__decr">
                            <h2 className="passcode-block-content__title">Generate passcode</h2>
                            <p className="passcode-block-content__text">You need to generate a code for children to use when logging in. Hit the button and create an access code for them to login with.</p>
                        </div>

                        <div className="passcode-block-content__action">
                            <button className="btn btn--success" onClick={this.onClickGenerateOnetimeCode}>Generate code</button>
                        </div>

                    </div>
                    
                </div>

            ) : null} */}

        {qbChildCount > 0 ? (
          <div className="section-block section-block--fixed-add-child">
            <div className="section-block-content">
              <h2 className="section-block-content__title">Add child accounts</h2>
              <p className="section-block-content__text">
                Add child accounts here. You can add multiple accounts at the same time and allow access to Topic Heroes<sup>®</sup>
              </p>

              <div className="app-form">
                <div className="app-form__content">
                  {quickbuildChildren.map((e, i) => {
                    return (
                      <div key={i} className="app-form__row">
                        <div className="app-field-wrapper">
                          <label>First Name</label>
                          <input
                            type="text"
                            placeholder="First name"
                            onChange={this.onChangeFirstName.bind(this, i)}
                            value={quickbuildChildren[i].first_name}
                          />
                        </div>
                        <div className="app-field-wrapper">
                          <label>Last Name</label>
                          <input type="text" placeholder="Last name" onChange={this.onChangeLastName.bind(this, i)} value={quickbuildChildren[i].last_name} />
                        </div>
                        <div className="app-field-wrapper--button">
                          <button className="btn btn--sml btn--danger" onClick={() => this.onClickDeleteQuickbuildChild(i)}>
                            Delete
                          </button>
                        </div>
                      </div>
                    );
                  })}

                  <div className="app-field-wrapper--inline">
                    <button className="btn btn--secondary" type="button" onClick={this.onClickAddQuickbuildChildRow}>
                      {" "}
                      Add another{" "}
                    </button>
                    <button className="btn btn--success" type="button" onClick={this.onClickSubmitQuickbuildChildren}>
                      {" "}
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="section-block">
          <div className="section-block-content">
            <h2 className="section-block-content__title">Child accounts</h2>
            {childCount < 1 ? (
              <p className="section-block-content__text">You've not created a child account yet, to get started, click 'Create Account'</p>
            ) : (
              <p className="section-block-content__text">
                You can see the list of child accounts here, you can add another account or edit the existing accounts.
              </p>
            )}

            <div className="child-account-block__grid">
              {user.children.map((e, i) => {
                return (
                  <ChildAccountItem
                    key={i}
                    child={e}
                    onClickEditChildAccount={this.onClickEditChildAccount}
                    onNavigateToClientPage={this.props.onNavigateToClientPage}
                    allowEdit={true}
                  />
                );
              })}
            </div>

            <button className="btn btn--primary" type="button" onClick={this.onClickAddQuickbuildChildRow}>
              Create account
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ChildAccounts;
