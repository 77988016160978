
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import FilterList from '../../../components/filter-list/FilterList';

class Filters extends Component {

    constructor(props) {
        super(props);

        this.onClickAddFilter = this.onClickAddFilter.bind(this);
         this.props.onHideFavorite(true);

    }

    onClickAddFilter() {

        this.props.onUpdateSearchFilter({
            id: 0,
            title: ""
        });
        this.props.history.push("/filters/add");
    }
    

    render() {

        return (
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>

                    <div className='th-panel bgc-white pall-l th-br'>

                        <div className="spc-b-s">

                            <h2 className="hw-font spc-b-s t-purple">Search Filters</h2>

                            <p>All current filters are listed below. You can edit these filters to change or add options. To create a new filter, hit the 'Create Filter' button.</p>

                        </div>

                        <div className="spc-b-l">

                        <FilterList 
                            searchFilter = { this.props.searchFilter }
                            onUpdateSearchFilterOption = { this.props.onUpdateSearchFilterOption }
                            onUpdateSearchFilter = { this.props.onUpdateSearchFilter }
                            searchFilterCatalouge = { this.props.searchFilterCatalouge }
                            history= { this.props.history } 
                        />

                        </div>

                        <div className="spc-b-s">
                        
                            <button className='th-admin-btn' onClick={ this.onClickAddFilter }>Create Filter</button>

                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default Filters;