
import React, { Component } from 'react';
import Loader from '../../components/Loader';


class ChildAccountEdit extends Component {

    constructor(props) {
        super(props);

        this.props.onShowHeader();
    this.props.onHideFavorite(true);
        
        this.state = {
            changes: null,
        };

        this.onChangeFirstname = this.onChangeFirstname.bind(this);
        this.onChangeLastname = this.onChangeLastname.bind(this);
        this.onChangeOldPassword = this.onChangeOldPassword.bind(this);
        this.onChangePassword = this.onChangePassword.bind(this);

        this.onSubmitForm = this.onSubmitForm.bind(this);
        this.onDeleteChild = this.onDeleteChild.bind(this);
    }

    componentDidMount(){
        
        this.setState({
            changes: this.props.childDetail
        });
    }

    onChangeFirstname(_e) {
        var child = this.state.changes;
        child.first_name =  _e.target.value;
        this.saveChanges(child);
    }

    onChangeLastname(_e) {
        var child = this.state.changes;
        child.last_name =  _e.target.value;
        this.saveChanges(child);
    }

    onChangeOldPassword(_e) {
        var child = this.state.changes;
        child.old_password =  _e.target.value;
        this.saveChanges(child);
    }

    onChangePassword(_e) {
        var child = this.state.changes;
        child.password =  _e.target.value;
        this.saveChanges(child);
    }

    saveChanges(_child) {
        this.setState({
           changes: _child 
        });
    }

    onSubmitForm() {
        //_e.preventDefault();

        const childDetails = this.state.changes;
        this.props.requestUpdateChildDetail(childDetails);
    
    }

    onDeleteChild() {
        const childDetails = this.state.changes;
        this.props.requestDeleteChild(childDetails);
    }

    render() {

        
        const child = this.state.changes;
        if(child === null) {
            return (<Loader />);
        }

        return (
            <div className="section-block">

                <div className='section-block-content'>

                    <h2 className="section-block-content__title">Edit account</h2>
                    <p className='section-block-content__text'>You can update your child account details here.</p>

                    <div className='app-form'>

                        <div className='app-form__content'>
                            <div className='app-form__row'>
                                <div className='app-field-wrapper'>
                                    <label htmlFor="firstname">First name</label>
                                    <input name="firstname" placeholder="First Name" type="text" onChange={ this.onChangeFirstname } value={ child.first_name } maxLength="128" />
                                </div>

                                <div className='app-field-wrapper'>
                                    <label htmlFor="lastname">Last name</label>
                                    <input name="lastname" placeholder="Last Name" type="text" onChange={ this.onChangeLastname } value={ child.last_name } maxLength="128" />
                                </div>
                            </div>

                            {
                                (child.id && child.id.length > 0) ? (
                                    <div className='app-field-wrapper'>
                                        <label htmlFor="oldpassword">Current password</label>
                                        <input name="oldpassword" placeholder="Current password" type="password" onChange={ this.onChangeOldPassword } value={ child.old_password } maxLength="128" />
                                    </div>
                                ) : null
                            }

                            <div className='app-field-wrapper'>
                                <label htmlFor="password">New password</label>
                                <input name="password" placeholder="New password" type="password" onChange={ this.onChangePassword } value={ child.password } maxLength="128" />
                            </div>

                            <div className='app-form__buttons'>
                                <button className='btn btn--danger' onClick={ this.onDeleteChild }>Delete account</button>
                                <button className='btn btn--success' onClick={ this.onSubmitForm }>Save changes</button>
                            </div>

                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default ChildAccountEdit;