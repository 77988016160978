
import React, { Component } from 'react';
import SponsorList from '../../../components/sponsors/SponsorList';
import { defaultSponsor } from '../../../constants/data';

class Sponsors extends Component {

    constructor(props) {
        super(props);

        this.onClickAddSponsor = this.onClickAddSponsor.bind(this);
         this.props.onHideFavorite(true);

    }

    componentDidMount() {
        this.props.requestAllSponsors();
    }

    onClickAddSponsor() {

        this.props.onUpdateSponsor(defaultSponsor);
        this.props.history.push("/sponsors/add");
    }
    

    render() {

        return (
            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>
                    <div className='th-panel bgc-white pall-l th-br'>

                        <div className="spc-b-l">
                            <h2 className="hw-font spc-b-s t-purple">Manage Sponsors</h2>
                            <p></p>
                        </div>

                        <SponsorList 
                            sponsor = { this.props.sponsor }
                            sponsors = { this.props.sponsors }
                            onUpdateSponsor = { this.props.onUpdateSponsor }
                            history= { this.props.history } 
                        />

                        <div>
                            <button className='th-admin-btn' onClick={ this.onClickAddSponsor }>Add Sponsor</button>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default Sponsors;