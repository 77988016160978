const OrderSummary = ({
  onApplyVoucher = () => {},
  voucherApplied = false,
  isBasketEmpty = false,
  onCheckoutClick = () => {},
}) => {
  return (
    <div className="rounded-lg bg-gray-100 p-6 shadow-sm border border-gray-50">
      <h2 className="text-3xl font-semibold text-black">Order Summary</h2>
      <div className="mt-6">
        <p className="text-gray-900 text-[16px]">
          Enter your <span className="font-medium">School Voucher Code</span> to
          activate your basket and claim your FREE books.
        </p>
        <button className="mt-2 text-[14px] font-semibold text-purple-200 hover:text-purple-100 transition-colors">
          Get code
        </button>
      </div>
      <div className="mt-6">
        <label className="text-lg font-medium text-purple-200">
          School Voucher Code
        </label>
        <input
          type="text"
          placeholder="XXX-XXX-XXX"
          className="mt-1 w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 focus:border-purple-500 focus:outline-none focus:ring-1 focus:ring-purple-500"
        />
      </div>
      {!voucherApplied && (
      <button
        onClick={() => onApplyVoucher("XXX-XXX-XXX")}
        disabled={isBasketEmpty}
        className={`mt-6 w-full h-18 justify-center items-center rounded-full px-4 py-2  text-[16px] transition-colors ${
          isBasketEmpty
            ? "bg-gray-300 text-gray-900 cursor-not-allowed"
            : "bg-purple-200 text-white hover:opacity-80"
        }`}
      >
        Apply Voucher
        </button>
      )}

      {voucherApplied && (
        <button
          onClick={onCheckoutClick}
          disabled={isBasketEmpty}
          className={`mt-6 w-full h-18 justify-center items-center rounded-full px-4 py-2 text-[16px] transition-colors ${
            isBasketEmpty
              ? "bg-gray-300 text-gray-900 cursor-not-allowed"
              : "bg-purple-200 text-white hover:opacity-80"
          }`}
        >
          Continue to Checkout
        </button>
      )}
    </div>
  );
};

export default OrderSummary;
