
import React, { Component } from 'react';

class LoginRedirect extends Component {

    constructor(props) {
        super(props);

    }

    componentDidMount(){
        setTimeout(this.props.requestLoginBypass(), 200);
       
    }

    render() {

        return (
            <div>
                <div className="form-inner-container register-form  panel-bg-white round-corner-12 panel-shadow bg-texture-dots has-validation-callback">
                    <div className="form-header">
                        <h2 className="text-bold space-after-8 text-center">Redirecting...</h2>
                    </div>
                </div>
        
            </div>
        );
    }
}

export default LoginRedirect;

