
import thresource from '../../apis/thresource';

import { 
    TEMPLATES_SELECT_ACTION,
    TEMPLATES_FETCH_ACTION,
    SEARCH_FILTER_SELECT_ACTION,
    SEARCH_FILTER_FETCH_ACTION,
    SPONSOR_SELECT_ACTION,
    SPONSOR_FETCH_ACTION,
    SCHOOL_SELECT_ACTION,
    SCHOOL_FETCH_ACTION,
    BASKETITEM_SELECT_ACTION,
    BASKET_FETCH_ACTION,
    ORDER_HISTORY_FETCH_ACTION,
    SUBJECT_SELECT_ACTION,
    SUBJECT_FETCH_ACTION
} from "../../constants/action_types";
import { getRequestHeadersFromAuth } from '../../utils/Utils';



export const selectTemplate = post => {
    return {
      type: TEMPLATES_SELECT_ACTION,
      payload: post
    };
};

//thunk action creator
export const fetchTemplates = () => {

    return async dispatch => { //function(dispatch, getState)
      const response = await thresource.get("/api/templates/list");
      if(response.data.success === true){
        dispatch({ type: TEMPLATES_FETCH_ACTION, payload: response.data.data });
      }
    }
  
};

export const selectSponsor = post => {
  return {
    type: SPONSOR_SELECT_ACTION,
    payload: post
  };
};

//thunk action creator
export const fetchSponsors = () => {

  return async dispatch => { //function(dispatch, getState)
    const response = await thresource.get("/api/sponsors/list");
    if(response.data.success === true){
      dispatch({ type: SPONSOR_FETCH_ACTION, payload: response.data.data });
    }
  }

};


export const selectSchool = post => {
  return {
    type: SCHOOL_SELECT_ACTION,
    payload: post
  };
};

export const fetchSchools = (auth) => {
  return async dispatch => { 
    const headers = getRequestHeadersFromAuth(auth);
    const response = await thresource.get("/api/schools/list", headers);
    if(response.data.success === true){
      dispatch({ type: SCHOOL_FETCH_ACTION, payload: response.data.data });
    }
  }

};


export const selectSubject = post => {
  return {
    type: SUBJECT_SELECT_ACTION,
    payload: post
  };
};

export const fetchSubjects = (auth) => {
  const headers = getRequestHeadersFromAuth(auth);
  return async dispatch => { 
    const response = await thresource.get("/api/subjects/list", headers);
    if(response.data.success === true){
      dispatch({ type: SUBJECT_FETCH_ACTION, payload: response.data.data });
    }
  }

};



export const selectSearchFilter = filter => {
  return {
    type: SEARCH_FILTER_SELECT_ACTION,
    payload: filter
  };
};

//thunk action creator 
export const fetchSearchFilters = () => {

  return async dispatch => { 
    const response = await thresource.get("/api/searchfilter/catalouge");
    if(response.data.success === true){
      dispatch({ type: SEARCH_FILTER_FETCH_ACTION, payload: response.data.data });
    }
  }

};

// export const selectBasketItem = filter => {
//   return {
//     type: BASKETITEM_SELECT_ACTION,
//     payload: filter
//   };
// };

// //thunk action creator 
// export const fetchBasket = () => {

//   return async dispatch => { 
//     const response = await thresource.get("/api/basket/current");
//     if(response.data.success === true){
//       dispatch({ type: BASKET_FETCH_ACTION, payload: response.data.data });
//     }
//   }

// };

export const requestFetchBasket = (headers) => {

    
  return async dispatch => { 
    const response = await thresource.get("/api/basket/current", headers);
    //console.log("basket current result: ", response.data.data);
    if(response.data.success === true){
      dispatch({ type: BASKET_FETCH_ACTION, payload: response.data.data });
    }
  }

};

export const requestFetchOrderHistory = (headers) => {

    
  return async dispatch => { 
    const response = await thresource.get("/api/orderhistory/list", headers);
    //console.log("basket current result: ", response.data.data);
    if(response.data.success === true){
      dispatch({ type: ORDER_HISTORY_FETCH_ACTION, payload: response.data.data });
    }
  }

};

