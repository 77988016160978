

import React, { Component } from 'react';
import { COMPANY_NAME, SUPPORT_EMAIL } from '../constants/string_constants';

class Privacy extends Component {

    render() {

        return (
            <div className='content-container p-row'>

                <div className='content-mw pall-l'>

                    <div className="spc-b-l">
                        <h1 className="t-light spc-b-r">Privacy Policy</h1>
                        <p className='no-spc'>{COMPANY_NAME}. (“Company”) believes in the importance of its trust relationship with customers, and recognizes the importance of protecting the privacy of its customers as a foundation for that relationship. The Company sets forth this privacy policy (“Privacy Policy”) to ensure that customer Personal Information is handled with care whenever customers use the services provided by the Company on the Website (“Services”), and strives at all times for the appropriate handling and security management of customer Personal Information in accordance herewith.</p>
                    </div>

                    <div className="legal-section">
                        <h2>Article 1: Compliance with Laws, Regulations, and Guidelines</h2>
                        <p className='no-spc'>The Company will comply with the Act on the Protection of Personal Information (“Personal Information Protection Act”), other relevant laws and regulations, guidelines stipulated by the Personal Information Protection Commission, and this Privacy Policy, in connection with its acquisition, use and other handling of Personal Information (i.e., personal information as defined in Article 2, Paragraph 1 of the Personal Information Protection Act) when operating the Services.</p>
                    </div>

                    <div className="legal-section">
                        <h2>Article 2: Acquired Personal Information and Method of Acquisition</h2>
                        <p>The Personal Information acquired by the Company on the Services will be as listed below, depending on the method of acquisition thereof. The customer-related information acquired by the Company includes cookies and other information which cannot by itself identify an individual, but will be handled as Personal Information where it is possible to identify an individual by cross-referencing such information against other information In the Company’s possession.</p>
                        
                        <ol>
                            <li>Information Provided Directly by Customers
                                <p>The Company may request that, when registering for newsletters on the Website, customers provide Personal Information such as, principally, the following.</p>
                                <ul>
                                    <li>Information related to customer profiles, such as date of birth, sex, or occupation.</li>
                                    <li>Information related to customer contact information, such as email address or prefecture of residence.</li>
                                </ul>
                                <p>In addition, when customers make requests as set forth in Article 5, the Company will, with a view to preventing identity fraud, request that such customers provide personal identification documents, and on such occasion will acquire customer Personal Information as indicated on such documents.</p>
                            </li>
                            
                            <li>Information Acquired Automatically by the Company
                                <p>Each time a customer uses the Services, the Company will automatically acquire information related to such customer’s state of access to and method of use of the Website. This will include the following information.</p>
                                <ul>
                                    <li>IP address</li>
                                    <li>Information recorded by cookies</li>
                                </ul>
                            </li>
                        </ol>
                    </div>

                    <div className="legal-section">
                        <h2>Article 3: Purposes of Use</h2>
                        <ol>
                            <li>The Company will use the customer Personal Information acquired in conjunction with provision of the Services mainly for the purposes indicated in the following items (“Purposes of Use”).
                                <ol>
                                    <li>To provide and maintain the Services (including acceptance of newsletter registration and identity verification related to the Services), and to improve the Services;</li>
                                    <li>To send, deliver, and measure effectiveness of advertisements (including direct mail) related to the Services;</li>
                                    <li>To respond to inquiries related to the Services;</li>
                                    <li>To respond to conduct in breach of Company Terms &amp; Conditions related to the Services (“Website Terms &amp; Conditions”); and</li>
                                    <li>To notify customers about amendments and the like to the Website Terms &amp; Conditions or this Privacy Policy.</li>
                                </ol>
                            </li>
                            <li>The Purposes of Use given in the items of the preceding paragraph may be modified only within the scope reasonably determined to be relevant to the pre-modified Purposes of Use. In such case, the Company will make announcements in accordance with Article 8.</li>
                        </ol>
                    </div>

                    <div className="legal-section">
                        <h2>Article 4: Disclosure of Personal Information to Third Parties</h2>
                        <ol>
                            <li>The Company may provide customer Personal Information to third parties in cases falling under any of the following items.
                                <ol>
                                    <li>In cases pursuant to laws and regulations; </li>
                                    <li>In cases where Personal Information is being provided to third parties with the relevant customer’s consent or at such customer’s instruction;</li>
                                    <li>In cases where, in connection with the operation or other business of the Website, the handling of Personal Information is outsourced in whole or in part to an extent necessary to achieve the Purposes of Use; and</li>
                                    <li>In cases granted under the Personal Information Protection Act or other laws and regulations, other than as in the preceding items.</li>
                                </ol>
                            </li>
                            <li>When providing customer Personal Information to consignees under Item (3) of the preceding paragraph, the Company will provide necessary and appropriate supervision to ensure that customer Personal Information is managed safely by such consignees.</li>
                        </ol>
                    </div>

                    <div className="legal-section">
                        <h2>Article 5: Procedures for Various Requests Related to Customer Personal Information</h2>
                        <ol>
                            <li>The Company will handle through the inquiry contact point of Article 6, in accordance with the procedures set forth in the present Article, all requests that customers personally make pursuant to the provisions of the Personal Information Protection Act for (i) notification of the purposes of use of, (ii) disclosure of, (iii) revision of the content of, (iv) suspension of use or deletion of, or (v) suspension of provision to third parties of, any retained personal data (i.e., retained personal data as defined in Article 2, Paragraph 7 of the Personal Information Protection Act) identifying such customers that has been acquired by the Company (“Subject Personal Information”; such requests collectively “Disclosure etc. Requests”). It should be noted that customers must specify the relevant Subject Personal Information when making a Disclosure etc. Request.</li>
                            <li>When receiving a Disclosure etc. Request as in the preceding paragraph, the Company will require that the relevant customer mail to the inquiry contact point of Article 6 a copy of their driver’s license, passport, or other Company-specified document, as an identity verification document in order to confirm that the request was in fact made by such customer. The procedures for such Disclosure etc. Request will move forward without delay once identity verification is completed using such documents.</li>
                            <li>Please be advised that notwithstanding the provisions of Paragraph 1, the Company may not be able to comply with customer requests in respect of Subject Personal Information in the case where the Company cannot comply or is permitted not to comply with such requests under laws and regulations, or in the case where the Company’s retention period has already passed for such Subject Personal Information.</li>
                        </ol>
                    </div>

                    <div className="legal-section">
                        <h2>Article 6: Contact Point for Inquiries and Complaints Concerning Handling of Personal Information </h2>
                        <p>Inquiries and complaints related to the handling of customer personal information should be directed to the following contact point.</p>
                        <p className='no-spc'>{COMPANY_NAME }. Customer Service
                        <br />{SUPPORT_EMAIL}
                        <br /></p>
                    </div>

                    <div className="legal-section">
                        <h2>Article 8: Privacy Policy Amendment Procedures</h2>
                        <p className='no-spc'>The Company may amend this Privacy Policy if necessary. In such case, the effective date and particulars of this Privacy Policy as amended will be announced by indication on the Website or by other appropriate method, or will be communicated to customers directly.</p>
                    </div>
                    
                    <div className="legal-section">
                        <p><strong>Enacted:</strong> January 1st, 2022<br /><strong>Revised:</strong> October 1st, 2022</p>
                        <p><strong>Enterprise Handling Personal Information:</strong>
                        <br />{COMPANY_NAME}.
                        <br />{SUPPORT_EMAIL}</p>
                    </div>

                </div>

            </div>
        );
    }
}

export default Privacy;