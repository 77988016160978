import React, { Component } from "react";
import axios from "../apis/thresource";
import Loader from "../components/Loader";
import { Lock } from "lucide-react";

class CompleteLogin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      verificationCode: "",
      isLoading: false,
    };

    this.onChangeVerificationCode = this.onChangeVerificationCode.bind(this);
    this.onClickSendAnotherCode = this.onClickSendAnotherCode.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onChangeVerificationCode(_e) {
    this.setState({
      verificationCode: _e.target.value,
    });
  }

  onClickSendAnotherCode(_e) {
    this.setState({
      verificationCode: "",
      isLoading: true,
    });

    const postData = {
      email: this.props.user.email,
    };

    axios
      .post("/api/account/resendvirifynumber", JSON.stringify(postData))
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          this.props.onSetAlert(response.data.errors);
        }

        if (response.data.success) {
          const alert = { field_name: "", message: "Code resent" };
          this.props.onSetAlert([alert]);
        }
        this.setState({ isLoading: false });
      })
      .catch((error) => {
        this.setState({ isLoading: false });
        if (error.response && error.response.data) {
          this.props.onSetAlert(error.response.data.errors);
        }
      });
  }

  onSubmitForm = async (_e) => {
    _e.preventDefault();
    // TODO: update auth remove this line. this is hack
    // this.props.onUpdateAuth(this.props.authToken, true);
    // return;
    ///

    await this.verifyCode(this.props.user.mobile, this.state.verificationCode);

   

    const postData = {
      email: this.props.user.email,
      token: this.state.verificationCode,
      update_2fa: false,
    };

    // axios
    //   .post("/api/account/verifynumber", JSON.stringify(postData))
    //   .then((response) => {
    //     if (!response.data.success && response.data.errors) {
    //       this.props.onSetAlert(response.data.errors);
    //     }
    //     if (response.data.success) {
    //       this.props.onUpdateAuth(this.props.authToken, true);
    //     }
    //   })
    //   .catch((error) => {
    //     if (error.response && error.response.data) {
    //       //console.log("server data:", error.response.data);
  };

  verifyCode = async (mobileNumber, code) => {
    // TODO: change this to the actual mobile number
    this.setState({ isLoading: true });
    try {
      const response = await axios.post("/api/Twilio/VerifyCode", {
        mobile_number: mobileNumber,
        code: code,
      });
      this.setState({ isLoading: false });
      if (response.data.success) {
        console.log("Code verified successfully:", response.data);
        this.props.onUpdateAuth(this.props.authToken, true);

        // Handle success response
      } else {
        console.error("Failed to verify code:", response.data.errors);
        // Handle failure response
         this.props.onSetAlert(response.data.errors);
      }
    } catch (error) {
      console.error("Error verifying code:", error);
      if (error.response && error.response.data) {
        console.error("Server error response:", error.response.data.errors);
        // Handle server error response
      }
    }
  };

  render() {
    const { user } = this.props;
    const { verificationCode } = this.state;

    if (!user || this.state.isLoading) {
      return <Loader />;
    }

    return (
      <div className="min-h-screen flex items-center justify-center">
        <form onSubmit={this.onSubmitForm}>
          <div className="bg-gray-100 p-8 rounded-xl shadow-md w-full max-w-md sm:max-w-lg md:max-w-xl lg:max-w-2xl xl:max-w-3xl 2xl:max-w-4xl border border-gray-300">
            <div className="text-center mb-8">
              <div className="inline-block p-3 rounded-full bg-white border border-gray-300 mb-4">
                <Lock className="w-12 h-12 text-primary-400" />
              </div>

              <h2 className="login-card-content__title">Authentication</h2>
              <p className="login-card-content__text">
                We've sent you a code to {user?.mobile} Please enter the code to
                login.
              </p>
            </div>

            <div className="space-y-4 w-full">
              <label className="block text-lg font-medium text-primary-400">
                Enter Code
              </label>
              <input
                type="text"
                placeholder="&#8226;&#8226;&#8226;&#8226;&#8226;&#8226;"
                onChange={this.onChangeVerificationCode}
                value={verificationCode}
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
              />
            </div>

            {/* <button className="btn btn--primary" type="submit">
              {" "}
              Sign in{" "}
            </button> */}

            <div className="flex flex-col items-start  w-full">
              <button
                type="submit"
                className="mt-4 py-3 px-6 border border-primary-400 rounded-full shadow-sm text-lg font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary min-w-48"
              >
                Continue
              </button>
            </div>

            {/* <div className="mt-6 flex items-center justify-between">
              <p className="text-xl text-gray-900 hover:underline">Cancel</p>
              <p className="text-xl text-gray-900 hover:underline">
                Not received a code?{" "}
                <span onClick={this.onClickSendAnotherCode}> Send another</span>
              </p>
            </div> */}

            <div className="mt-6 flex items-center justify-between">
              <button
                onClick={this.onRequestReset}
                className="text-lg text-gray-900 hover:underline"
              >
                Cancel
              </button>
              <button
                onClick={this.onClickSendAnotherCode}
                className="text-lg text-gray-900 hover:underline"
              >
                Not received a code?
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default CompleteLogin;
