import React from "react";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

const HomeCarousel = ({ features }) => {
  return (
    <div className="slider-block">
      <div className="slider-block__content">
        <Carousel
          className="slider"
          emulateTouch={true}
          infiniteLoop={true}
          interval={3000}
          autoPlay={true}
          stopOnHover={true}
          centerMode={false}
          centerSlidePercentage={33.33}
          showArrows={true}
          showThumbs={false}
          showIndicators={true}
          showStatus={false}
        >
          {features.map((feat, i) => (
            <div key={i} className="slider__large-slide">
              <Link className="slider__link" to={`/topics/${feat.id}/details`}>
                <img src={feat.cover_image_url} alt={feat.title} />
              </Link>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default HomeCarousel;
