import BasketItem from "../../components/basket-list/BasketItem";
import OrderSummary from "../../components/basket-list/BasketSummery";
import { connect } from "react-redux";
import { requestFetchBasket } from "../../store/actions";
import { useState, useEffect } from "react";

const Checkout = ({
  requestFetchBasket,
  getRequestHeaders,
  basket,
  requestRemoveFromBasket,
  onApplyVoucher,
  voucherApplied,
  onCheckoutClick,
}) => {
  const [isLoading, setisLoading] = useState(false);
  // Sample data
  const orderDetails = {
    orderId: "ORD123456",
    contact: {
      name: "John Doe",
      email: "john@example.com",
      phone: "07700 900000",
    },
    delivery: {
      schoolName: "Sample School",
      className: "Class 5A",
      addressLine1: "123 School Street",
      addressLine2: "Education District",
      city: "London",
      county: "Greater London",
      postCode: "SW1A 1AA",
    },
  };

  useEffect(() => {
    setisLoading(true);
    requestFetchBasket(getRequestHeaders());
  }, [requestFetchBasket, getRequestHeaders]);

  useEffect(() => {
    setisLoading(false);
  }, [basket]);

  return (
    <div className="min-h-screen bg-gray-50 py-8 ">
      <div className="max-w-8xl px-4 sm:px-6 lg:px-8 lg:mx-[10%] xl:mx-[15%]">
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 sm:mb-0">
          Checkout order
        </h1>
        <p className="text-3xl sm:text-2xl font-bold mb-4 sm:mb-0 text-gray-900">
          {basket?.items?.length > 0
            ? basket?.items?.length === 1
              ? "You have 1 item in your basket."
              : `You have ${basket?.items?.length} items in your basket.`
            : "Your basket is empty."}
        </p>

        <h2 className="text-3xl font-semibold text-black mb-4">
          Order Details
        </h2>

        <div className="mt-8 grid gap-8 lg:grid-cols-3 ">
          <div className="lg:col-span-2 space-y-6">
            {/* Order Details Section */}
            <div>
              <div className="shadow-sm p-12 bg-gray-100 border border-gray-200 rounded-xl">
                <div className="grid md:grid-cols-2 gap-8">
                  <div>
                    <h3 className="font-medium text-black mb-4">
                      Order No: {orderDetails.orderId}
                    </h3>
                    <div className="space-y-2">
                      <h4 className="font-medium text-black">
                        Contact Information
                      </h4>
                      <p className="text-gray-900">
                        {orderDetails.contact.name}
                      </p>
                      <p className="text-gray-900">
                        {orderDetails.contact.email}
                      </p>
                      <p className="text-gray-900">
                        {orderDetails.contact.phone}
                      </p>
                    </div>
                  </div>
                  <div>
                    <h3 className="font-medium text-black mb-4">
                      Delivery Address
                    </h3>
                    <div className="space-y-2 text-gray-900">
                      <p>{orderDetails.delivery.schoolName}</p>
                      <p>Class: {orderDetails.delivery.className}</p>
                      <p>{orderDetails.delivery.addressLine1}</p>
                      <p>{orderDetails.delivery.addressLine2}</p>
                      <p>{orderDetails.delivery.city}</p>
                      <p>{orderDetails.delivery.county}</p>
                      <p>{orderDetails.delivery.postCode}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Order Items Section */}
            <div>
              <h2 className="text-xl font-semibold text-gray-900 mb-4">
                Order Items
              </h2>
              <div className="space-y-4">
                {basket?.items?.map((item) => (
                  <BasketItem
                    key={item.id}
                    item={item}
                    onItemRemoveClick={() => {
                      console.log("Remove item:", item);
                      setIsRemoveBasketModalOpen(true);
                      setSelectedItem(item);
                    }}
                  />
                ))}
              </div>
            </div>
          </div>

          {/* Order Summary Section */}
          <div className="lg:col-span-1">
            {/* <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-2xl font-semibold text-gray-900 mb-4">
                Order Summary
              </h2>
              <p className="text-gray-600 mb-6">
                Please make sure your order details are correct. Your items will
                be delivered via a Royal Mail Signed for service. The labelling
                will include your class name.
              </p>
              <div className="space-y-4">
                <div>
                  <label className="text-sm font-medium text-purple-600">
                    School Voucher Code
                  </label>
                  <div className="mt-1 p-2 bg-gray-50 rounded-md border border-gray-200">
                    XXX-XXX-XXX
                  </div>
                </div>
                <div className="pt-4">
                  <label className="flex items-start gap-3">
                    <input
                      type="checkbox"
                      className="mt-1 h-4 w-4 rounded border-gray-300 text-purple-600 focus:ring-purple-500"
                    />
                    <span className="text-sm text-gray-600">
                      You must agree to our Terms and Conditions before placing
                      your order.
                    </span>
                  </label>
                </div>
                <button className="w-full rounded-full bg-purple-600 px-4 py-2 text-white hover:bg-purple-700 transition-colors mt-4">
                  Place Order
                </button>
              </div>
            </div> */}
            <OrderSummary
              onApplyVoucher={onApplyVoucher}
              onCheckout={() => {
                navigate("/checkout");
              }}
              voucherApplied={voucherApplied}
              isBasketEmpty={!basket}
              onCheckoutClick={onCheckoutClick}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, { requestFetchBasket })(Checkout);
