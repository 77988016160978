import React, { Component } from "react";
import Loader from "../../components/Loader";
import {
  RESOURCE_TYPE_GLOSSARY,
  RESOURCE_TYPE_WORKBOOK,
} from "../../constants/string_constants";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import _ from "lodash";
import DeleteProjectModal from "../../components/modals/DeleteProjectModal";

import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import axios from "../../apis/thresource";
import { checkAquiredRole } from "../../utils/Utils";
import { ROLE_TYPE_CHILD } from "../../constants/role_constants";
import { ArrowDownToLine } from "lucide-react";

class TopicDetail extends Component {
  constructor(props) {
    super(props);
    this.props.onShowHeader();

    const project = this.props.savedBooks.find(
      (x) => x.template_id == this.props.templateDetail?.id
    );

    this.state = {
      isOpenImageBox: false,
      photoIndex: 0,
      showDeleteConfirm: false,
      isCompleted: project ? project.completed : false,
      isChild: checkAquiredRole([ROLE_TYPE_CHILD], this.props.auth),
    };

    this.onHandleSaveBookClick = this.onHandleSaveBookClick.bind(this);
    this.onHandleDeleteBookClick = this.onHandleDeleteBookClick.bind(this);
    this.onHandleFavouritesClick = this.onHandleFavouritesClick.bind(this);
    this.onHandleStartTopic = this.onHandleStartTopic.bind(this);
    this.findAndAssignMatchingBook = this.findAndAssignMatchingBook.bind(this);
    this.navigateToRegister = this.navigateToRegister.bind(this);
    this.onSaveToHomeAndEditTopic = this.onSaveToHomeAndEditTopic.bind(this);
    this.onConfirmDelete = this.onConfirmDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.props.onHideFavorite(true);
  }

  componentDidMount() {
    if (this.props.templateCatalouge === null) {
      this.props.requestTemplateCatalouge();
    }
    const templateId = parseInt(this.props.match.params.id, 10);
    this.props.requestTemplateDetails(templateId);
  }

  onHandleSaveBookClick() {
    this.props.requestCreateSavedBook();
    this.props.onNavigateToClientPage("/");
  }

  onHandleDeleteBookClick() {
    this.setState({
      showDeleteConfirm: true,
    });
  }

  onConfirmDelete() {
    this.closeModal();
    var bookId = this.findAndAssignMatchingBook();
    this.props.requestDeleteSavedBook(bookId);
    //TODO now load the saved book in the editor.
  }

  closeModal() {
    this.setState({
      showDeleteConfirm: false,
    });
  }

  onHandleFavouritesClick() {
    const { templateDetail } = this.props;
    this.props.requestChangeFavouriteTemplate(templateDetail.id);
  }

  findAndAssignMatchingBook() {
    const { templateDetail, savedBooks } = this.props;

    var bookId = 0;
    for (var i = 0; i < savedBooks.length; i++) {
      if (savedBooks[i].template_id === templateDetail.id) {
        bookId = savedBooks[i].id;
        break;
      }
    }
    return bookId;
  }

  onHandleStartTopic() {
    const { templateDetail, savedBooks } = this.props;
    var template_id = 0;
    for (var i = 0; i < savedBooks.length; i++) {
      if (savedBooks[i].template_id === templateDetail.id) {
        template_id = savedBooks[i].id;
        break;
      }
    }

    this.props.onSelectStartWriting(template_id);
  }

  onSaveToHomeAndEditTopic() {
    const { templateDetail, savedBooks } = this.props;
    const postData = {
      template_id: templateDetail.id,
    };
    axios
      .post("/api/savedbooks/create", postData, this.props.getRequestHeaders())
      .then((response) => {
        if (response.data.success === true) {
          this.props.requestSavedBooks();

          setTimeout(() => {
            this.onHandleStartTopic();
          }, 500);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onHandleDownloadResource(_resource) {
    window.open(_resource.resource_url, "_blank");
  }

  onHandleRelatedTemplateClick(_templateId) {
    this.props.requestTemplateDetails(_templateId);
    this.props.onNavigateToClientPage(`/topics/${_templateId}/details`);
  }

  navigateToRegister() {
    this.props.onNavigateToClientPage("/Register");
  }

  render() {
    const { isLoggedIn, templateDetail, templateCatalouge } = this.props;
    const {
      isOpenImageBox,
      photoIndex,
      showDeleteConfirm,
      isChild,
      isCompleted,
    } = this.state;

    if (templateDetail === null) {
      return <Loader />;
    }

    var bookExists = this.findAndAssignMatchingBook() > 0;

    const favouriteButtonText =
      templateDetail.favourite === true
        ? "Remove from Favourites"
        : "Save as Favourites";
    const saveBookButtonText =
      bookExists === false ? "Save as Project" : "Delete Project";

    var imagesCount = 0;
    const lightBoxImages = [];

    for (var i = 0; i < templateDetail.images.length; i++) {
      if (
        templateDetail.images[i].image_url !== templateDetail.cover_image_url &&
        templateDetail.images[i].image_url !== templateDetail.thumbnail_url
      ) {
        imagesCount++;
        lightBoxImages.push(templateDetail.images[i]);
      }
    }

    var imgGalleryPlaceHolders = [];
    if (imagesCount < 6) {
      for (var j = imagesCount; j < 6; j++) {
        imgGalleryPlaceHolders.push(
          <div
            key={j}
            className="d-flex flex-col flex-jc-c flex-ai-c bgc-light-grey ar-6x4 th-br"
          >
            <p className="t-grey">
              <i className="fa-light fa-image"></i>
            </p>
          </div>
        );
      }
    }

    const eligibleOtherCatalogs = templateCatalouge.filter(
      (x) => x.id != +this.props.match.params.id
    );
    const templatesCount = eligibleOtherCatalogs.length;
    var otherTopicPlaceHolders = [];
    if (templatesCount > 0) {
      while (otherTopicPlaceHolders.length < 6) {
        var randomIndex1 = Math.floor(Math.random() * templatesCount);
        const imgSrc = eligibleOtherCatalogs[randomIndex1].thumbnail_url;
        if (imgSrc) {
          otherTopicPlaceHolders.push({
            id: eligibleOtherCatalogs[randomIndex1].id,
            element: (
              <div
                key={eligibleOtherCatalogs[randomIndex1].id}
                className="related-slider__slide-sml"
                onClick={this.onHandleRelatedTemplateClick.bind(
                  this,
                  eligibleOtherCatalogs[randomIndex1].id
                )}
              >
                <img src={`${imgSrc}`} />
              </div>
            ),
          });
        }
      }
    }

    const uniqueCatalogs = _.uniqBy(otherTopicPlaceHolders, "id");
    const relatedTemplates = uniqueCatalogs.map((x) => x.element);

    const downloadCount =
      templateDetail.resources !== null && templateDetail.resources.length > 0
        ? templateDetail.resources.length
        : 0;

    return (
      <div>
        {templateDetail && (
          <>
            <div className="topic-banner-block">
              <div className="topic-banner-content">
                <div className="topic-banner-content__image">
                  <img src={templateDetail.cover_image_url} alt="" />
                </div>
              </div>
            </div>

            <div className="topic-info-block">
              <div className="topic-info-content">
                <div className="topic-info">
                  <h1 className="text-4xl sm:text-5xl font-bold mb-4 sm:mb-0">
                    {templateDetail.title}
                  </h1>
                  <p className="text-3xl sm:text-2xl font-bold mb-4 sm:mb-0">
                    {" "}
                    {templateDetail.subtitle}{" "}
                  </p>
                  <p className="topic-info__text">
                    {templateDetail.description}
                  </p>

                  <h2 className="topic-info__subtitle">Template gallery</h2>

                  {lightBoxImages.length > 0 && isOpenImageBox && (
                    <Lightbox
                      mainSrc={lightBoxImages[photoIndex].image_url}
                      nextSrc={
                        lightBoxImages[(photoIndex + 1) % lightBoxImages.length]
                          .image_url
                      }
                      prevSrc={
                        lightBoxImages[
                          (photoIndex + lightBoxImages.length - 1) %
                            lightBoxImages.length
                        ].image_url
                      }
                      onCloseRequest={() =>
                        this.setState({ isOpenImageBox: false })
                      }
                      onMovePrevRequest={() =>
                        this.setState({
                          photoIndex:
                            (photoIndex + lightBoxImages.length - 1) %
                            lightBoxImages.length,
                        })
                      }
                      onMoveNextRequest={() =>
                        this.setState({
                          photoIndex: (photoIndex + 1) % lightBoxImages.length,
                        })
                      }
                    />
                  )}

                  <div className="topic-gallery">
                    {imagesCount > 0
                      ? templateDetail.images.map((tImg, j) => {
                          if (
                            tImg.image_url === templateDetail.cover_image_url ||
                            tImg.image_url === templateDetail.thumbnail_url
                          ) {
                            return;
                          }

                          return (
                            <div
                              key={j}
                              className="topic-detail-image d-flex flex-col flex-jc-c flex-ai-c bgc-light-grey ar-6x4 th-br"
                              style={{
                                backgroundImage: `url(${tImg.image_url})`,
                                backgroundSize: "cover",
                              }}
                              onClick={() =>
                                this.setState({ isOpenImageBox: true })
                              }
                            ></div>
                          );
                        })
                      : null}

                    {imgGalleryPlaceHolders}
                  </div>

                  {isLoggedIn === true ? (
                    <div className="templete-actions-block">
                      <div className="templete-actions-content">
                        {/* {bookExists ? (
                          <button
                            className="btn btn--success"
                            onClick={this.onHandleStartTopic}
                            disabled={isChild && isCompleted}
                          >
                            Continue writing
                          </button>
                        ) : (
                          <button
                            className="btn btn--success"
                            onClick={this.onSaveToHomeAndEditTopic}
                          >
                            Start Writing
                          </button>
                        )} */}

                        <div className="templete-actions-right">
                          {/* {bookExists === false ? (
                            <button
                              className="btn btn--secondary"
                              onClick={this.onHandleSaveBookClick}
                            >
                              {saveBookButtonText}
                            </button>
                          ) : (
                            <button
                              className="btn btn--danger"
                              onClick={this.onHandleDeleteBookClick}
                            >
                              {saveBookButtonText}
                            </button>
                          )} */}

                          {/* <button
                            className="btn btn--secondary"
                            onClick={this.onHandleFavouritesClick}
                          >
                            {favouriteButtonText}
                          </button> */}
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>

                <div className="bg-gray-100 divide-y divide-gray-300 p-4 border border-gray-200 rounded-xl [&>*]:py-8">
                  <div className="flex  flex-col  space-y-4">
                    <h2 className="topic-resources__title width-full text-left">
                      Get Started
                    </h2>

                    <div className="flex flex-col items-center w-full  space-y-4">
                      {bookExists ? (
                        <button
                          className="w-full  min-w-[250px] rounded-full hover:opacity-80 h-24 border-purple-200 bg-purple-200 hover:bg-white hover:border-purple-300 hover:text-gray-900 text-white font-semibold"
                          onClick={this.onHandleStartTopic}
                          disabled={isChild && isCompleted}
                        >
                          Continue writing
                        </button>
                      ) : (
                        <button
                          className="w-full bg-primary-400 border-primary-400 hover:bg-purple-100 hover:border-purple-300 hover:text-gray-900 min-w-[250px] rounded-full h-24 font-semibold text-white"
                          onClick={this.onSaveToHomeAndEditTopic}
                        >
                          Start Writing
                        </button>
                      )}

                      {bookExists === false ? (
                        <button
                          className="w-full bg-gray-300 hover:bg-gray-300 hover:border-gray-400 hover:text-gray-900 min-w-[250px] rounded-full h-24 font-semibold text-black"
                          onClick={this.onHandleSaveBookClick}
                        >
                          {saveBookButtonText}
                        </button>
                      ) : (
                        <button
                          className="w-full bg-gray-300 border-2 hover:border-primary-200 hover:text-gray-900 min-w-[250px] rounded-full h-24 font-semibold text-primary-400"
                          onClick={this.onHandleDeleteBookClick}
                        >
                          {saveBookButtonText}
                        </button>
                      )}

                      <button
                        className="w-full bg-gray-300 border-gray-300 hover:bg-white hover:border-gray-400 hover:text-gray-900 min-w-[250px] rounded-full h-24 font-semibold text-black"
                        onClick={this.onHandleFavouritesClick}
                      >
                        {favouriteButtonText}
                      </button>
                    </div>
                  </div>

                  <div>
                    <h2 className="topic-resources__title">Resources</h2>
                    <p className="topic-resources__text">
                      We've created some fab resources for you to download for
                      this topic. There are some handy hints and tips to help
                      you write amazing books.
                    </p>
                  </div>
                  <div>
                    {isLoggedIn ? (
                      downloadCount > 0 && (
                        <p className="t-bld">Download the files</p>
                      )
                    ) : (
                      <div className="mt-4 flex flex-col items-center">
                        <h3 className="topic-resources__title text-left w-full">
                          Innovative learning
                        </h3>
                        <p className="topic-resources__text">
                          Topic Heroes<sup>®</sup> is perfect for children to
                          get excited about writing. The whole family can get
                          involved with topics and be more inspired with
                          literacy at school and at home. Register today and
                          take advantage of this innovative and rewarding
                          learning resource.
                        </p>
                        <button
                          className="btn mt-4 btn--success bg-primary-400 border-primary-400 hover:bg-white hover:border-primary-500 hover:text-gray-900 w-full  min-w-[250px]"
                          onClick={this.navigateToRegister}
                        >
                          Register now
                        </button>
                      </div>
                    )}

                    {isLoggedIn && (
                      <div>
                        {downloadCount > 0
                          ? templateDetail.resources.map((tRsc, k) => {
                              var buttonGraphicName = "fa-list-check";
                              var buttonText = "Download";
                              if (
                                tRsc.resource_type === RESOURCE_TYPE_GLOSSARY
                              ) {
                                buttonGraphicName = "fa-square-quote";
                                buttonText = "Glossary";
                              }
                              if (
                                tRsc.resource_type === RESOURCE_TYPE_WORKBOOK
                              ) {
                                buttonGraphicName = "fa-list-check";
                                buttonText = "Download the Fact Sheet";
                              }

                              return (
                                <button
                                  key={k}
                                  className="flex items-center gap-2 mt-4 hover:underline"
                                  onClick={this.onHandleDownloadResource.bind(
                                    this,
                                    tRsc
                                  )}
                                >
                                  <ArrowDownToLine size={24} />
                                  <span>{buttonText}</span>
                                </button>
                              );
                            })
                          : null}
                      </div>
                    )}
                  </div>

                  {templateDetail.sponsor_id ? (
                    <div className="sponsor-block">
                      <p className="sponsor-block__text">
                        This topic is sponsored by:
                      </p>

                      <h3 className="sponsor-block__sponsor">
                        {templateDetail?.sponsor?.title}
                      </h3>

                      {templateDetail.sponsor?.image_url ? (
                        <div className="sponsor-block__image">
                          <img src={templateDetail.sponsor.image_url} />
                        </div>
                      ) : null}

                      <p className="sponsor-block__text">
                        {templateDetail?.sponsor?.website}
                      </p>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="section-block section-block--grey-xl">
              <div className="section-block-content">
                <h4 className="topic-info__subtitle">More Like this...</h4>
                <div>
                  <Carousel
                    className="related-slider"
                    infiniteLoop={true}
                    autoPlay={false}
                    centerMode={true}
                    centerSlidePercentage={33.33}
                    showStatus={false}
                    showArrows={true}
                    emulateTouch={true}
                    showThumbs={false}
                  >
                    {relatedTemplates}
                  </Carousel>
                </div>
              </div>
            </div>
          </>
        )}

        <DeleteProjectModal
          open={showDeleteConfirm}
          onClose={this.closeModal}
          onConfirmDelete={this.onConfirmDelete}
        />
      </div>
    );
  }
}

export default TopicDetail;
