import React, { Component } from 'react';
import Loader from '../../../components/Loader';

class EditSubject extends Component {

    constructor(props){
        super(props);

        this.state = {
            changes: null
        }

        this.onChangeTitle = this.onChangeTitle.bind(this);

        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.onSaveChanges = this.onSaveChanges.bind(this);
         this.props.onHideFavorite(true);

    }

    static getDerivedStateFromProps(props, current_state) {
        if (current_state.changes !== props.subject) {
          return {
            changes: props.subject,
          }
        }
        return null
    }

    onChangeTitle(_e){

        var { changes } = this.state;
        changes.title = _e.target.value;
        this.onSaveChanges(changes);
    }

    onSaveChanges(_subject) {
        this.setState({
            changes: _subject
        });
    }


    onSubmitForm(_e) {
        _e.preventDefault();

        const { changes } = this.state;

        const subject = {
            id: changes.id,
            title: changes.title
        };

        this.props.requestUpdateSubject(subject);
    }

    render() {

        const { changes } = this.state;
        if(changes === null) {
            return <Loader />
        }

        const titleText = changes.id.length > 0 ? "Edit Category" : "Add Category";

        return (
           

            <div className='content-container bgc-grade-snowfall'>

                <div className='content-mw pall-l'>

                    <div className='th-panel th-br bgc-white pall-l'>

                        <h2 className="hw-font t-purple spc-b-s">{ titleText }</h2>

                        <p className="spc-b-l">Update the name of the Category.</p>

                        <form className="th-form-container has-validation-callback" onSubmit={ this.onSubmitForm }>

                            <div className="th-form-inner">
                                
                                <div className="th-field-wrapper">

                                    <label>Category Title</label>

                                    <input type="text" placeholder="Title" onChange={ this.onChangeTitle } value={ changes.title } />

                                </div>
                                <div className="form-buttons">
                                    <button className="th-admin-btn" type="submit">Save Changes</button>
                                </div>
                            </div>
                        </form>

                    </div>

                </div>

            </div>
        );
    }
}

export default EditSubject;