import React, { useState } from "react";
import Avatar from "react-avatar";

const AddChildAccountForm = ({ onSubmit, onCancel }) => {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(formData);
  };

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded-lg shadow-sm">
      <div className="flex flex-col sm:flex-row items-center justify-between">
        <div className="flex items-center space-x-4 flex-grow mb-4 sm:mb-0">
          <div className="flex-shrink-0">
            <div className="w-12 h-12 bg-gray-200 rounded-full flex items-center justify-center mr-2">
              <Avatar
                name={`${formData.first_name} ${formData.last_name}`}
                round={true}
                size="44"
                color="purple"
              />
            </div>
          </div>
          <div className="flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-4 flex-grow">
            <input
              type="text"
              id="firstName"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              placeholder="First Name"
              required
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
            <input
              type="text"
              id="lastName"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              placeholder="Last Name"
              required
              className="flex-1 px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
            />
          </div>
        </div>
        <div className="flex space-x-2 mt-4 sm:mt-0">
          <button
            type="button"
            onClick={onCancel}
            className="px-3 py-2 w-24 text-[14px] font-medium text-gray-600 hover:text-gray-800 focus:outline-none"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="px-3 py-2 w-24 text-[14px] font-medium text-blue-600 hover:text-blue-800 focus:outline-none"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};

export default AddChildAccountForm;
