
export const TEMPLATES_FETCH_ACTION = "FETCH_TEMPLATES";
export const TEMPLATES_SELECT_ACTION = "TEMPLATE_SELECTED";

export const SEARCH_FILTER_FETCH_ACTION = "FETCH_SEARCH_FILTER";
export const SEARCH_FILTER_SELECT_ACTION = "FETCH_SEARCH_SELECTED";

export const SPONSOR_SELECT_ACTION = "SPONSOR_SELECTED";
export const SPONSOR_FETCH_ACTION = "FETCH_SPONSORS";

export const SCHOOL_SELECT_ACTION = "SCHOOL_SELECTED";
export const SCHOOL_FETCH_ACTION = "FETCH_SCHOOLS";

export const SUBJECT_SELECT_ACTION = "SUBJECT_SELECTED";
export const SUBJECT_FETCH_ACTION = "FETCH_SUBJECTS";

export const BASKETITEM_SELECT_ACTION = "BASKETITEM_SELECTED";
export const BASKET_FETCH_ACTION = "FETCH_BASKET";

export const ORDER_HISTORY_FETCH_ACTION = "FETCH_ORDER_HISTORY";