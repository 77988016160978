

import React, { Component } from 'react';
import OrderHistoryList from '../../components/order-history/OrderHistoryList';

class OrderHistory extends Component {
  constructor(props) {
    super(props);
    this.props.onHideFavorite(true);
  }

  render() {
    return (
      <div className="section-block">
        <div className="section-block-content">
          <h2 className="section-block-content__title">Order history</h2>
          <p className="section-block-content__text">Here's where you can view your previous orders.</p>

          <OrderHistoryList
            admin={false}
            getRequestHeaders={this.props.getRequestHeaders}
            requestOrderHistoryDetails={this.props.requestOrderHistoryDetails}
            onNavigateToClientPage={this.props.onNavigateToClientPage}
          />
        </div>
      </div>
    );
  }
}

export default OrderHistory;