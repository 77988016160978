import BasketItem from "../../components/basket-list/BasketItem";
import OrderSummary from "../../components/basket-list/BasketSummery";
import { useEffect, useState } from "react";
import { requestFetchBasket } from "../../store/actions";
import { connect } from "react-redux";
import Loader from "../../components/Loader";
import RemoveBasketModal from "../../components/basket-list/RemoveBasketModal";

const Basket = ({
  requestFetchBasket,
  getRequestHeaders,
  basket,
  requestRemoveFromBasket,
  onApplyVoucher,
  voucherApplied,
  onCheckoutClick,
}) => {
  const [isLoading, setisLoading] = useState(false);
  const [isRemoveBasketModalOpen, setIsRemoveBasketModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);

  useEffect(() => {
    setisLoading(true);
    requestFetchBasket(getRequestHeaders());
  }, [requestFetchBasket, getRequestHeaders]);

  useEffect(() => {
    setisLoading(false);
  }, [basket]);

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="max-w-8xl px-4 sm:px-6 lg:px-8 lg:mx-[10%]">
        <h1 className="text-4xl sm:text-5xl font-bold mb-4 sm:mb-0">
          Your Basket
        </h1>

        <p className="text-3xl sm:text-2xl font-bold mb-4 sm:mb-0 text-gray-900">
          {basket?.items?.length > 0
            ? basket?.items?.length === 1
              ? "You have 1 item in your basket."
              : `You have ${basket?.items?.length} items in your basket.`
            : "Your basket is empty."}
        </p>

        <div className="mt-8 grid gap-8 lg:grid-cols-3">
          <div className="lg:col-span-2 space-y-4">
            {isLoading ? (
              <div className="flex justify-center items-center h-[50vh]">
                <Loader />
              </div>
            ) : (
              basket?.items?.map((item) => (
                <BasketItem
                  key={item.id}
                  item={item}
                  onItemRemoveClick={() => {
                    console.log("Remove item:", item);
                    setIsRemoveBasketModalOpen(true);
                    setSelectedItem(item);
                  }}
                />
              ))
            )}
          </div>

          <div className="lg:col-span-1">
            <OrderSummary
              onApplyVoucher={onApplyVoucher}
              onCheckout={() => {
                navigate("/checkout");
              }}
              voucherApplied={voucherApplied}
              isBasketEmpty={!basket}
              onCheckoutClick={onCheckoutClick}
            />
          </div>
        </div>
      </div>
      <RemoveBasketModal
        open={isRemoveBasketModalOpen}
        onClose={() => setIsRemoveBasketModalOpen(false)}
        onConfirmDelete={() => {
          console.log("Confirm delete:", selectedItem);
          setIsRemoveBasketModalOpen(false);
          setisLoading(true);
          requestRemoveFromBasket(selectedItem.id);
        }}
      />
    </div>
  );
};

const mapStateToProps = (state) => ({
  basket: state.basket,
});

export default connect(mapStateToProps, { requestFetchBasket })(Basket);
