
import React, { Component } from 'react';
import Loader from '../../components/Loader';
import { ROLE_TYPE_ADMIN } from '../../constants/role_constants';
import { checkAquiredRole, formatDate } from '../../utils/Utils';


class OrderHistoryDetails extends Component {

    constructor(props) {
        super(props);

        this.onRequestOrderHistoryDetails = this.onRequestOrderHistoryDetails.bind(this);
        this.onRequestRefreshOrderHistoryStatus = this.onRequestRefreshOrderHistoryStatus.bind(this);
        this.onDownloadPdf = this.onDownloadPdf.bind(this);
         this.props.onHideFavorite(true);
    }

    componentDidMount(){
        setTimeout(this.onRequestOrderHistoryDetails, 100);
    }

    onRequestOrderHistoryDetails() {
        const orderHistoryId = this.props.match.params.id;
        this.props.requestOrderHistoryDetails(orderHistoryId);
    }

    onRequestRefreshOrderHistoryStatus(){
        const { orderHistoryDetail } = this.props;

        this.props.requestRefreshOrderHistoryStatus(orderHistoryDetail.id);
    }

    onDownloadPdf(_url) {
        window.open(_url, '_blank');
    }

    render() {

        const { orderHistoryDetail, auth } = this.props;

        if(orderHistoryDetail === undefined || orderHistoryDetail === null) {
            return (
                <Loader />
            );
        }

        const isAdmin = checkAquiredRole([ ROLE_TYPE_ADMIN ], auth);

        return (
            <div className='section-block'>

                <div className='section-block-content'>

                    <h2 className="section-block-content__title">Order details</h2>
                    <p className="section-block-content__text">View your order details below.</p>
                    
                    <div>

                        <div className='app-form'>

                            <div className='app-form__content'>

                                <div className='app-form__row'>

                                    <div className='app-field-wrapper'>
                                        <label htmlFor="order_id">Order #</label>
                                        <input name="order_id" type="text" value={ orderHistoryDetail.id } readOnly={true} />
                                    </div>

                                    <div className='app-field-wrapper'>
                                        <label htmlFor="complete">Order Date</label>
                                        <input name="created" type="text" value={ formatDate(orderHistoryDetail.created) } readOnly={true} />
                                    </div>

                                </div>
                                
                                <div className='app-form__row'>
                                    <div className='app-field-wrapper'>
                                        <label htmlFor="action_text">Status</label>

                                        <input name="action_text" type="text" value={ orderHistoryDetail.action_text } readOnly={true} />
                                    </div>

                                    <div className='app-field-wrapper'>
                                        <label htmlFor="complete">Complete</label>

                                        <input name="complete" type="text" value={ orderHistoryDetail.complete } readOnly={true} />
                                    </div>
                                </div>
                               
                                {
                                    orderHistoryDetail.items.length > 0 ? orderHistoryDetail.items.map((e, i) => {
                                        return (
                                            <div key={i} className='app-table-content'>

                                                <div className='app-form' >

                                                    <h3 className="section-block-content__subtitle">{ e.book_title }</h3>
                                                    
                                                    <div className='app-form__content'>

                                                        <div className='app-form__row'>
                                                            <div className='app-field-wrapper'>
                                                                <label htmlFor="author_name">Author</label>

                                                                <input name="author_name" type="text" value={ e.author_name } readOnly={true} />
                                                            </div>

                                                            <div className='app-field-wrapper'>
                                                                <label htmlFor="ext_last_sync">Sync Date</label>

                                                                <input name="ext_last_sync" type="text" value={ formatDate(e.ext_last_sync) } readOnly={true} />
                                                            </div>
                                                        </div>

                                                        {
                                                            (isAdmin) ? (
                                                                <div className='app-form__content'>
                                                                    <div className='app-form__row'>

                                                                        <div className='app-field-wrapper'>
                                                                            <label htmlFor="item_ext_ref">Printess Ref</label>

                                                                            <input name="item_ext_ref" type="text" value={ e.ext_ref } readOnly={true} />
                                                                        </div>

                                                                        <div className='app-field-wrapper'>
                                                                            <label htmlFor="item_ext_order_number">Printess Order Number</label>

                                                                            <input name="item_ext_order_number" type="text" value={ e.ext_order_number } readOnly={true} />
                                                                        </div>

                                                                    </div>

                                                                        {
                                                                            (orderHistoryDetail.complete) ? (
                                                                                <button className='btn btn--success' onClick={ () => { this.onDownloadPdf(e.ext_file_url); } }>Download</button>
                                                                            ) : null
                                                                        }
                                                                    
                                                                </div>
                                                            ) : null
                                                        }

                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }) : (
                                        <p>No items on this order</p>
                                    )
                                }

                                
                                {
                                    (isAdmin) ? (
                                        <button className='btn btn--success' onClick={ this.onRequestRefreshOrderHistoryStatus }>Sync with Printess</button>
                                    ) : null
                                }
                            
                            </div>
                            
                        </div>

                    </div>

                </div>

            </div>
        );
    }
}

export default OrderHistoryDetails;