import React, { Component } from "react";
import axios from "../../apis/thresource";
import toast from "react-hot-toast";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.props.onHideHeader();

    this.state = {
      email: "",
      emailSent: false,
    };

    this.onChangeEmail = this.onChangeEmail.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  onChangeEmail(_e) {
    this.setState({
      email: _e.target.value,
    });
  }

  onSubmitForm(_e) {
    _e.preventDefault();

    if (!this.state.email) {
      toast.error("Please enter your email address.");
      return;
    }

    const postData = {
      email: this.state.email,
    };
    const toastId = toast.loading("Sending email...");
    axios
      .post("/api/auth/forgot-password", JSON.stringify(postData))
      .then((response) => {
        if (!response.data.success && response.data.errors) {
          //this.props.onSetAlert(response.data.errors);
          toast.error(response.data.errors[0]?.message);
          toast.dismiss(toastId);
        }
        if (response.data.success) {
          toast.dismiss(toastId);
          this.setState({
            emailSent: true,
          });
          // if(response.data.data.is2FAEnabled){
          //     const user = {
          //         email:response.data.data.email,
          //         mobile: response.data.data.mobile,
          //         resetToken: response.data.data.resetToken
          //     }
          //     this.props.onUpdateUser(user);
          //     this.props.history.push('/verify-2fa');
          // }else{
          //     this.setState({
          //         emailSent: true
          //     });
          // }
        }
      })
      .catch((error) => {
        if (error.response && error.response.data) {
          //console.log("server data:", error.response.data);
          //this.props.onSetAlert(error.response.data.errors);
          toast.error(error.response.data.errors[0]?.message);
          toast.dismiss(toastId);
        }
      });
  }

  render() {
    const { email, emailSent } = this.state;

    return (
      <div className="login-block">
        {!emailSent ? (
          <form className="login-block-card" onSubmit={this.onSubmitForm}>
            <div className="login-card-content">
              <div>
                <div className="login-card-content__icon">
                  <i className="fa-light fa-lock"></i>
                </div>
                <h2 className="login-card-content__title">Password Reset</h2>
                <p className="login-card-content__text">
                  Enter the registered email address and we'll send you reset
                  link to change your password.
                </p>
              </div>

              <div className="login-card-content__form">
                <div>
                  <label className="block text-lg font-medium text-primary-400">
                    Email Address
                  </label>
                  <input
                    className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-primary focus:border-primary"
                    autoComplete="on"
                    name="email"
                    type="text"
                    maxLength="128"
                    placeholder="yourname@domain.co.uk"
                    onChange={this.onChangeEmail}
                    value={email}
                  />
                </div>

                <div className="text-sm text-gray-900 mt-2">
                  <label>If you have 2FA enabled, we'll send you a code.</label>
                </div>
              </div>

              <button
                type="submit"
                className="flex justify-center items-center py-3 px-6 border border-primary-400 rounded-full shadow-sm text-2xl font-medium text-white bg-primary-400 hover:bg-white hover:text-primary-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary w-full h-20 mt-8 hover:bg-primary-300"
              >
                {" "}
                Continue{" "}
              </button>
            </div>
          </form>
        ) : (
          <div className="form-card-container th-br th-fade-in th-shadow-down pall-l">
            <div className="th-form-inner">
              <h2 className="hw-font t-purple t-ctr">Link sent</h2>
              <p className="t-grey t-ctr">
                {" "}
                An email has been sent to {email}. Follow the link in the email
                to reset password.
              </p>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default ForgotPassword;
