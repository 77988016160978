import React, { Component } from "react";

import NavMenu from "./NavMenu";
import Footer from "./Footer";

class Layout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      //isOpen: false,
      isMenuVisible: false,
      isSearchVisible: false,
    };

    this.onToggleSearch = this.onToggleSearch.bind(this);
    this.onToggleMenu = this.onToggleMenu.bind(this);
  }

  onToggleMenu() {
    var menuVisible = !this.state.isMenuVisible;
    var searchVisible = this.state.isSearchVisible;
    if (menuVisible === true && searchVisible === true) {
      searchVisible = false;
    }

    this.setState({
      isMenuVisible: menuVisible,
      isSearchVisible: searchVisible,
    });
  }

  onToggleSearch() {
    //console.log("onToggleSearch: ", this.state.isSearchVisible);
    var menuVisible = this.state.isMenuVisible;
    var searchVisible = !this.state.isSearchVisible;
    if (menuVisible === true && searchVisible === true) {
      menuVisible = false;
    }

    this.setState({
      isMenuVisible: menuVisible,
      isSearchVisible: searchVisible,
    });
  }

  render() {
    const { isDisplayHeader } = this.props;

    const wrapperClass =
      isDisplayHeader === true
        ? this.state.isMenuVisible === true
          ? "main-wrapper mw-push"
          : "main-wrapper"
        : "main-wrapper-public";
    return (
      <React.Fragment>
        {isDisplayHeader === true ? (
          <NavMenu
            onNavigateToClientPage={this.props.onNavigateToClientPage}
            isMenuVisible={this.state.isMenuVisible}
            isSearchVisible={this.state.isSearchVisible}
            onToggleMenu={this.onToggleMenu}
            onToggleSearch={this.onToggleSearch}
            onToggleEditorMode={this.props.onToggleEditorMode}
            history={this.props.history}
            isLoggedIn={this.props.isLoggedIn}
            auth={this.props.auth}
            onLogout={this.props.onLogout}
            searchTerm={this.props.searchTerm}
            onUpdateSearchTerm={this.props.onUpdateSearchTerm}
            searchFilterCatalouge={this.props.searchFilterCatalouge}
            onSubmitSearch={this.props.onSubmitSearch}
            requestChangeFavouriteTemplate={
              this.props.requestChangeFavouriteTemplate
            }
            onUpdateSearchFilterCatalouge={
              this.props.onUpdateSearchFilterCatalouge
            }
            requestSearchFilterCatalouge={
              this.props.requestSearchFilterCatalouge
            }
            requestLoginBypass={this.props.requestLoginBypass}
            showFavoriteIcon={this.props.showFavoriteIcon}
            onNavigateToFavorite={this.props.onNavigateToFavorite}
          />
        ) : null}
        <div className={wrapperClass}>{this.props.children}</div>
        <Footer />
      </React.Fragment>
    );
  }
}

export default Layout;
